import { Injectable } from '@angular/core';
import { PurecloudService } from '../purecloud/purecloud.service';
import * as platformClient from 'purecloud-platform-client-v2';

class getOutboundCampaignsOptions implements platformClient.OutboundApi.getOutboundCampaignsOptions {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}

class getRoutingQueuesOptions implements platformClient.RoutingApi.getRoutingQueuesOptions {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}
@Injectable({
  providedIn: 'root'
})
export class NumberOfRecordsByTypeCloudService {

  constructor(private purecloudService : PurecloudService) { }

  public getWraUps(): Promise<any> {
    return this.purecloudService.getWraUps();
  }
  public getOutboundWrapupcodemappings(): Promise<platformClient.Models.WrapUpCodeMapping> {
    return this.purecloudService.getOutbound().getOutboundWrapupcodemappings();
  }

  public getUsers(): Promise<any> {
    return this.purecloudService.getUsers();
  }

  public getCampaigns(): Promise<any|Array<any>> {
    return this.purecloudService.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
      this.getCampaignsInternal(new getOutboundCampaignsOptions(100, 1), new Array<any>(), resolve, reject);
    });
  }
  private getCampaignsInternal(opts: getOutboundCampaignsOptions, users: Array<any>, resolve, reject) {
    this.purecloudService.getOutbound().getOutboundCampaigns()
      .then((response) => this.thenCampaigns(response, users, resolve, reject))
      .catch((response) => this.catchCampaigns(response, reject));
  }
  private thenCampaigns(response: platformClient.Models.CampaignEntityListing, users: Array<any>, resolve, reject) {
    response.entities.forEach((user) => users.push({
      id: user.id,
      name: user.name
    }));
    if(response.pageNumber < response.pageCount) {
      this.getCampaignsInternal(new getOutboundCampaignsOptions(100, (response.pageNumber + 1)), users, resolve, reject);
    } else {
      resolve(users);
    }
  }

  private catchCampaigns(response: any, reject) {
    reject(response);
  }
/* */
public getQueues(): Promise<any|Array<any>> {
  return this.purecloudService.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
    this.getQueuesInternal(new getRoutingQueuesOptions(100, 1), new Array<any>(), resolve, reject);
  });
}
private getQueuesInternal(opts: getRoutingQueuesOptions, users: Array<any>, resolve, reject) {
  this.purecloudService.getRouting().getRoutingQueues(opts)
    .then((response) => this.thenQueues(response, users, resolve, reject))
    .catch((response) => this.catchQueues(response, reject));
}
private thenQueues(response: platformClient.Models.QueueEntityListing, users: Array<any>, resolve, reject) {
  response.entities.forEach((user) => users.push({
    id: user.id,
    name: user.name
  }));
  if(response.pageNumber < response.pageCount) {
    this.getQueuesInternal(new getRoutingQueuesOptions(100, (response.pageNumber + 1)), users, resolve, reject);
  } else {
    resolve(users);
  }
}
private catchQueues(response: any, reject) {
  reject(response);
}



}
