import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { COMMA, ENTER, F, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import Swal from 'sweetalert2'
import { InteractionsDetailDataService } from '../../services/interactions-detail/interactions-detail-data.service';
import { InteractionsDetailExcelService } from '../../services/interactions-detail/interactions-detail-excel.service';
import { PurecloudService }  from '../../services/purecloud/purecloud.service';
import { InteractionsDetail }  from '../../models/interactions-detail/interactions-detail';
import { Agent }  from '../../models/interactions-detail/agent';
import * as $ from "jquery"

declare const duallistboxInteractionsDetail: any;
declare const duallistboxInteractionsDetailDummy: any;

@Component({
  selector: 'app-interactions-detail',
  templateUrl: './interactions-detail.component.html',
  styleUrls: ['./interactions-detail.component.css']
})
export class InteractionsDetailComponent implements OnInit, DoCheck {
  readonly separatorKeysCodes = [ENTER, COMMA, TAB] as const;
  pickerFrom;
  myDatePickerFrom;
  pickerTo;
  myDatePickerTo;
  @Input()
  allAgents: Array<Agent>= [];
  carsDummy: Array<Agent>= [];
  selectedAgents: Agent[] = [];
  listAgentSelected: any;
  selectedContract: string[] = [];
  selectedPhone: string[] = [];
  duallistbox:any;
  constructor(private interactionsDetailData : InteractionsDetailDataService, private interactionsDetailExcel : InteractionsDetailExcelService, private purecloudService : PurecloudService) {
  }

  ngOnInit(): void {
    this.loadUsers();
  }
  loadUser(user) {
    this.allAgents.push({
      name: user.name,
      id: user.id
    });
  }
  loadUsers() {
    this.purecloudService.getUsers()
    .then((response) => {
      response.forEach((user) => this.loadUser(user));
    })
    .catch((response) => alert(response));
  }

  ngDoCheck(): void {
    var duallistbox = $('.duallistbox-interactions-detail');
    if(this.duallistbox == undefined && duallistbox[0].length > 0) {
      this.duallistbox = duallistbox;
      console.log(duallistbox);
      duallistboxInteractionsDetailDummy.bootstrapDualListbox('destroy');
      $('.duallistbox-interactions-detail-dummy').hide()

      duallistboxInteractionsDetail.bootstrapDualListbox({
        moveOnSelect: false,
        infoTextEmpty: 'Lista vacia',
        infoText: false,
        filterPlaceHolder: 'Filtrar por agente',
        moveSelectedLabel: 'Mover soleccionado',
        moveAllLabel: 'Mover todos',
        removeSelectedLabel: 'Borrar seleccionado',
        removeAllLabel: 'Borrar todos',
        infoTextFiltered: 'Filtrado {0} de {1}',
        filterTextClear: 'Mostrar todo'
      });
    }

    var listAgentSelected = $("#bootstrap-duallistbox-selected-list_agentes");
    console.log(listAgentSelected);
    if(this.listAgentSelected == undefined && listAgentSelected.length) {
      console.log("Intenta hacer un cambio");

      this.listAgentSelected = listAgentSelected;

      const observer = new MutationObserver(
        () => { listAgentSelected.trigger('mutated') }
      )
      observer.observe(listAgentSelected[0], { childList: true })
      listAgentSelected.on('mutated', () => this.onAgentChange())
    }

  }
  onAgentChange(): void {
    this.selectedAgents = [];
    console.log(this.listAgentSelected);
    for (var i = 0; i < this.listAgentSelected[0].length; i++) {
      var agent = this.listAgentSelected[0][i];
      console.log(agent);
      this.selectedAgents.push({name: agent.text, id: agent.value});
    }
  }
  addContract(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.selectedContract.push(value);
    }
    event.input.value = "";
  }
  removeContract(value: string): void {
    const index = this.selectedContract.indexOf(value);
    if (index >= 0) {
      this.selectedContract.splice(index, 1);
    }
  }
  addPhone(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.selectedPhone.push(value);
    }
    event.input.value = "";
  }
  removePhone(value: string): void {
    const index = this.selectedPhone.indexOf(value);
    if (index >= 0) {
      this.selectedPhone.splice(index, 1);
    }
  }

  onRangeDate(type: string): void {
    var now = new Date();
    switch(type) {
      case 'TODAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'YESTERDAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        break;
        case'LAST_7_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'THIS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom .getDate() + 7, 0, 0, 0, 0);
        break;
        case'PREVIOUS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7 + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        break;
        case'LAST_31_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
      default:
    }
  }

  async onGenerateInteractionsDetail() {
    console.info('pickerFrom: ' + this.pickerFrom + ', myDatePickerFrom: ' + this.myDatePickerFrom + ', pickerTo: ' + this.pickerTo + ', myDatePickerTo: ' + this.myDatePickerTo);
    if(this.myDatePickerFrom == undefined || this.myDatePickerTo == undefined) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>No se definio rango de fechas correctamente</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var listAgentNoSelected = $("#bootstrap-duallistbox-nonselected-list_agentes");
    console.log(this.selectedAgents);

    if(listAgentNoSelected[0].length > 0 && this.selectedAgents.length > 100) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>Se supero la cantidad de agentes permitidos: 100</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }


    var from = new Date(this.myDatePickerFrom); //new Date(2021, 4, 23, 3, 0, 0,0); //'2021-04-23T03:00:00.000Z'
    var to = new Date(this.myDatePickerTo); //new Date(2021, 4, 24, 3, 0, 0,0); //'2021-04-24T03:00:00.000Z'

    /*var distance = to.getTime() - from.getTime();
    if(distance > 2678400000) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>El rango se fecha supera los 31 días</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }*/
    
    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    var selectedIdAgents = [];
    var selectedMapAgents = {};
    for (var i = 0; i < this.selectedAgents.length; i++) {
      selectedIdAgents.push(this.selectedAgents[i].id);
    }
    for (var i = 0; i < this.allAgents.length; i++) {
      selectedMapAgents[this.allAgents[i].id] = this.allAgents[i].name;
    }

    let divisions = await this.purecloudService.getDivisions();
    let wraups = await this.purecloudService.getWraUps();

    this.interactionsDetailData.generate(this.allAgents.length == selectedIdAgents.length ? [] : selectedIdAgents, from, to, false)
    .then((response) => this.processInteractionsDetailExcel(response, new Date(this.myDatePickerFrom), new Date(this.myDatePickerTo), selectedMapAgents, divisions, wraups, this.selectedPhone, this.selectedContract))
    .catch(function(response) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b></b>',
        didOpen: () => {
          Swal.showLoading();
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              console.log(response);
              var result = undefined;
              if(response.body !== undefined && response.body.message !== undefined) {
                result = response.body.message
              }
              if(response.error !== undefined && response.error.message !== undefined) {
                result = response.error.message + " (" + response.error.errno + ")";
              }
              if(result === undefined) {
                result = response;
              }
              b.textContent = JSON.stringify(result);
            }
          }
        },
        willClose: () => {
          window.location.href = window.location.href
        }
      }).then((result) => {
        console.log(result);
      })    
    });
  }

  private processInteractionsDetailExcel(response : InteractionsDetail[], from: Date, to: Date, selectedMapAgents, divisions, wraups, anis: string[], contratos: string[]): void {
    console.log(response);

    response.forEach(d => this.adapter(d, selectedMapAgents, divisions, wraups));

    this.interactionsDetailExcel.exportExcel(response, from, to, anis, contratos);
  }

  private adapter(info : InteractionsDetail, selectedMapAgents, divisions, wraups) {
    var name = selectedMapAgents[info.agente];
    info.agente = selectedMapAgents[info.agente];
    info.inicioDate = this.adapterDate(info.inicio);
    info.cliente = this.adapterPhone(info.cliente);
    info.dnis = this.adapterPhone(info.dnis);
    info.duracionTime = this.adapterHours(info.duracion);
    info.duracionTime = this.adapterHours(info.duracion);
    info.previewTime = this.adapterHours(info.preview);
    info.dialingTime = this.adapterHours(info.dialing);
    info.ringingTime= this.adapterHours(info.ringing);
    info.talkingTimeTime = this.adapterHours(info.talkingTime);
    info.holdTime = this.adapterHours(info.hold);
    info.acwTime = this.adapterHours(info.acw);
    info.enColaTime = this.adapterHours(info.enCola);

    info.empresas.forEach((empresa) => info.empresas = divisions[empresa]);
    var resultadoGestion = wraups[info.resultadoGestion];
    if(resultadoGestion !== undefined) {
      info.resultadoGestion = resultadoGestion;
    }
    if(info.resultadoGestion === undefined) {
      info.resultadoGestion = 'No Disp.';
    }
    
    info.agenteCliente = selectedMapAgents[info.agenteCliente];
  }
  private adapterHours3(time : number) : string {
    var quotient = Math.floor(time/1000);
    var seconds = quotient % 60;
    var quotient = Math.floor(quotient/60);
    var minutes = quotient % 60;
    var hours = Math.floor(quotient/60) ;

    var str = hours > 9 ? '' + hours : '0' + hours;
    var str = str + ':' + (minutes > 9 ? '' + minutes : '0' + minutes);
    var str = str + ':' + (seconds > 9 ? '' + seconds : '0' + seconds);

    return str;
  }
  private adapterHours(time : number) : Date {
    var date = new Date();
    date.setTime(0);
    date.setDate(-25538);
    date.setHours(date.getHours(), -75, -108, Math.floor(time/1000)*1000)
    return date;
  }
  private adapterDate(date : string) : Date {
    var year = parseInt(date.substring(0, 4));
    var month = parseInt(date.substring(5, 7)) - 1;
    var day = parseInt(date.substring(8, 10));
    var hours = parseInt(date.substring(11, 13)) - 3;
    var minutes = parseInt(date.substring(14, 16));
    var seconds = parseInt(date.substring(17, 19));
    var milliseconds = 0;
    if(date.indexOf('.') >= 0)
      milliseconds = parseInt(date.substring(20, date.length - 1));
    
		return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }
  private adapterPhone(number : string) : string {
    if(number === undefined) {
      return number;
    }
    
    if(number.startsWith('tel:+540')) {
      return number.substr(8);
    }

    if(number.startsWith('tel:+54')) {
      return number.substr(7);
    }

    if(number.startsWith('tel:540')) {
      return number.substr(7);
    }

    if(number.startsWith('tel:54')) {
      return number.substr(6);
    }

    if(number.startsWith('tel:0')) {
      return number.substr(5);
    }

    if(number.startsWith('tel:')) {
      return number.substr(4);
    }

    if(number.startsWith('sip:Anonymous')) {
      return '';
    }

    return number;
  }
}
