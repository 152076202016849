import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { COMMA, ENTER, F, TAB } from '@angular/cdk/keycodes';
import { Campaign }  from '../../models/records-detail/campaign';
import { ConversationDetailQueryPredicateDimension, PredicateDimension, SegmentDetailQueryPredicateDimension }  from '../../constants/purecloud';
import * as $ from "jquery"
import Swal from 'sweetalert2'
import { RecordsDetailExcelService} from 'src/app/services/records-detail/records-detail-excel.service'
import { RecordsDetailDataService } from 'src/app/services/records-detail/records-detail-data.service';
import { RecordsDetailCloudService } from 'src/app/services/records-detail/records-detail-cloud.service';
import { Task } from 'src/app/models/records-detail/task';

declare const duallistboxCampaignWrapupAccumulators: any;
declare const duallistboxCampaignWrapupAccumulatorsDummy: any;

@Component({
  selector: 'app-records-detail',
  templateUrl: './records-detail.component.html',
  styleUrls: ['./records-detail.component.css']
})
export class RecordsDetailComponent implements OnInit, DoCheck {
  readonly separatorKeysCodes = [ENTER, COMMA, TAB] as const;
  pickerFrom;
  myDatePickerFrom;
  pickerTo;
  myDatePickerTo;
  listCampaignSelected: any;
  selectedCampaigns: Campaign[] = [];
  allCampaigns: Array<Campaign>= [];
  campaignDummy: Array<Campaign>= [];
  duallistbox:any;
  runTimeCheck: boolean = false;

  constructor(private dataService : RecordsDetailDataService, private excelService : RecordsDetailExcelService, private cloudService : RecordsDetailCloudService) { }



  ngOnInit(): void {
    this.loadUsers();
  }
  loadCampaign(campaign) {
    this.allCampaigns.push({
      name: campaign.name,
      id: campaign.id
    });
  }
  loadUsers() {
    this.cloudService.getCampaigns()
    .then((response) => {
      response.forEach((user) => this.loadCampaign(user));
    })
    .catch((response) => alert(response));
  }

  ngDoCheck(): void {
    var duallistbox = $('.duallistbox-campaign-wrapup-accumulators');
    if(this.duallistbox == undefined && duallistbox[0].length > 0) {
      this.duallistbox = duallistbox;
      console.log(duallistbox);
      duallistboxCampaignWrapupAccumulatorsDummy.bootstrapDualListbox('destroy');
      $('.duallistbox-campaign-wrapup-accumulators-dummy').hide()

      duallistboxCampaignWrapupAccumulators.bootstrapDualListbox({
        moveOnSelect: false,
        infoTextEmpty: 'Lista vacia',
        infoText: false,
        filterPlaceHolder: 'Filtrar por campaña',
        moveSelectedLabel: 'Mover soleccionado',
        moveAllLabel: 'Mover todos',
        removeSelectedLabel: 'Borrar seleccionado',
        removeAllLabel: 'Borrar todos',
        infoTextFiltered: 'Filtrado {0} de {1}',
        filterTextClear: 'Mostrar todo'
      });
    }

    var listAgentSelected = $("#bootstrap-duallistbox-selected-list_campaigns");
    if(this.listCampaignSelected == undefined && listAgentSelected.length) {
      this.listCampaignSelected = listAgentSelected;

      const observer = new MutationObserver(
        () => { listAgentSelected.trigger('mutated') }
      )
      observer.observe(listAgentSelected[0], { childList: true })
      listAgentSelected.on('mutated', () => this.onCampaignChange())
    }

  }
  onCampaignChange(): void {
    this.selectedCampaigns = [];
    for (var i = 0; i < this.listCampaignSelected[0].length; i++) {
      var agent = this.listCampaignSelected[0][i];
      this.selectedCampaigns.push({name: agent.text, id: agent.value});
    }
    console.log('RuintimeCheck es: ', this.runTimeCheck); //! Borrar esto
  }

  onRangeDate(type: string): void {
    var now = new Date();
    switch(type) {
      case 'TODAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'YESTERDAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        break;
        case'LAST_7_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'THIS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom .getDate() + 7, 0, 0, 0, 0);
        break;
        case'PREVIOUS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7 + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        break;
        case'LAST_31_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
      default:
    }
  }

  async onGenerateCampaignWrapupAccumulators() {
    console.info('pickerFrom: ' + this.pickerFrom + ', myDatePickerFrom: ' + this.myDatePickerFrom + ', pickerTo: ' + this.pickerTo + ', myDatePickerTo: ' + this.myDatePickerTo);
    if(this.myDatePickerFrom == undefined || this.myDatePickerTo == undefined) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>No se definio rango de fechas correctamente</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var listAgentNoSelected = $("#bootstrap-duallistbox-nonselected-list_campaigns");
    if(listAgentNoSelected[0].length > 0 && this.selectedCampaigns.length > 100) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>Se supero la cantidad de campañas permitidas: 100</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var from = new Date(this.myDatePickerFrom); //new Date(2021, 4, 23, 3, 0, 0,0); //'2021-04-23T03:00:00.000Z'
    var to = new Date(this.myDatePickerTo); //new Date(2021, 4, 24, 3, 0, 0,0); //'2021-04-24T03:00:00.000Z'

    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    var limit :Date = this.getLimitDate(await (await this.cloudService.getConversationsAvailability()).dataAvailabilityDate);
    if( !this.runTimeCheck && limit.getTime() < to.getTime() ){
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>La fecha supera a la base de datos</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var selectedIdCampaigns = [];
    var selectedMapCampaigns = {};
    for (var i = 0; i < this.selectedCampaigns.length; i++) {
      selectedIdCampaigns.push(this.selectedCampaigns[i].id);
    }
    for (var i = 0; i < this.allCampaigns.length; i++) {
      selectedMapCampaigns[this.allCampaigns[i].id] = this.allCampaigns[i].name;
    }

    let wraups = await this.cloudService.getWraUps();
    let wrapMap = await this.cloudService.getOutboundWrapupcodemappings();

    var predicate = {};
    predicate[PredicateDimension.conversation] = {}
    predicate[PredicateDimension.conversation][ConversationDetailQueryPredicateDimension.originatingDirection] = ['outbound'];
    predicate[PredicateDimension.conversation][ConversationDetailQueryPredicateDimension.conversationEnd] = [];
    predicate[PredicateDimension.segment] = {}
    predicate[PredicateDimension.segment][SegmentDetailQueryPredicateDimension.outboundCampaignId] = selectedIdCampaigns;

    this.dataService.generate(from, to, predicate, this.runTimeCheck)
    .then((response) => this.processExcel(response, new Date(this.myDatePickerFrom), new Date(this.myDatePickerTo), selectedMapCampaigns, wraups,wrapMap))
    .catch(function(response) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b></b>',
        didOpen: () => {
          Swal.showLoading();
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              console.log(response);
              var result = undefined;
              if(response.body !== undefined && response.body.message !== undefined) {
                result = response.body.message
              }
              if(response.error !== undefined && response.error.message !== undefined) {
                result = response.error.message + " (" + response.error.errno + ")";
              }
              if(result === undefined) {
                result = response;
              }
              b.textContent = JSON.stringify(result);
            }
          }
        },
        willClose: () => {
          window.location.href = window.location.href
        }
      }).then((result) => {
        console.log(result);
      })
    });
  }

  private processExcel(response : Object[], from : Date, to : Date, selectedMapCampaigns, wraups,wrapMap): void {
    response.forEach( (element: Task) => {
      //const keys = Object.keys(element);
      element.campania = (element.campania !== undefined) ? this.getCampaignName(element.campania) : this.getCampaignName('');

      element.causaTerminacion = ( wraups[element.campania] !== undefined ) ? wraups[element.campania] : element.causaTerminacion;

      element.estado = this.getEstado(element.ultimoWrapup, wrapMap);

      element.resultadoGestion = ( element.resultadoGestion ===undefined || element.resultadoGestion === "" ) ? 'N/A' : element.resultadoGestion;

      /*
      keys.forEach(key => {// cambiar esto ya que Task no es algo dinamico
        var name = wraups[key];
        var value = element[key];
        if(name === undefined) {
          name = key;
        }
        //delete element[key];
        if(value===undefined){
          value = "";
        }
        element[name] = value;
        if(key == "campania"){
          element[key]= this.getCampaignName(element[name]); //! X
        }
        if(key == "causaTerminacion" ){
          if(wraups[element[key]] !== undefined )
          element[key] = wraups[element[key]]; //! X
        }
        if(key == "ultimoWrapup"){
          element["estado"]= this.getEstado(element[key],wrapMap); //! X
        }
        if(key == "resultadoGestion"){
          if (element[key]===undefined || element[key]===""){
          element[key]= "N/A";//! X
          }
        }
      });
      */
    });

    this.excelService.exportExcel(response, from, to,selectedMapCampaigns, wraups);
  }
  getEstado(wrapId: string, wrapMap: any): string {
    var estado= wrapMap.mapping[wrapId];
    var estadoString = "";

  if (estado ===undefined){
    estadoString =wrapId;
  }
  else{

    estado.forEach( element => {
      if (element !== undefined){
        if (estadoString != ""){estadoString += " ";}
        if (element == "Number_UnCallable"){
         estadoString += element.replace("Number_UnCallable","Número no llamable");
        }
        if (element == "Right_Party_Contact"){
         estadoString += element.replace("Right_Party_Contact","Contacto correcto");
        }
        //aca faltaria el if de"contacto no llamable" que es Contact_UnCallable
        if(element !="Number_UnCallable" && element !="Right_Party_Contact" ){//este es por si hay un cuarto, y agregar != contact...
         estadoString = element;
       }
     }
     else{
      estadoString = wrapId;
     }
    });

}
  return estadoString;
  }

  getCampaignName(campaignId: string): string {
    var campaignName: string ="";
    this.allCampaigns.forEach(campaign => {
      if(campaign.id == campaignId)
        campaignName = campaign.name;
    }
    )
      return campaignName;
  }

  getLimitDate(dateString: string): Date{

    return new Date();
  }
}
