import { Component, DoCheck, OnInit } from '@angular/core';
import { COMMA, ENTER, F, TAB } from '@angular/cdk/keycodes';
import Swal from 'sweetalert2'
import { Campaign } from 'src/app/models/abandoned-interactions-detail/campaign';
import { ConversationDetailQueryPredicateDimension, PredicateDimension, SegmentDetailQueryPredicateDimension } from '../../constants/purecloud';
import * as $ from "jquery"
import { AbandonedInteractionsDetailDataService } from 'src/app/services/abandoned-interactions-detail/abandoned-interactions-detail-data.service';
import { AbandonedInteractionsDetailExcelService } from 'src/app/services/abandoned-interactions-detail/abandoned-interactions-detail-excel.service';
import { AbandonedInteractionsDetailCloudService } from 'src/app/services/abandoned-interactions-detail/abandoned-interactions-detail-cloud.service';
import { AbandonedDetail } from 'src/app/models/abandoned-interactions-detail/abandoned-detail';



declare const duallistboxAbandonedInteractionDetails: any;
declare const duallistboxAbandonedInteractionDetailsDummy: any;

@Component({
  selector: 'app-abandoned-interactions-detail',
  templateUrl: './abandoned-interactions-detail.component.html',
  styleUrls: ['./abandoned-interactions-detail.component.css']
})

export class AbandonedInteractionsDetailComponent implements OnInit, DoCheck {
  readonly separatorKeysCodes = [ENTER, COMMA, TAB] as const;
  pickerFrom;
  myDatePickerFrom;
  pickerTo;
  myDatePickerTo;
  listCampaignSelected: any;
  selectedCampaigns: Campaign[] = [];
  allCampaigns: Array<Campaign> = [];
  campaignDummy: Array<Campaign> = [];
  duallistbox: any;

  constructor(private dataService: AbandonedInteractionsDetailDataService, private excelService: AbandonedInteractionsDetailExcelService, private cloudService: AbandonedInteractionsDetailCloudService) { }

  ngOnInit(): void {
    this.loadUsers();
  }
  loadCampaign(campaign) {
    this.allCampaigns.push({
      name: campaign.name,
      id: campaign.id
    });
  }
  loadUsers() {
    this.cloudService.getCampaigns()
      .then((response) => {
        response.forEach((user) => this.loadCampaign(user));
      })
      .catch((response) => alert(response));
  }

  ngDoCheck(): void {
    var duallistbox = $('.duallistbox-abandoned-interaction-details');
    if (this.duallistbox == undefined && duallistbox[0].length > 0) {
      this.duallistbox = duallistbox;
      console.log(duallistbox);
      duallistboxAbandonedInteractionDetailsDummy.bootstrapDualListbox('destroy');
      $('.duallistbox-abandoned-interaction-details-dummy').hide()

      duallistboxAbandonedInteractionDetails.bootstrapDualListbox({
        moveOnSelect: false,
        infoTextEmpty: 'Lista vacia',
        infoText: false,
        filterPlaceHolder: 'Filtrar por campaña',
        moveSelectedLabel: 'Mover soleccionado',
        moveAllLabel: 'Mover todos',
        removeSelectedLabel: 'Borrar seleccionado',
        removeAllLabel: 'Borrar todos',
        infoTextFiltered: 'Filtrado {0} de {1}',
        filterTextClear: 'Mostrar todo'
      });
    }

    var listCampaignSelected = $("#bootstrap-duallistbox-selected-list_campaigns");
    if (this.listCampaignSelected == undefined && listCampaignSelected.length) {
      this.listCampaignSelected = listCampaignSelected;

      const observer = new MutationObserver(
        () => { listCampaignSelected.trigger('mutated') }
      )
      observer.observe(listCampaignSelected[0], { childList: true })
      listCampaignSelected.on('mutated', () => this.onCampaignChange())
    }

  }
  onCampaignChange(): void {
    this.selectedCampaigns = [];
    for (var i = 0; i < this.listCampaignSelected[0].length; i++) {
      var queue = this.listCampaignSelected[0][i];
      this.selectedCampaigns.push({ name: queue.text, id: queue.value });
    }
  }

  onRangeDate(type: string): void {
    var now = new Date();
    switch (type) {
      case 'TODAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
      case 'YESTERDAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        break;
      case 'LAST_7_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
      case 'THIS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        break;
      case 'PREVIOUS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7 + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        break;
      case 'LAST_31_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
      default:
    }
  }

  async onGenerateAbandonedInteractionDetails() {
    console.info('pickerFrom: ' + this.pickerFrom + ', myDatePickerFrom: ' + this.myDatePickerFrom + ', pickerTo: ' + this.pickerTo + ', myDatePickerTo: ' + this.myDatePickerTo);
    if (this.myDatePickerFrom == undefined || this.myDatePickerTo == undefined) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>No se definio rango de fechas correctamente</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var listCampaignNoSelected = $("#bootstrap-duallistbox-nonselected-list_campaigns");
    if (listCampaignNoSelected[0].length > 0 && this.selectedCampaigns.length > 100) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>Se supero la cantidad de campañas permitidas: 100</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var from = new Date(this.myDatePickerFrom); //new Date(2021, 4, 23, 3, 0, 0,0); //'2021-04-23T03:00:00.000Z'
    var to = new Date(this.myDatePickerTo); //new Date(2021, 4, 24, 3, 0, 0,0); //'2021-04-24T03:00:00.000Z'

    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    var selectedIdCampaigns = [];
    var selectedMapCampaigns = {};
    for (var i = 0; i < this.selectedCampaigns.length; i++) {
      selectedIdCampaigns.push(this.selectedCampaigns[i].id);
    }
    for (var i = 0; i < this.allCampaigns.length; i++) {
      selectedMapCampaigns[this.allCampaigns[i].id] = this.allCampaigns[i].name;
    }

    var predicate = {};
    predicate[PredicateDimension.conversation] = {}
    predicate[PredicateDimension.conversation][ConversationDetailQueryPredicateDimension.conversationEnd] = [];
    predicate[PredicateDimension.segment] = {}
    predicate[PredicateDimension.segment][SegmentDetailQueryPredicateDimension.queueId] = selectedIdCampaigns;

    this.dataService.generate(from, to, predicate, true)
      .then((response) => this.processExcel(response, new Date(this.myDatePickerFrom), new Date(this.myDatePickerTo))) //this.processInteractionsDetailExcel(response, new Date(this.myDatePickerFrom), new Date(this.myDatePickerTo), selectedMapAgents, divisions, wraups, this.selectedPhone, this.selectedContract))
      .catch(function (response) {
        Swal.fire({
          title: 'Cancelado',
          html: 'Se cancelo la tarea por... <b></b>',
          didOpen: () => {
            Swal.showLoading();
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector('b');
              if (b) {
                console.log(response);
                var result = undefined;
                if (response.body !== undefined && response.body.message !== undefined) {
                  result = response.body.message
                }
                if (response.error !== undefined && response.error.message !== undefined) {
                  result = response.error.message + " (" + response.error.errno + ")";
                }
                if (result === undefined) {
                  result = response;
                }
                b.textContent = JSON.stringify(result);
              }
            }
          },
          willClose: () => {
            window.location.href = window.location.href
          }
        }).then((result) => {
          console.log(result);
        })
      });
  }

  private processExcel(response: AbandonedDetail[], from: Date, to: Date): void {
    this.excelService.exportExcel(response, from, to);
  }
}
