export class AbandonedDetail {
  idInteraccion:string;
  segmento:number=0;
  fecha:Date;
  campania:string;
  tiempo:number=0;
  cliente:string;
  tipeo:string;

  purposeAnterior:string='';
  llegoAgente:boolean=false;
  interactuoAgente:boolean=false;
}