import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import * as platformClient  from 'purecloud-platform-client-v2';
import { PurecloudService }  from '../../services/purecloud/purecloud.service';
import { TimeRange }  from '../../services/purecloud/time-range';
import { DateTime }  from '../../utils/date-time';
import { InteractionsDetailParserService }  from '../../services/interactions-detail/interactions-detail-parser.service';
import { MapConversation }  from '../../utils/map-conversation';
import { InteractionsDetail }  from '../../models/interactions-detail/interactions-detail';

@Injectable({
  providedIn: 'root'
})
export class InteractionsDetailDataService extends InteractionsDetailParserService {
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  });

  constructor(private purecloud: PurecloudService) { 
    super();
  }
  
  generate(userIds : string[], from: Date, to: Date, isSync: boolean) : Promise<InteractionsDetail[]> {
    let promise = new Promise<InteractionsDetail[]>((resolve, reject) => {
      var calls : InteractionsDetail[] = [];
      var timeRange = new TimeRange(from, to);
      if(isSync) {
      this.downSync(userIds, timeRange, calls, resolve, reject);
      } else {
        this.downAsync(userIds, timeRange, calls, resolve, reject);
      }
    });
    return promise;
  }
  private processInternalCall(conversation: platformClient.Models.AnalyticsConversation, timeRange : TimeRange, calls : InteractionsDetail[]) {
    if(DateTime.convertStringToDate(conversation.conversationStart).getTime() >= timeRange.fromParcial.getTime()
      && DateTime.convertStringToDate(conversation.conversationEnd).getTime() <= timeRange.to.getTime()) {
        if(conversation.conversationId === 'b9b75b05-4b7d-49d9-8df2-5fe3bdf1bd14') {
          console.log(conversation);
        }
      this.processCall(MapConversation.analyticsConversation(conversation), calls)
    }
  }

  protected downSync(userIds : string[], timeRange : TimeRange, calls : InteractionsDetail[], resolve, reject) : void {
    if(timeRange.isOk()) {
      this.downSyncParcial(0, userIds, timeRange, calls, {}, resolve, reject);
    } else {
      resolve(calls);
    }
  }
  private downSyncParcial(index : number, userIds : string[], timeRange : TimeRange, calls : InteractionsDetail[], errors, resolve, reject) : void {
    index++;
    this.purecloud.postAnalyticsConversationsDetailsQueryByUserIds(timeRange.fromParcial, timeRange.toParcial, userIds, index)
    .then((queryResponse) => this.processSyncParcial(queryResponse.conversations, index, userIds,  timeRange, calls, errors, resolve, reject))
    .catch((response) => this.downSyncParcialCatch(index, userIds,  timeRange, calls, response, errors, resolve, reject));
  }
  private downSyncParcialCatch(index : number, userIds : string[], timeRange : TimeRange, calls : InteractionsDetail[], response, errors, resolve, reject) : void {
    if(response.error !== undefined && response.error.errno !== undefined) {
      var count = errors[response.error.errno + "_" + index]
      if(count === undefined) {
        count = 0;
      }
      index--;

      if(count < 3) {
        count++;
        errors[response.error.errno + "_" + index] = count;
        console.log("Errors: ", errors);
        this.downSyncParcial(index, userIds, timeRange, calls, errors, resolve, reject);
      } else {
        reject(response);
      }
    } else {
      reject(response);
    }
  }
  private processSyncParcial(conversations : platformClient.Models.AnalyticsConversation[], index : number, userIds : string[], timeRange : TimeRange, calls : InteractionsDetail[], errors, resolve, reject) {
    if(conversations == undefined || conversations.length == 0) {
      timeRange.addDate();
      this.downSync(userIds, timeRange, calls, resolve, reject);
    } else {
      timeRange.addCount(conversations.length);
      conversations.forEach((conversation) => this.processInternalCall(conversation, timeRange, calls));

      this.Toast.fire({
        title: 'Interacciones consideradas ' + calls.length + ' de ' + timeRange.count,
        timer: 1000
      });

      this.downSyncParcial(index, userIds, timeRange, calls, errors, resolve, reject);
    }
  }

  private downAsync(userIds : string[], timeRange : TimeRange, calls : InteractionsDetail[], resolve, reject) {
    var info = {
      html: undefined,
      cancel: false
    }

    Swal.fire({
      title: 'Esperando...',
      html: 'Se espera la respuesta de Purecloud <b></b>',
      didOpen: () => this.didOpenSwal(userIds, timeRange, calls, info, resolve, reject),
      willClose: () => {
        info.cancel = true;
      }
    }).then((result) => {
      console.log(result);
    })    
  }
  private async didOpenSwal(userIds : string[], timeRange : TimeRange, calls : InteractionsDetail[], info, resolve, reject) {
    Swal.showLoading();
    const content = Swal.getContent();
    if (content) {
      const b = content.querySelector('b');
      if (b) {
        b.textContent = '';
        info.html = b;
      }

      this.purecloud.postAnalyticsConversationsDetailsJobsByUserIds(timeRange.from, timeRange.to, userIds)
      .then((queryResponse) => this.checkStatusJob(queryResponse.jobId, userIds, timeRange, calls, 0, info, resolve, reject))
      .catch((response) => reject(response));
    }
  }
  private async checkStatusJob(jobId: string, userIds : string[], timeRange : TimeRange, calls : InteractionsDetail[], retry : number, info, resolve, reject) {
    await this.sleep(1000);
    retry++;
    this.purecloud.getAnalyticsConversationsDetailsJob(jobId)
    .then((statusResponse) => this.validateStatusJob(statusResponse.state, jobId, userIds, timeRange, calls, retry, info, resolve, reject))
    .catch((response) => reject(response));
  }
  private async validateStatusJob(state: string, jobId: string, queues : string[], timeRange : TimeRange, calls : InteractionsDetail[], retry : number, info, resolve, reject) {
    if(!info.cancel) {
      console.info('a: ' + retry);
      if(state === 'FULFILLED') {
        this.Toast.fire({
          title: 'Comenzando...',
          timer: 5000
        });

          this.purecloud.getAnalyticsConversationsDetailsJobResults(jobId)
        .then((resultResponse) => this.processJob(resultResponse.cursor, jobId, resultResponse.conversations, 0, queues, timeRange, calls, resolve, reject))
        .catch((response) => reject(response));
      } else {
        if (info.html) {
          info.html.textContent = ': ' + retry
        }
        await this.checkStatusJob(jobId, queues, timeRange, calls, retry, info, resolve, reject)
      }
    } else {
      reject('Cancelado');
    }
  }
  private processJob(cursor: string, jobId: string, conversations : platformClient.Models.AnalyticsConversation[], index : number, queues : string[], timeRange : TimeRange, calls : InteractionsDetail[], resolve, reject) {
      index++;
    if(conversations == undefined || conversations.length == 0) {
      resolve(calls);
    } else {
      timeRange.addCount(conversations.length);
      conversations.forEach((conversation) => this.processInternalCall(conversation, timeRange, calls));

      this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (1000*(index - 1) + conversations.length),
        timer: 5000
      });

      if(cursor == undefined) {
        resolve(calls);
      } else {
        this.purecloud.getAnalyticsConversationsDetailsJobResults(jobId, cursor)
        .then((resultResponse) => this.processJob(resultResponse.cursor, jobId, resultResponse.conversations, index, queues, timeRange, calls, resolve, reject))
        .catch((response) => reject(response));
      }
    }
  }
  private sleep(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
