import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { AgentLogin } from 'src/app/models/agent-login/agent-login';
import { Agent } from 'src/app/models/interactions-detail/agent';

@Injectable({
  providedIn: 'root'
})
export class AgentLoginExcelService {


  constructor() { }

  generateExcel(response: Object[]){
    var name = 'reporte_login_de_agentes';

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reporte');
    worksheet.properties.defaultRowHeight = 18;

    //? font tamaño alineacion y ancho de todas las celdas
    for(var i =1; i <= 7; i++){
      worksheet.getColumn(i).font = {name: 'Tahoma', size: 8}; //#ffffff white
      worksheet.getColumn(i).width = 20;
      worksheet.getColumn(i).alignment = { horizontal: 'center', vertical: 'middle' };
    }
    worksheet.getColumn(1).width = 33; //LOGINID
    worksheet.getColumn(3).width = 24; //FECHA
    worksheet.getColumn(6).numFmt = "[HH]:mm:ss"; //TIEMPO
    //? Los header's
    var headers = ['LOGINID', 'AGENTE','FECHA','LOGIN','LOGOUT','TIEMPO'];
    var row = worksheet.addRow(headers);
    row.alignment = { vertical: 'top' };
    row.font = {bold: true};
    //? custom exclusivo de los header's
    ['A1','B1','C1','D1','E1','F1'].map(key => {
      var cell = worksheet.getCell(key);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000099' },
        bgColor: { argb: '000099' }
      };
      cell.font = {color: {argb: 'FFFFFF'}, bold: true };
      cell.alignment = {horizontal : 'center'}
    });

    var keys = Object.keys(response[0]);
    keys.forEach( (key) => {
      if(key !== 'agentes'){
        var agentData: AgentLogin[] = response[0][key];
        for(var  i = 0; i < agentData.length; i++){
          if( agentData[i].nombreAgente !== '-' ){
            this.addRow(worksheet ,agentData[i]);
          }
        }
      }

    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }


  private addRow(worksheet: Worksheet, login: AgentLogin){

    var fecha = this.convertDateToDayStringCustom(new Date(login.fecha), '/');
    var logIn = this.convertDateToHour(new Date(login.logIn), ':');
    var logOut = this.convertDateToHour(new Date(login.logOut), ':');
    login.tiempoTime = this.adapterHours(login.tiempo);

    var columns = [ login.id, login.nombreAgente, fecha,
                    logIn, logOut, login.tiempoTime ];

    var row = worksheet.addRow(columns);
    row.height = 18;
    row.font = {name: 'Tahoma', size: 7, bold: true};
    row.alignment = { vertical: 'middle' };
    row.alignment = { horizontal: 'center' };

  }

  private toClockFormat(ms: number): string{
    var hours = Math.floor(ms/(1000*60*60));  //ms * min * sec
    var hoursRemainderMs = ms % (1000*60*60);
    var minutes = Math.floor(hoursRemainderMs/(1000*60)); //ms * sec
    var minutesRemainderMs = hoursRemainderMs % (1000*60);
    var seconds = Math.floor(minutesRemainderMs / 1000); //ms
    var hoursS = (hours<10)? hoursS = '0'+hours.toString() : hours.toString();
    var minutesS = (minutes<10)? minutesS = '0'+minutes.toString() : minutes.toString();
    var secondsS = (seconds<10)? secondsS = '0'+seconds.toString() : seconds.toString();
    // hh:mm:ss
    return hoursS + ':' + minutesS + ':' + secondsS;
  }
  private convertDateToDayStringCustom(date : Date, delimiter : string) : string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return  day + delimiter + month  + delimiter + year ;
  }
  private convertDateToHour(date : Date, delimiter : string) : string {
    var hours = '' + date.getHours();
    var minutes = '' + (date.getMinutes());
    var seconds = '' + date.getSeconds();

    if (hours.length < 2)
    hours = '0' + hours;
    if (minutes.length < 2)
      minutes = '0' + minutes;
    if (seconds.length < 2)
      seconds = '0' + seconds;


    return  hours + delimiter + minutes  + delimiter + seconds ;
  }
  private adapterHours(time : number) : Date {
    var date = new Date();
    date.setTime(0);
    date.setDate(-25538);
    date.setHours(date.getHours(), -75, -108, Math.floor(time/1000)*1000)
    return date;
  }
}
