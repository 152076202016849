import { Injectable } from '@angular/core';
import { PurecloudService } from '../purecloud/purecloud.service';
import * as platformClient from 'purecloud-platform-client-v2';

class getTelephonyProvidersEdgesDidsOptions implements platformClient.TelephonyProvidersEdgeApi.getTelephonyProvidersEdgesDidsOptions {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class EventCounterCloudService {
  

  constructor(private purecloudService : PurecloudService) { }
  
  getDids(): Promise<any> {
    return this.purecloudService.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
      this.getDidsInternal(new getTelephonyProvidersEdgesDidsOptions(100, 1), new Array<string>(), resolve, reject);
    });
  }
  private getDidsInternal(opts: getTelephonyProvidersEdgesDidsOptions, dids: Array<string>, resolve, reject) {
    this.purecloudService.getTelephonyProvidersEdge().getTelephonyProvidersEdgesDids(opts)
      .then((response) => this.thenDids(response, dids, resolve, reject))
      .catch((response) => this.catchDids(response, reject));
  }
  private thenDids(response: platformClient.Models.DIDEntityListing, dids: Array<string>, resolve, reject) {
    response.entities.forEach((did) => dids.push(did.phoneNumber));
    if(response.pageNumber < response.pageCount) {
      this.getDidsInternal(new getTelephonyProvidersEdgesDidsOptions(100, (response.pageNumber + 1)), dids, resolve, reject);
    } else {
      resolve(dids);
    }
  }
  private catchDids(response: any, reject) {
    reject(response);
  }

  public getMaxDate(): Promise<platformClient.Models.DataAvailabilityResponse> {
    return this.purecloudService.getConversations().getAnalyticsConversationsDetailsJobsAvailability();  
  }
}
