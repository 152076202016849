import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CampaignAccumulatorsComponent } from './components/campaign-accumulators/campaign-accumulators.component';
import { CampaignWrapupAccumulatorsComponent } from './components/campaign-wrapup-accumulators/campaign-wrapup-accumulators.component';
import { DownloaderComponent } from './components/downloader/downloader.component';
import { EnargasComponent } from './components/enargas/enargas.component';
import { FieldAccumulatorsComponent } from './components/field-accumulators/field-accumulators.component';
import { InteractionsDetailComponent } from './components/interactions-detail/interactions-detail.component';
import { PollsComponent } from './components/polls/polls.component';
import { RecordsDetailComponent } from './components/records-detail/records-detail.component';
import { NumberOfRecordsByTypeComponent } from './components/number-of-records-by-type/number-of-records-by-type.component';
import { AbandonedInteractionsDetailComponent } from './components/abandoned-interactions-detail/abandoned-interactions-detail.component';
import { AgentsLogsComponent } from './components/agents-logs/agents-logs.component';
import { EventCounterComponent } from './components/event-counter/event-counter.component';
import { AgentLoginComponent } from './components/agent-login/agent-login.component';


const routes: Routes = [
  {
    path: 'download',
    component: DownloaderComponent
  },
  {
    path: 'download/enargas',
    component: EnargasComponent
  },
  {
    path: 'download/interactions-detail',
    component: InteractionsDetailComponent
  },
  {
    path: 'download/campaign-wrapup-accumulators',
    component: CampaignWrapupAccumulatorsComponent
  },
  {
    path: 'download/campaign-accumulators',
    component: CampaignAccumulatorsComponent
  },
  {
    path: 'download/event-counter',
    component: EventCounterComponent
  },
  {
    path: 'download/polls',
    component: PollsComponent
  },
  {
    path: 'download/records-detail',
    component: RecordsDetailComponent
  },
  {
    path: 'download/abandoned-interactions-detail',
    component: AbandonedInteractionsDetailComponent
  },
  {
    path: 'download/number-of-records-by-type',
    component: NumberOfRecordsByTypeComponent
  },
  {
    path: 'download/agent-states-log-report',
    component: AgentLoginComponent
  },
  {
    path: 'download/agent-states-log',
    component: AgentsLogsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents= [RecordsDetailComponent]

