import { Injectable } from '@angular/core';
import { InteractionsDetail }  from '../../models/interactions-detail/interactions-detail';
import { ParticipantInteraction }  from '../../models/interactions-detail/participant-interaction';
import { Conversation , Participant , Session , Segment , Property }  from '../../models/purecloud/conversation';
import { DateTime }  from '../../utils/date-time';

@Injectable({
  providedIn: 'root'
})
export class InteractionsDetailParserService {

  variables = ['contrato', 'Contrato', 'referencia', 'Referencia'];
  constructor() { }

  protected processCall(conversation : Conversation, interactionsDetails : InteractionsDetail[]) {
    var interactionsDetail = new InteractionsDetail();
    interactionsDetail.conversationId = conversation.conversationId;
    interactionsDetail.segmento = 0;
    interactionsDetail.tipoContacto = conversation.participants[0].sessions[0].mediaType;
    interactionsDetail.cliente = conversation.participants[0].sessions[0].ani;
    interactionsDetail.dnis = conversation.participants[0].sessions[0].dnis;
    interactionsDetail.sentido = conversation.originatingDirection;
    interactionsDetail.duracion = DateTime.convertStringToDate(conversation.conversationEnd).getTime() - DateTime.convertStringToDate(conversation.conversationStart).getTime();
    interactionsDetail.preview = 0;
    interactionsDetail.dialing = 0;
    interactionsDetail.ringing = 0;
    interactionsDetail.talkingTime = 0;
    interactionsDetail.hold = 0;
    interactionsDetail.acw = 0;
    interactionsDetail.enCola = 0;

    interactionsDetail.equipo = conversation.participants[0].sessions[0].edgeId;
    interactionsDetail.abandonada = 0;
    interactionsDetail.empresas = conversation.divisionIds;

    interactionsDetail.transferIn = 0;
    interactionsDetail.transferOut = 0;


    var participantInteraction = {
      participant: undefined,
      interactionsDetail: interactionsDetail
    }

    conversation.participants.forEach(participant => this.processParticipantInteractionsDetail(participantInteraction, participant, interactionsDetails));
  }
  private processParticipantInteractionsDetail(participantInteraction : ParticipantInteraction, participant : Participant, interactionsDetails : InteractionsDetail[]) {
    participantInteraction.participant = participant;
    this.processParticipant(participantInteraction, interactionsDetails)
  }
  private processParticipant(participantInteraction : ParticipantInteraction, interactionsDetails : InteractionsDetail[]) {
    if(participantInteraction.interactionsDetail.derivada === undefined && (participantInteraction.participant.purpose === 'agent' || participantInteraction.participant.purpose === 'user')) {
      participantInteraction.interactionsDetail.derivada = 0;
    } else {
      participantInteraction.interactionsDetail.derivada = 1;
    }
    if(interactionsDetails.length > 0) {
      var interaction = interactionsDetails[interactionsDetails.length - 1];
      if(interaction.conversationId === participantInteraction.interactionsDetail.conversationId && interaction.origenCorte === 'transfer') {
        if(participantInteraction.participant.participantName !== 'SurveyDynamix' && participantInteraction.participant.sessions[0].direction === 'outbound') {
          interaction.transferOut = 1;
        }
        if(participantInteraction.participant.sessions[0].direction === 'inbound') {
          interaction.transferIn = 1;
        }
      }
    }

    if(undefined !== participantInteraction.participant.attributes){

      for(var i=0; i<this.variables.length; i++){
        var variable = this.variables[i];
        var value = participantInteraction.participant.attributes[variable];
        if((undefined !== value) &&  (value  !== '0')){
          participantInteraction.interactionsDetail.contrato = value;
          break;
        }
      }
    }
    
    switch(participantInteraction.participant.purpose) {
      case 'customer':
      case 'external':
        this.processCustom(participantInteraction.participant, participantInteraction.interactionsDetail, interactionsDetails);
        break;
      case 'acd':
        this.processACD(participantInteraction.participant, participantInteraction.interactionsDetail, interactionsDetails);
        break;
      case 'customer':
      case 'external':
      case 'agent':
      case 'user':
          this.processAgent(participantInteraction, interactionsDetails);
        break;
    }

    if(participantInteraction.interactionsDetail.segmentoTipo !== participantInteraction.participant.purpose) {
      participantInteraction.interactionsDetail.segmento = participantInteraction.interactionsDetail.segmento + 1;
      participantInteraction.interactionsDetail.segmentoTipo = participantInteraction.participant.purpose;
    }
  }
  private processCustom(participant : Participant, interactionsDetail : InteractionsDetail, interactionsDetails : InteractionsDetail[]) {
    if(interactionsDetail.custom) {
      interactionsDetail.custom = false;
    } else {
      participant.sessions.forEach((session) => this.processSessionAgent(session, interactionsDetail));
    }
  }
  private processACD(participant : Participant, interactionsDetail : InteractionsDetail, interactionsDetails : InteractionsDetail[]) {
    interactionsDetail.campania = participant.participantName;
    participant.sessions.forEach((session) => this.processSessionACD(session, interactionsDetail));
  }
  private processSessionACD(session : Session, interactionsDetail : InteractionsDetail) {
    session.segments.forEach((segment) => this.processSegmentACD(segment, interactionsDetail));
  }
  private processSegmentACD(segment : Segment, interactionsDetail : InteractionsDetail) {
    interactionsDetail.enCola += DateTime.getTimeBySegment(segment);
  }
  private processAgent(participantInteraction : ParticipantInteraction, interactionsDetails : InteractionsDetail[]) {
    participantInteraction.interactionsDetail.agente = participantInteraction.participant.userId;
    participantInteraction.participant.sessions.forEach((session) => this.processSessionAgent(session, participantInteraction.interactionsDetail));

    if(interactionsDetails.length > 0) {
      var last = interactionsDetails[interactionsDetails.length - 1];
      if(last.conversationId === participantInteraction.interactionsDetail.conversationId) {
        last.abandonada = 0;
      }
    }

    interactionsDetails.push(participantInteraction.interactionsDetail);
    participantInteraction.interactionsDetail = JSON.parse(JSON.stringify(participantInteraction.interactionsDetail));
    participantInteraction.interactionsDetail.inicio = undefined;
    participantInteraction.interactionsDetail.agenteCliente = participantInteraction.interactionsDetail.agente;
    participantInteraction.interactionsDetail.agente = '';
    participantInteraction.interactionsDetail.preview = 0;
    participantInteraction.interactionsDetail.dialing = 0;
    participantInteraction.interactionsDetail.ringing = 0;
    participantInteraction.interactionsDetail.talkingTime = 0;
    participantInteraction.interactionsDetail.hold = 0;
    participantInteraction.interactionsDetail.acw = 0;
    participantInteraction.interactionsDetail.enCola = 0;
    participantInteraction.interactionsDetail.abandonada = 0;
    participantInteraction.interactionsDetail.custom = false;
  }
  private processSessionAgent(session : Session, interactionsDetail : InteractionsDetail) {
    interactionsDetail.entrante = session.direction;
    session.segments.forEach((segment) => this.processSegmentAgent(segment, interactionsDetail));
  }
  private processSegmentAgent(segment : Segment, interactionsDetail : InteractionsDetail) {
    if(segment.properties) {
      segment.properties.forEach((property) => this.processPropertyAgent(property, interactionsDetail));
    }
    if(interactionsDetail.inicio === undefined) {
      interactionsDetail.inicio = segment.segmentStart;
    }


    switch(segment.segmentType) {
      case 'alert':
        interactionsDetail.ringing += DateTime.getTimeBySegment(segment);
        break;
      case 'hold':
        interactionsDetail.hold += DateTime.getTimeBySegment(segment);
        break;
      case 'wrapup':
        interactionsDetail.acw += DateTime.getTimeBySegment(segment);
        interactionsDetail.resultadoGestion = segment.wrapUpCode;
        interactionsDetail.nota = segment.wrapUpNote;
        break;
      case 'interact':
        interactionsDetail.talkingTime += DateTime.getTimeBySegment(segment);
        break;
      case 'dialing':
        interactionsDetail.dialing += DateTime.getTimeBySegment(segment);
        break;
      default:
        break;
    }
    if(segment.disconnectType) {
      interactionsDetail.origenCorte = segment.disconnectType;
      if(interactionsDetail.talkingTime === 0) {
        interactionsDetail.abandonada = 1;
      }
    }
}
private processPropertyAgent(property : Property, interactionsDetail : InteractionsDetail) {
  if(property.property === 'contrato')
    interactionsDetail.contrato = property.value;
}

}
