import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER, F, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-field-accumulators',
  templateUrl: './field-accumulators.component.html',
  styleUrls: ['./field-accumulators.component.css']
})
export class FieldAccumulatorsComponent implements OnInit {
  readonly separatorKeysCodes = [ENTER, COMMA, TAB] as const;
  pickerFrom;
  myDatePickerFrom;
  pickerTo;
  myDatePickerTo;
  carsDummy: Array<any>= [];
  selectedContract: string[] = [];
  selectedPhone: string[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  onRangeDate(type: string): void {
    var now = new Date();
    switch(type) {
      case 'TODAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'YESTERDAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        break;
        case'LAST_7_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'THIS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom .getDate() + 7, 0, 0, 0, 0);
        break;
        case'PREVIOUS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7 + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        break;
        case'LAST_31_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
      default:
    }
  }

  async onGenerateInteractionsDetail() {
  }
  addContract(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.selectedContract.push(value);
    }
    event.input.value = "";
  }
  removeContract(value: string): void {
    const index = this.selectedContract.indexOf(value);
    if (index >= 0) {
      this.selectedContract.splice(index, 1);
    }
  }
  addPhone(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.selectedPhone.push(value);
    }
    event.input.value = "";
  }
  removePhone(value: string): void {
    const index = this.selectedPhone.indexOf(value);
    if (index >= 0) {
      this.selectedPhone.splice(index, 1);
    }
  }
}
