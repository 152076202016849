import { T } from '@angular/cdk/keycodes';
import { Injectable, WrappedValue } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { countReset } from 'console';
import { Workbook, Worksheet , ValueType, Cell} from 'exceljs';
import * as fs from 'file-saver';
import { networkInterfaces } from 'os';
import { clearLine } from 'readline';
import { RBTCampaign } from 'src/app/models/number-of-records-by-type/rbtcampaign';
import { RBTCampaignWithStates } from 'src/app/models/number-of-records-by-type/rbtCampaignWithStates';
import { RBTDatosFecha } from 'src/app/models/number-of-records-by-type/rbtdatosFecha';
import { RBTEstado } from 'src/app/models/number-of-records-by-type/rbtestado';
import { Task } from 'src/app/models/number-of-records-by-type/rbttask';
import { ConversationDataOpenedPurecloudService } from '../purecloud/conversation-data-opened-purecloud.service';
@Injectable({
  providedIn: 'root'
})
export class NumberOfRecordsByTypeExcelService {

  constructor() { }

  exportExcel(info : Object[], from: Date, to: Date,selectedMapCampaigns, wraups ,selectedQueues) {
    var headers = ['Campaña', 'Fecha', 'Estado','Causa','Cantidad'];
    var headers2 = ['Campaña', 'Estado','Causa','Cantidad'];
    var name = 'Detalle-de-registros-por-tipo';
    
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Detalle por registro por tipo');
    let worksheet2 = workbook.addWorksheet('Totalizado por rango de fecha');

    worksheet.getColumn(1).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(1).width = 15.27;
    worksheet.getColumn(1).alignment = { horizontal: 'left' };
    worksheet.getColumn(2).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(2).width = 15.27;
    worksheet.getColumn(2).alignment = { horizontal: 'right' };
    worksheet.getColumn(3).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(3).width = 15.27;
    worksheet.getColumn(3).alignment = { horizontal: 'right' };
    worksheet.getColumn(4).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(4).width = 15.27;
    worksheet.getColumn(4).alignment = { horizontal: 'right' };
    worksheet.getColumn(5).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(5).width = 15.27;
    worksheet.getColumn(5).alignment = { horizontal: 'right' };
    

    worksheet2.getColumn(1).font = {name: 'Tahoma', size: 8 };
    worksheet2.getColumn(1).width = 15.27;
    worksheet2.getColumn(1).alignment = { horizontal: 'left' };
    worksheet2.getColumn(2).font = {name: 'Tahoma', size: 8 };
    worksheet2.getColumn(2).width = 15.27;
    worksheet2.getColumn(2).alignment = { horizontal: 'right' };
    worksheet2.getColumn(3).font = {name: 'Tahoma', size: 8 };
    worksheet2.getColumn(3).width = 15.27;
    worksheet2.getColumn(3).alignment = { horizontal: 'right' };
    worksheet2.getColumn(4).font = {name: 'Tahoma', size: 8 };
    worksheet2.getColumn(4).width = 15.27;
    worksheet2.getColumn(4).alignment = { horizontal: 'right' };
    worksheet2.getColumn(5).font = {name: 'Tahoma', size: 8 };
    worksheet2.getColumn(5).width = 15.27;
    worksheet2.getColumn(5).alignment = { horizontal: 'right' };
    

    var row = worksheet.addRow(['']);
    row.height = 20;
    var row = worksheet.addRow(['Detalle de registros por Tipo- ( ' + this.formatDate(from) + ' ' + this.formatHour(from) + ' a ' + this.formatDate(to) + ' ' + this.formatHour(to) + ' )']);
    row.getCell(1).font = {name: 'Calibri Light', size: 10, bold: true};
    row.getCell(1).alignment = { horizontal: 'left', vertical: 'top' };
    row.height = 20;
    var row = worksheet.addRow(['']);
    row.height = 10;

    var row2 = worksheet2.addRow(['']);
    row2.height = 20;
    var row2 = worksheet2.addRow(['Detalle de registros por Tipo- ( ' + this.formatDate(from) + ' ' + this.formatHour(from) + ' a ' + this.formatDate(to) + ' ' + this.formatHour(to) + ' )']);
    row2.getCell(1).font = {name: 'Calibri Light', size: 10, bold: true};
    row2.getCell(1).alignment = { horizontal: 'left', vertical: 'top' };
    row2.height = 20;
    var row2 = worksheet2.addRow(['']);
    row2.height = 10;


    worksheet.mergeCells('A1:B1');
    worksheet.mergeCells('A2:B2');
    worksheet.mergeCells('A3:B3');

    worksheet2.mergeCells('A1:B1');
    worksheet2.mergeCells('A2:B2');
    worksheet2.mergeCells('A3:B3');


    var row = worksheet.addRow(headers);
    var row2 = worksheet2.addRow(headers2);
    
    row.height = 18;
    row.font = {name: 'Tahoma', size: 7, bold: true};
    row.alignment = { vertical: 'top' };
    row.getCell(1).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(2).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(3).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(4).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(5).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    
    row2.height = 18;
    row2.font = {name: 'Tahoma', size: 7, bold: true};
    row2.alignment = { vertical: 'top' };
    row2.getCell(1).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row2.getCell(2).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row2.getCell(3).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row2.getCell(4).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    
    var counter = {
      title: 'Total general',
      count: 0,
      sum: 0
    };
    var counter2 = {
      title: 'Total general',
      count: 0,
      sum: 0
    };
    var sum = 0;
   //console.log(info);
   
   var procesedInfo:RBTCampaign[] = [];
   
   info.forEach(d => this.processData(d,procesedInfo,selectedQueues));
   var count = 0; 
   //console.log(procesedInfo);
   this.addToExcel(procesedInfo, counter, worksheet);
   var row = worksheet.addRow([counter.title,'','', '' ,counter.sum]);
   row.eachCell(a => 
    a.fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'445599'}
    }
    );
  
   var procesedInfo2:RBTCampaignWithStates[] = [];
   info.forEach(pi => this.processDataAllDates(pi,procesedInfo2,selectedQueues));
   
   //console.log(procesedInfo2);
   this.addToExcel2(procesedInfo2, counter2, worksheet2);
   console.log("counter: " + counter.sum)
   var row = worksheet2.addRow([counter2.title,'','', counter2.sum]);
   
   row.eachCell(a => 
    a.fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'445599'}
    }
   );

   workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }


  private addToExcel2(procesedInfo2: RBTCampaignWithStates[], counter2: { title: string; count: number; sum: number; }, worksheet2: Worksheet) {
    var i= 0;
    var startCell: string=worksheet2.getCell("A1").$col$row;
    var endCell: string =worksheet2.getCell("A1").$col$row;
    while(i< procesedInfo2.length){
      this.addDataRow2(procesedInfo2[i],counter2,worksheet2,startCell,endCell);
      i= i+1;
    }
    
   
  }

  private addToExcel(procesedInfo: RBTCampaign[], counter: { title: string; count: number; sum: number; }, worksheet: Worksheet) {
   var i=0;
   var startCell :string = worksheet.getCell("A1").$col$row;
   var endCell: string = worksheet.getCell("A1").$col$row;
   var startEnd : string[] = [];
   while(i <  procesedInfo.length){
      startEnd = this.addDataRow(procesedInfo[i],counter,worksheet,startCell,endCell);
    
     i= i+1;
   }
  }

  private processData(info: Object, procesedInfo: RBTCampaign[],selectedQueues): void {
    console.log("d: " ,info);
    var task: Task = this.infoToTask(info);
    var existeFecha= false;
    var existeEstado = false;
    var existeCampania =false;
    procesedInfo.forEach(pi => {
       if (task.campania == pi.name && contiene(pi.name,selectedQueues)){
        existeCampania = true;
        pi.datosFecha.forEach(df => {
          if(df.fecha == task.fecha){
            existeFecha = true;
            df.estados.forEach(e => {
              if(e.estado == task.estado && e.causaTerminacion == task.causaTerminacion){
                e.cantidad = e.cantidad +1;
                existeEstado = true;
              }
            })
            //Si el estado no existe
            if (existeEstado == false){
            var estado : RBTEstado = new RBTEstado();
            estado.estado = task.estado;
            estado.causaTerminacion = task.causaTerminacion;
            estado.cantidad = 1;
            df.estados.push(estado);  
            }
            existeEstado == false;
          }
        })
        //Si la fecha no existe en la campania
        if (existeFecha == false){
        var datosFecha : RBTDatosFecha = new RBTDatosFecha();
        datosFecha.fecha = task.fecha;
        var estado : RBTEstado =new RBTEstado();
        estado.estado= task.estado;
        estado.causaTerminacion = task.causaTerminacion;
        estado.cantidad =  1;
        datosFecha.estados.push(estado);
        pi.datosFecha.push(datosFecha);
        }
        existeFecha = false;
       }
      
      });
      //Si la campania no existia
      if(existeCampania == false && contiene(task.idCampania,selectedQueues)){
      var nuevoDato = new RBTCampaign();
      nuevoDato.id= task.idCampania;
      nuevoDato.name=task.campania;
      
      var datosFecha : RBTDatosFecha = new RBTDatosFecha();
      datosFecha.fecha= task.fecha;
      var estado : RBTEstado = new RBTEstado;
      estado.estado= task.estado;
      estado.causaTerminacion=task.causaTerminacion;
      estado.cantidad = 1;
      datosFecha.estados.push(estado);
      nuevoDato.datosFecha.push(datosFecha);
      procesedInfo.push(nuevoDato);
    }
    existeCampania = false;
      }
  
  private processDataAllDates(info: Object, procesedInfo: RBTCampaignWithStates[],selectedQueues): void {
    var task: Task = this.infoToTask(info);
    var existeCampania = false;
    var existeEstado = false;

    procesedInfo.forEach(df => {
      if (task.campania === df.name && contiene(task.idCampania,selectedQueues)){
        existeCampania = true;
        df.estados.forEach(e => {
          if(e.estado === task.estado && e.causaTerminacion === task.causaTerminacion){
           e.cantidad = e.cantidad +1;
           existeEstado = true;
          }
        })
        if (existeEstado == false){
          var estado : RBTEstado = new RBTEstado();
          estado.estado = task.estado;
          estado.causaTerminacion = task.causaTerminacion;
          estado.cantidad = 1;
          df.estados.push(estado);
        }
        existeEstado = false;
      }
     
    });
    if(existeCampania == false && contiene(task.idCampania,selectedQueues) ){
      var nuevoDato = new RBTCampaignWithStates();
      nuevoDato.id= task.idCampania;
      nuevoDato.name=task.campania;
      var estado : RBTEstado = new RBTEstado;
      estado.estado= task.estado;
      estado.causaTerminacion=task.causaTerminacion;
      estado.cantidad = 1;
      nuevoDato.estados.push(estado);
      procesedInfo.push(nuevoDato);
    }
  
  }
  
  
  private infoToTask(info: Object) {
    const keys = Object.keys(info);
    var task: Task = new Task();
    keys.forEach(key => {
      if (key === 'campania') {
        task.campania = info[key];
      }
      if (key === 'estado') {
        task.estado = info[key];
      }
      if (key === 'fecha') {
        task.fecha = info[key];
      }
      if (key === 'idCampania') {
        task.idCampania = info[key];
      }
      if (key === 'causaTerminacion') {
        task.causaTerminacion = info[key];
      }
      if (key === 'ultimoWrapup') {
        task.ultimoWrapup = info[key];
       // task.causaTerminacion= info[key];
      }
    });
    return task;
  }

  public formatDate(date: Date) : string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
  }

  public formatHour(date: Date) : string {
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();

    if (hour.length < 2) 
      hour = '0' + hour;
    if (minute.length < 2) 
      minute = '0' + minute;
    if (second.length < 2) 
      second = '0' + second;

    return [hour, minute, second].join(':');
  }

  private async addDataRow2(info : RBTCampaignWithStates, counter, worksheet2: Worksheet,startCell:string,endCell:string) {
    var name = info.name;
    var start = 0;
    var quantity =0;
        info.estados.forEach(e => {
          var newRow = [];
          newRow.push(info.name);
          newRow.push(e.estado);
          newRow.push(e.causaTerminacion);
          newRow.push(e.cantidad);
          counter.sum = counter.sum + e.cantidad;
          counter.count = counter.count+e.cantidad;
          quantity = quantity +e.cantidad;
          var row = worksheet2.addRow(newRow);
          if (start === 0){
            startCell =row.getCell(1).$col$row;
            start = 1;
          }
          else{
            endCell=row.getCell(1).$col$row;
          }
        })
        
        var sumrow = ["","Subtotal","", quantity];
        var sumrow2=  worksheet2.addRow(sumrow);
        sumrow2.eachCell(a => 
          a.fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'8899BB'}
          }
         );
        
        endCell = sumrow2.getCell(1).$col$row; 
        
        if (startCell!== worksheet2.getCell("A1").$col$row && endCell !== worksheet2.getCell("A1").$col$row){
          worksheet2.mergeCells(startCell,endCell);
          worksheet2.getCell(endCell).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'8899BB'}
          };
        }
  }



  private addDataRow(info : RBTCampaign, counter , worksheet: Worksheet,startCell:string,endCell: string): string[] {
    var fecha :String;
    var start = 0;
    var startDateCell :string = worksheet.getCell("A1").$col$row;
    var endDateCell : string = worksheet.getCell("A1").$col$row;
    var df = info.datosFecha;
    var startEnd=[];
    counter.count = 0;
    startEnd = this.aggregateDate(df, fecha, info, counter, worksheet, start, startCell, endCell,startDateCell,endDateCell); //end while
    console.log(startEnd[0]);
    console.log(startEnd[1]);
    console.log(startEnd[2]);
    startCell= startEnd[0];
 
    var subTot = ["","Subtotal","","",startEnd[2]];
    var subtRow = worksheet.addRow(subTot);
    subtRow.eachCell(a => 
      a.fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'7788BB'}
      }
     )
    
    console.log("addedRow");
    endCell = subtRow.getCell(1).$col$row;
    console.log("end cell: " + startCell+" " +endCell);
    if(startCell!== worksheet.getCell("A1").$col$row && endCell!== worksheet.getCell("A1").$col$row){
       worksheet.mergeCells(startCell,endCell);
       worksheet.getCell(endCell).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'7788BB'}
      };
    }
    
    return startEnd; 
     

  }
  
 


  private aggregateDate( dfecha: RBTDatosFecha[], fecha: String, info: RBTCampaign, counter: any, worksheet: Worksheet, start: number, startCell: string, endCell: string,startDateCell,endDateCell):string[] {
    var i =0;
    
    while (i < dfecha.length) {
      var quantity = 0;
      var df :RBTDatosFecha = dfecha[i];
      i = i +1;
      fecha = df.fecha;
      var dateStarted = 0;
      df.estados.forEach(e => {
        var newRow = [];
        newRow.push(info.name);
        newRow.push(fecha);
        newRow.push(e.estado);
        if (e.causaTerminacion === undefined || e.causaTerminacion === '') {
         e.causaTerminacion = 'N/A';
        }
        newRow.push(e.causaTerminacion);
        newRow.push(e.cantidad);
        counter.count = counter.count + e.cantidad;
        counter.sum = counter.sum + e.cantidad;
        quantity = quantity + e.cantidad;
        var row = worksheet.addRow(newRow);
        if (start === 0) {
          startCell = row.getCell(1).$col$row;
          start = 1;
        }
        else{ endCell = row.getCell(1).$col$row;
        }
        if (dateStarted === 0) {
          startDateCell = row.getCell(2).$col$row;
          dateStarted = 1;
        }
        else{ 
          endDateCell = row.getCell(2).$col$row; 
        }
      });
      
      var sumrow = ["","","Subtotal","", quantity];
      var sumrow2 =  worksheet.addRow(sumrow);
      sumrow2.eachCell(a => 
        a.fill = {
          type: 'pattern',
          pattern:'solid',
          fgColor:{argb:'8899BB'}
        }
       )
          
      endDateCell = sumrow2.getCell(2).$col$row;
      endCell =  sumrow2.getCell(1).$col$row;
      if(startDateCell!== worksheet.getCell("A1").$col$row && endDateCell!== worksheet.getCell("A1").$col$row){
           worksheet.mergeCells(startDateCell,endDateCell);
           worksheet.getCell(endDateCell).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'8899BB'}
          };
      } 

    } //end while
    
    var startEnd = [startCell,endCell, counter.count];
    
    return startEnd;
  }
  
}



//TODO: Selected queues marca todo, falta filtrar por solo los utiles
function contiene(name: string, selectedQueues:string[]):boolean {
  var contiene = false;
 
  selectedQueues.forEach(element => {
    if(name === element){
      contiene = true;
    }
  });
  return contiene;
}

