import { Injectable } from "@angular/core";
import * as platformClient from "purecloud-platform-client-v2";
import { Conversation } from "src/app/models/purecloud/conversation";
import { DateTime } from "src/app/utils/date-time";
import { MapConversation } from "src/app/utils/map-conversation";
import { ConversationDataPurecloudService } from "../purecloud/conversation-data-purecloud.service";
import { PurecloudService } from "../purecloud/purecloud.service";
import { TimeRange } from "../purecloud/time-range";

@Injectable({
  providedIn: "root",
})
export class CampaignWrapupAccumulatorsDataService extends ConversationDataPurecloudService {
  constructor(private purecloudService: PurecloudService) {
    super(purecloudService);
  }

  protected processInternalCall(
    conversation: platformClient.Models.AnalyticsConversation,
    timeRange: TimeRange,
    calls: Object[]
  ) {
    var from = DateTime.convertStringToDate(conversation.conversationStart);
    //if(timeRange.fromParcial.getTime() === timeRange.from.getTime() && from.getTime() <= timeRange.from.getTime() ) {
    //  this.processSegmentCall(MapConversation.analyticsConversation(conversation), timeRange, calls);
    //  return;
    //}

    if (from.getTime() > timeRange.fromParcial.getTime()) {
      this.processSegmentCall(
        MapConversation.analyticsConversation(conversation),
        timeRange,
        calls
      );
    }
  }

  public processCall(conversation: Conversation, calls: Object[]) {}

  public processSegmentCall(
    conversation: Conversation,
    timeRange: TimeRange,
    calls: Object[]
  ) {
    if (calls.length == 0) {
      calls.push({});
    }

    var sessions = this.getSessionsCall(conversation);

    var obj = calls[0];
    const ids = Object.keys(sessions);
    ids.forEach((id) => {
      var segments = sessions[id];
      segments.sort((a, b) => (a.segmentEnd > b.segmentEnd ? 1 : 0));

      if (segments.length > 0) {
        var wrapUpCode = undefined;
        for (var k = 0; k < segments.length; k++) {
          var wrapUpCodeTmp = segments[k].wrapUpCode;
          if (wrapUpCodeTmp !== undefined) {
            wrapUpCode = wrapUpCodeTmp;
            this.processWrapUpCode(wrapUpCode,conversation, obj);
          }
        }
        if (wrapUpCode === undefined) {
          this.processWrapUpCode("No Disp.",conversation, obj);
        }
      } else {
        this.processWrapUpCode("No Disp.",conversation, obj);
      }
    });
  }

  public getSessionsCall(conversation: Conversation) {
    var sessions = {};
    for (var i = 0; i < conversation.participants.length; i++) {
      var participant = conversation.participants[i];
      for (var j = 0; j < participant.sessions.length; j++) {
        var session = participant.sessions[j];
        if (session.mediaType === "callback") {
          //continue;
        }

        var id = undefined;
        if (session.peerId === undefined) {
          id = session.sessionId;
        } else {
          id = session.peerId;
        }
        var segments = sessions[id];
        if (segments === undefined) {
          segments = [];
          sessions[id] = segments;
        }

        for (var k = 0; k < session.segments.length; k++) {
          var segment = session.segments[k];
          if (segment.wrapUpCode !== undefined) {
            segments.push(segment);
          }
        }
      }
    }
    return sessions;
  }

  public processWrapUpCode(wrapUpCode: string, conversation: Conversation, obj: Object): number {
    var withWrapUpCode = 0;
    var wrapUpCodes = obj["wrapUpCodes"];
    if (wrapUpCodes === undefined) {
      wrapUpCodes = {};
      obj["wrapUpCodes"] = wrapUpCodes;
    }
    var wrapUpRows = obj["wrapUpRows"];
    if (wrapUpRows === undefined) {
      wrapUpRows = [];
      obj["wrapUpRows"] = wrapUpRows;
    }
    if (wrapUpCode !== undefined) {
      var count = wrapUpCodes[wrapUpCode];
      if (count === undefined) {
        count = 0;
      }
      count++;
      wrapUpCodes[wrapUpCode] = count;
      withWrapUpCode = 1;

   /*    console.log(obj["wrapUpCodes"]); */
    }

    if (wrapUpRows !== undefined) {
      var countRow = {
        conversationId: conversation.conversationId,
        conversationStart : conversation.conversationStart,
        conversationEnd : conversation.conversationEnd,
        outboundCampaignId : conversation.participants[0].sessions[0].outboundCampaignId,
        outboundContactListId : conversation.participants[0].sessions[0].outboundContactListId,
        outboundContactId : conversation.participants[0].sessions[0].outboundContactId,
        wrapUpCode : wrapUpCode
      };
      wrapUpRows.push(countRow);
    }
    return withWrapUpCode;
  }
}
