import { Component, OnInit, DoCheck } from '@angular/core';
import { AgentLoginCloudService } from 'src/app/services/agent-login/agent-login-cloud.service';
import * as $ from "jquery"
import { Campaign }  from '../../models/campaign-accumulators/campaign';
import { AgentLoginService } from 'src/app/services/agent-login/agent-login.service';
import Swal from 'sweetalert2'
import { Agent } from 'src/app/models/interactions-detail/agent';
import { PurecloudService } from 'src/app/services/purecloud/purecloud.service';
import { AgentLoginExcelService } from 'src/app/services/agent-login/agent-login-excel.service';

declare const duallistboxAgentLoginCampaigns: any;
declare const duallistboxAgentLoginCampaignsDummy: any;

declare const duallistboxAgentLoginAgents: any;
declare const duallistboxAgentLoginAgents2: any;
declare const duallistboxAgentLoginAgentsDummy: any;
declare const duallistboxAgentLoginAgentsDummy2: any;

@Component({
  selector: 'app-agent-login',
  templateUrl: './agent-login.component.html',
  styleUrls: ['./agent-login.component.css']
})
export class AgentLoginComponent implements OnInit {

  pickerFrom;
  myDatePickerFrom;
  pickerTo;
  myDatePickerTo;
  listCampaignSelected: any;
  selectedCampaigns: Campaign[] = [];
  allCampaigns: Array<Campaign>= [];
  campaignDummy: Array<Campaign>= [];
  allAgents: Array<Agent>= [];
  allAgentsDummy: Array<Agent>= [];
  selectedAgents: Agent[] = [];
  listAgentSelected: any;
  duallistbox:any;
  duallistbox2:any;
  duallistbox4:any;

  bloqueDos = false;
  allAgents2: Array<Agent>= [];

  constructor(private purecloudService: PurecloudService, private dataService: AgentLoginService , private cloudService: AgentLoginCloudService,
              private excelService: AgentLoginExcelService) { }

  ngOnInit(): void {
    this.loadUsers();
  }
  loadUsers() {
    this.purecloudService.getUsers()
    .then((response) => {
      response.forEach((user) => this.loadUser(user));
    })
    .catch((response) => alert(response));
  }
  loadUser(user) {
    this.allAgents.push({
      name: user.name,
      id: user.id
    });
  }

  ngDoCheck(): void {
    var duallistbox2 = $('.duallistbox-agent-login-agents');
    if( (this.duallistbox2 == undefined && duallistbox2[0].length > 0) ) {

      this.duallistbox2 = duallistbox2;
      console.log(duallistbox2);
      duallistboxAgentLoginAgentsDummy.bootstrapDualListbox('destroy');
      //$('.duallistbox-agent-login-agents-dummy').hide()


      duallistboxAgentLoginAgents.bootstrapDualListbox({
        moveOnSelect: false,
        infoTextEmpty: 'Lista vacia',
        infoText: false,
        filterPlaceHolder: 'Filtrar por agente',
        moveSelectedLabel: 'Mover soleccionado',
        moveAllLabel: 'Mover todos',
        removeSelectedLabel: 'Borrar seleccionado',
        removeAllLabel: 'Borrar todos',
        infoTextFiltered: 'Filtrado {0} de {1}',
        filterTextClear: 'Mostrar todo'
      });
    }

    var listAgentSelected = $("#bootstrap-duallistbox-selected-list_agentes");
    if(this.listAgentSelected == undefined && listAgentSelected.length) {
      this.listAgentSelected = listAgentSelected;

      const observer = new MutationObserver(
        () => { listAgentSelected.trigger('mutated') }
      )
      observer.observe(listAgentSelected[0], { childList: true })
      listAgentSelected.on('mutated', () => this.onAgentChange())
    }

  }

  onAgentChange(): void {
    this.selectedAgents = [];
    for (var i = 0; i < this.listAgentSelected[0].length; i++) {
      var agent = this.listAgentSelected[0][i];
      this.selectedAgents.push({name: agent.text, id: agent.value});
    }
  }


  onRangeDate(type: string): void {
    var now = new Date();
    switch(type) {
      case 'TODAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'YESTERDAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        break;
        case'LAST_7_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'THIS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom .getDate() + 7, 0, 0, 0, 0);
        break;
        case'PREVIOUS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7 + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        break;
        case'LAST_31_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
      default:
    }
  }

  async onGenerateAgentsLogin(){

    if (this.myDatePickerFrom == undefined || this.myDatePickerTo == undefined ) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>No se definio rango de fechas correctamente</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var from = new Date(this.myDatePickerFrom); //new Date(2021, 4, 23, 3, 0, 0,0); //'2021-04-23T03:00:00.000Z'
    var to =  new Date(this.myDatePickerTo); //new Date(2021, 4, 24, 3, 0, 0,0); //'2021-04-24T03:00:00.000Z'
    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    if ( to < from ) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>No se definio rango de fechas correctamente</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var response: Object[] = [];
    if (response.length === 0) {
      response.push({});
    }

    if( this.selectedAgents.length === 0 ){
      response[0]['agentes'] = this.allAgents; // Todos los agentes
    }
    else{
      response[0]['agentes'] = this.selectedAgents; // Todos los agentes seleccionados
    }

    this.dataService.processUsers(from, to, response)
      .then((response: Object[]) => {
        this.excelService.generateExcel(response);      })
      .catch((error) =>this.catchService(error));
  }

  catchService(error){
    console.log(error);
  }

  private addIfNotAlready(agents: Agent[], candidate: Agent){
    var alreadyIn = false;
    agents.forEach( (agent) => {
      if( agent.id === candidate.id) alreadyIn = true;
    });
    if( !alreadyIn ){
      agents.push(candidate);
    }
  }
}
