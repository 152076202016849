import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { Enargas }  from '../../models/enargas/Enargas';

@Injectable({
  providedIn: 'root'
})
export class EnargasExcelService {

  constructor() { }
  exportExcel(info : Enargas[]) {
    var headers = ['FECHA', 'TELEFONO', 'DEMORA', 'DURACION', 'COLA', 'ID CLOUD'];
    var name = 'reporte_enargas';

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reporte');
    worksheet.addRow(headers);

    info.forEach(d => this.addRow(d, worksheet));

    worksheet.getColumn(1).width = 19;
    worksheet.getColumn(1).alignment = { horizontal: 'right' };
    worksheet.getColumn(2).width = 14;
    worksheet.getColumn(2).alignment = { horizontal: 'right' };
    worksheet.getColumn(3).width = 12;
    worksheet.getColumn(3).alignment = { horizontal: 'right' };
    worksheet.getColumn(4).width = 12;
    worksheet.getColumn(4).alignment = { horizontal: 'right' };
    worksheet.getColumn(5).width = 16;
    worksheet.getColumn(6).width = 40;
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }

  private addRow(info : Enargas, worksheet: Worksheet) {
    worksheet.addRow([info.fecha.replace(/[^0-9]/g, '') + info.hora.replace(/[^0-9]/g, ''), info.numero, info.demora, info.duracion, info.queue, info.conversation]);
  }
}
