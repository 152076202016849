import * as platformClient  from 'purecloud-platform-client-v2';
import { Conversation , Participant , Session , Segment , Property }  from '../models/purecloud/conversation';

export class MapConversation {
    public static analyticsConversation(analyticsConversation : platformClient.Models.AnalyticsConversation) : Conversation {
        var conversation = new Conversation();
        conversation.conversationId = analyticsConversation.conversationId;
        conversation.conversationStart = analyticsConversation.conversationStart;
        conversation.conversationEnd = analyticsConversation.conversationEnd;
        conversation.divisionIds = analyticsConversation.divisionIds;
        conversation.originatingDirection = analyticsConversation.originatingDirection;
        conversation.participants = MapConversation.analyticsParticipants(analyticsConversation.participants);
        return conversation;
    }

    public static analyticsParticipants(analyticsParticipants : platformClient.Models.AnalyticsParticipant[]) : Array<Participant> {
        var participants = new Array<Participant>();
        analyticsParticipants.forEach((analyticsParticipant) => participants.push(MapConversation.analyticsParticipant(analyticsParticipant)));
        return participants;
    }
    public static analyticsParticipant(analyticsParticipant : platformClient.Models.AnalyticsParticipant) : Participant {
        var participant = new Participant();
        participant.participantName = analyticsParticipant.participantName;
        participant.purpose = analyticsParticipant.purpose;
        participant.userId = analyticsParticipant.userId;
        participant.attributes = analyticsParticipant.attributes;
        participant.sessions = MapConversation.analyticsSessions(analyticsParticipant.sessions);
        return participant;
    }

    public static analyticsSessions(analyticsSessions : Array<platformClient.Models.AnalyticsSession>) : Array<Session> {
        var sessions = new Array<Session>();
        analyticsSessions.forEach((analyticsSession) => sessions.push(MapConversation.analyticsSession(analyticsSession)));
        return sessions;
    }
    public static analyticsSession(analyticsSession : platformClient.Models.AnalyticsSession) : Session {
        var session = new Session();
        session.ani = analyticsSession.ani;
        session.direction = analyticsSession.direction;
        session.dnis = analyticsSession.dnis;
        session.edgeId = analyticsSession.edgeId;
        session.sessionId = analyticsSession.sessionId;
        session.peerId = analyticsSession.peerId;
        session.mediaType = analyticsSession.mediaType;
        session.outboundCampaignId = analyticsSession.outboundCampaignId;
        session.outboundContactListId = analyticsSession.outboundContactListId;
        session.outboundContactId = analyticsSession.outboundContactId;
        session.segments = MapConversation.analyticsConversationSegments(analyticsSession.segments);
        return session;
    }

    public static analyticsConversationSegments(analyticsConversationSegments : Array<platformClient.Models.AnalyticsConversationSegment>) : Array<Segment> {
        var segment = new Array<Segment>();
        analyticsConversationSegments.forEach((analyticsConversationSegment) => segment.push(MapConversation.analyticsConversationSegment(analyticsConversationSegment)));
        return segment;
    }
    public static analyticsConversationSegment(analyticsConversationSegment : platformClient.Models.AnalyticsConversationSegment) : Segment {
        var segment = new Segment();
        segment.disconnectType = analyticsConversationSegment.disconnectType;
        segment.errorCode = analyticsConversationSegment.errorCode;
        segment.segmentEnd = analyticsConversationSegment.segmentEnd;
        segment.segmentStart = analyticsConversationSegment.segmentStart;
        segment.segmentType = analyticsConversationSegment.segmentType;
        segment.wrapUpCode = analyticsConversationSegment.wrapUpCode;
        segment.wrapUpNote = analyticsConversationSegment.wrapUpNote;
        segment.properties = MapConversation.analyticsProperties(analyticsConversationSegment.properties);
        return segment;
    }

    public static analyticsProperties(analyticsProperties : Array<platformClient.Models.AnalyticsProperty>) : Array<Property> {
        var properties = new Array<Property>();
        if(analyticsProperties)
            analyticsProperties.forEach((analyticsProperty) => properties.push(MapConversation.analyticsProperty(analyticsProperty)));
        return properties;
    }
    public static analyticsProperty(analyticsProperty : platformClient.Models.AnalyticsProperty) : Property {
        var property = new Property();
        property.property = analyticsProperty.property;
        property.propertyType = analyticsProperty.propertyType;
        property.value = analyticsProperty.value;
        return property;
    }
}
