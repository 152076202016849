import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { COMMA, ENTER, F, TAB } from '@angular/cdk/keycodes';
import { Campaign }  from '../../models/campaign-accumulators/campaign';
import * as $ from "jquery"
import Swal from 'sweetalert2'
import { CampaignAccumulatorsDataService } from 'src/app/services/campaign-accumulators/campaign-accumulators-data.service';
import { CampaignAccumulatorsExcelService } from 'src/app/services/campaign-accumulators/campaign-accumulators-excel.service';
import { CampaignAccumulatorsCloudService } from 'src/app/services/campaign-accumulators/campaign-accumulators-cloud.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ConversationDetailQueryPredicateDimension, PredicateDimension, SegmentDetailQueryPredicateDimension } from 'src/app/constants/purecloud';

declare const duallistboxCampaignAccumulators: any;
declare const duallistboxCampaignAccumulatorsDummy: any;

@Component({
  selector: 'app-campaign-accumulators',
  templateUrl: './campaign-accumulators.component.html',
  styleUrls: ['./campaign-accumulators.component.css']
})
export class CampaignAccumulatorsComponent implements OnInit, DoCheck {
  readonly separatorKeysCodes = [ENTER, COMMA, TAB] as const;
  pickerFrom;
  myDatePickerFrom;
  pickerTo;
  myDatePickerTo;
  listCampaignSelected: any;
  selectedCampaigns: Campaign[] = [];
  allCampaign: Array<Campaign>= [];
  allCampaigns: Array<Campaign>= [];
  campaignDummy: Array<Campaign>= [];
  inbound = false;
  outbound = false;
  duallistbox:any;

  constructor(private dataService : CampaignAccumulatorsDataService, private excelService : CampaignAccumulatorsExcelService, private cloudService : CampaignAccumulatorsCloudService) { }

  ngOnInit(): void {
    this.loadUsers();
  }
  loadCampaign(campaign) {
    this.allCampaigns.push({
      name: campaign.name,
      id: campaign.id
    });
  }
  loadUsers() {
    this.cloudService.getCampaigns()
    .then((response) => {
      response.forEach((user) => this.loadCampaign(user));
    })
    .catch((response) => alert(response));
  }

  ngDoCheck(): void {
    var duallistbox = $('.duallistbox-campaign-accumulators');
    if(this.duallistbox == undefined && duallistbox[0].length > 0) {
      this.duallistbox = duallistbox;
      console.log(duallistbox);
      duallistboxCampaignAccumulatorsDummy.bootstrapDualListbox('destroy');
      $('.duallistbox-campaign-accumulators-dummy').hide()

      duallistboxCampaignAccumulators.bootstrapDualListbox({
        moveOnSelect: false,
        infoTextEmpty: 'Lista vacia',
        infoText: false,
        filterPlaceHolder: 'Filtrar por campaña',
        moveSelectedLabel: 'Mover soleccionado',
        moveAllLabel: 'Mover todos',
        removeSelectedLabel: 'Borrar seleccionado',
        removeAllLabel: 'Borrar todos',
        infoTextFiltered: 'Filtrado {0} de {1}',
        filterTextClear: 'Mostrar todo'
      });
    }

    var listAgentSelected = $("#bootstrap-duallistbox-selected-list_campaigns");
    if(this.listCampaignSelected == undefined && listAgentSelected.length) {
      this.listCampaignSelected = listAgentSelected;

      const observer = new MutationObserver(
        () => { listAgentSelected.trigger('mutated') }
      )
      observer.observe(listAgentSelected[0], { childList: true })
      listAgentSelected.on('mutated', () => this.onCampaignChange())
    }

  }
  onCampaignChange(): void {
    this.selectedCampaigns = [];
    console.log("Campaing change"); //! Borrar esto
    console.log('Bound value: ' + this.getBoundValue()); //! Borrar esto
    for (var i = 0; i < this.listCampaignSelected[0].length; i++) {
      var agent = this.listCampaignSelected[0][i];
      this.selectedCampaigns.push({name: agent.text, id: agent.value});
    }
  }

  onRangeDate(type: string): void {
    var now = new Date();
    switch(type) {
      case 'TODAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'YESTERDAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        break;
        case'LAST_7_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'THIS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom .getDate() + 7, 0, 0, 0, 0);
        break;
        case'PREVIOUS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7 + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        break;
        case'LAST_31_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
      default:
    }
  }

  async onGenerateCampaignAccumulators() {
    console.info('pickerFrom: ' + this.pickerFrom + ', myDatePickerFrom: ' + this.myDatePickerFrom + ', pickerTo: ' + this.pickerTo + ', myDatePickerTo: ' + this.myDatePickerTo);
    if(this.myDatePickerFrom == undefined || this.myDatePickerTo == undefined) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>No se definio rango de fechas correctamente</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }
    var listAgentNoSelected = $("#bootstrap-duallistbox-selected-list_campaigns");
    if(listAgentNoSelected[0].length > 0 && this.selectedCampaigns.length > 100) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>Se supero la cantidad de campañas permitidas: 100</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var from = new Date(this.myDatePickerFrom); //new Date(2021, 4, 23, 3, 0, 0,0); //'2021-04-23T03:00:00.000Z'
    var to =  new Date(this.myDatePickerTo); //new Date(2021, 4, 24, 3, 0, 0,0); //'2021-04-24T03:00:00.000Z'


    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    var selectedIdCampaigns = [];
    for (var i = 0; i < this.selectedCampaigns.length; i++) {
      console.log("Agregando campaña");
      selectedIdCampaigns.push(this.selectedCampaigns[i].id);
    }

    console.log("Armando predicate");
    var outboundAux: boolean = false;
    outboundAux = this.outbound;
    var boundValueAux: string[] = [];
    boundValueAux = this.getBoundValue();
    var predicate = {};

    if( (this.inbound && !this.outbound) || (!this.inbound && this.outbound) ){
      this.buildPredicate(predicate, selectedIdCampaigns, boundValueAux, outboundAux);
      if( this.inbound ){
        this.dataService.generate(from, to, predicate, true)
        .then( (response) => this.generateUsers(from, to, response) )
        .catch( (error) => this.catchService(error) );
      }
      else{ //es outbound
        this.dataService.generate(from, to, predicate, true)
        .then( (response) => this.excelService.generateExcel(response, from, to) )
        .catch( (error) => this.catchService(error) );
      }
    }
    else{// no se selecciono ninguna o ambas
      var objArrayInbound: Object[];
      var objArrayOutbound: Object[];
      this.buildPredicate(predicate, selectedIdCampaigns, ['inbound'], false);
      this.dataService.generate(from, to, predicate, true)
      .then((response) => {
        this.dataService.processUsers(from, to, response)
        .then((responseInb) => {
          objArrayInbound = responseInb;
          predicate = {};
          this.buildPredicate(predicate, selectedIdCampaigns, ['outbound'], true);
          this.dataService.generate(from, to, predicate, true)
          .then((responseOutb) => {
            objArrayOutbound = responseOutb;

            console.log(objArrayInbound);
            console.log(objArrayOutbound);

            if(objArrayOutbound !== undefined){
              this.dataService.mergeInbOutb(objArrayInbound, objArrayOutbound);// une todo en el inbound
            }
            this.excelService.generateExcel(objArrayInbound, from, to);
          })
          .catch((error) => this.catchService(error));
        })
        .catch( (error) => this.catchService(error));
      })
      .catch( (error) => this.catchService(error));






    }
  }



  private buildPredicate(predicate, selectedIdCampaigns, boundValue: string[], outbound: boolean){
    predicate[PredicateDimension.conversation] = {}
    if(boundValue !== []){
      predicate[PredicateDimension.conversation][ConversationDetailQueryPredicateDimension.originatingDirection] = boundValue;
    }
    else{
      predicate[PredicateDimension.conversation][ConversationDetailQueryPredicateDimension.conversationEnd] = [];
    }
    if(outbound){
      predicate[PredicateDimension.segment] = {};
      predicate[PredicateDimension.segment][SegmentDetailQueryPredicateDimension.outboundCampaignId] = selectedIdCampaigns;
    }
  }

  generateUsers(from: Date, to: Date, response: Object[]){
    this.dataService.processUsers(from, to, response)
    .then( (responseOuts: Object[]) => this.excelService.generateExcel(responseOuts, from, to))
    .catch( (error) => this.catchService(error) );
  }

  catchService(error){
    console.log(error);
  }

  getBoundValue(): string[]{
    var res: string[];
    if( (this.inbound && this.outbound) || (!this.inbound && !this.outbound) )
      res = [];
    else if(this.inbound)
      res = ['inbound'];
    else
      res = ['outbound'];

    return res;
  }

}
