import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from "@angular/forms"

import { TranslateLoader, TranslateModule } from "@ngx-translate/core"
import { HttpClient, HttpClientModule } from "@angular/common/http"
import { TranslateHttpLoader } from "@ngx-translate/http-loader"

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { NgxMatMomentModule, NgxMatMomentAdapter, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { NGX_MAT_DATE_FORMATS, NgxMatDateAdapter, NgxMatDateFormats } from '@angular-material-components/datetime-picker';

import { AppRoutingModule,routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { DownloaderComponent } from './components/downloader/downloader.component';
import { LanguageService } from './services/language/language.service';
import { ScripthackComponent } from './components/scripthack/scripthack.component';
import { EnargasComponent } from './components/enargas/enargas.component';
import { InteractionsDetailComponent } from './components/interactions-detail/interactions-detail.component';
import { FieldAccumulatorsComponent } from './components/field-accumulators/field-accumulators.component';
import { PollsComponent } from './components/polls/polls.component';
import { CampaignAccumulatorsComponent } from './components/campaign-accumulators/campaign-accumulators.component';
import { CampaignWrapupAccumulatorsComponent } from './components/campaign-wrapup-accumulators/campaign-wrapup-accumulators.component';
import { AbandonedInteractionsDetailComponent } from './components/abandoned-interactions-detail/abandoned-interactions-detail.component';
import { NumberOfRecordsByTypeComponent } from './components/number-of-records-by-type/number-of-records-by-type.component';
import { AgentsLogsComponent } from './components/agents-logs/agents-logs.component';
import { EventCounterComponent } from './components/event-counter/event-counter.component';
import { AgentLoginComponent } from './components/agent-login/agent-login.component';

export function HttpLoaderFactory (client : HttpClient) {
  return new TranslateHttpLoader(client);
}

const CUSTOM_DATE_FORMATS : NgxMatDateFormats = {
  parse: {
    dateInput: "DD-MM-YYYY HH:mm:ss"
  },
  display: {
    dateInput: "DD-MM-YYYY HH:mm:ss",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    DownloaderComponent,
    ScripthackComponent,
    EnargasComponent,
    InteractionsDetailComponent,
    FieldAccumulatorsComponent,
    PollsComponent,
    CampaignAccumulatorsComponent,
    CampaignWrapupAccumulatorsComponent,
    routingComponents,
    NumberOfRecordsByTypeComponent,
    AbandonedInteractionsDetailComponent,
    AgentsLogsComponent,
    EventCounterComponent,
    AgentLoginComponent
    ],
  imports: [
    CommonModule,
    FormsModule,

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatChipsModule,
    MatInputModule,
    MatButtonModule,
    MatNativeDateModule,
    MatTabsModule,
    MatCheckboxModule,

    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,

    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (languagesService) => languagesService.getLanguage()
    },

    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,

    MatDatepickerModule,
    MatNativeDateModule,
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
