import { DateTime } from './../../utils/date-time';
import { Injectable } from '@angular/core';
import { PurecloudService } from '../purecloud/purecloud.service';
import * as platformClient from 'purecloud-platform-client-v2';

class getOutboundCampaignsOptions implements platformClient.OutboundApi.getOutboundCampaignsOptions {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}
class UserDetailsQuery implements platformClient.Models.UserDetailsQuery {
  constructor(public interval: string, public order: string, public paging: PagSpec ) {
  }
}
class PagSpec implements platformClient.Models.PagingSpec {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class CampaignAccumulatorsCloudService {

  constructor(private purecloudService : PurecloudService) { }

  public getCampaigns(): Promise<any|Array<any>> {
    return this.purecloudService.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
      this.getCampaignsInternal(new getOutboundCampaignsOptions(100, 1), new Array<any>(), resolve, reject);
    });
  }
  private getCampaignsInternal(opts: getOutboundCampaignsOptions, users: Array<any>, resolve, reject) {
    this.purecloudService.getOutbound().getOutboundCampaigns()
      .then((response) => this.thenCampaigns(response, users, resolve, reject))
      .catch((response) => this.catchCampaigns(response, reject));
  }
  private thenCampaigns(response: platformClient.Models.CampaignEntityListing, users: Array<any>, resolve, reject) {
    response.entities.forEach((user) => users.push({
      id: user.id,
      name: user.name
    }));
    if(response.pageNumber < response.pageCount) {
      this.getCampaignsInternal(new getOutboundCampaignsOptions(100, (response.pageNumber + 1)), users, resolve, reject);
    } else {
      resolve(users);
    }
  }
  private catchCampaigns(response: any, reject) {
    reject(response);
  }
  public getUsersDetails( from:Date, to: Date ): Promise<any|Array<platformClient.Models.AnalyticsUserDetail>> {
    var interval = DateTime.convertDateToString(from) + '/' + DateTime.convertDateToString(to);
    return this.purecloudService.checkLogin() || new Promise<Array<platformClient.Models.AnalyticsUserDetail>>((resolve, reject) => {
      this.getUsersDetailsInternal(new UserDetailsQuery(interval, 'asc', new PagSpec(100,1)), new Array<platformClient.Models.AnalyticsUserDetail>(), resolve, reject);
    });
  }
  private getUsersDetailsInternal(opts: UserDetailsQuery, users: Array<platformClient.Models.AnalyticsUserDetail>, resolve, reject) {
    console.log('UserDetailQuery: ' + JSON.stringify(opts));
    this.purecloudService.getUsersApi().postAnalyticsUsersDetailsQuery( opts )
      .then((response) => this.thenUsersDetails(response, opts , users, resolve, reject))
      .catch((response) => this.catchUsersDetails(response, reject));
  }
  private thenUsersDetails(response: platformClient.Models.AnalyticsUserDetailsQueryResponse,opts: UserDetailsQuery, usersDetails: Array<platformClient.Models.AnalyticsUserDetail>, resolve, reject) {
    if(response.userDetails !== undefined) {
      response.userDetails.forEach( (userDetail) => {
        usersDetails.push(userDetail);
      });
      opts.paging.pageNumber++;
      this.getUsersDetailsInternal(opts ,usersDetails , resolve, reject);
    } else {
      resolve(usersDetails);
    }
  }
  private catchUsersDetails(response: any, reject) {
    reject(response);
  }
}
