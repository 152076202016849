import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { COMMA, ENTER, F, TAB } from '@angular/cdk/keycodes';
import { PollsDataService } from 'src/app/services/polls/polls-data.service';
import { PollsExcelService } from 'src/app/services/polls/polls-excel.service';
import { PollsCloudService } from 'src/app/services/polls/polls-cloud.service';
import { Agent } from 'src/app/models/polls/agent';
import { Poll } from 'src/app/models/polls/poll';
import { PurecloudService } from 'src/app/services/purecloud/purecloud.service';
import * as $ from "jquery"
import Swal from 'sweetalert2'
import { DateTime } from 'src/app/utils/date-time';
import { Interaction } from 'src/app/models/polls/interaction';
import { Survey } from 'src/app/models/polls/survey';
import { ConversationDetailQueryPredicateDimension, PredicateDimension, SegmentDetailQueryPredicateDimension } from 'src/app/constants/purecloud';
import { PollReport } from 'src/app/models/polls/pollReport';
import { NGX_MAT_DATE_FORMATS , NgxMatDateFormats } from '@angular-material-components/datetime-picker';

const CUSTOM_DATE_FORMATS : NgxMatDateFormats = {
  parse: {
    dateInput: "DD-MM-YYYY"
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};


declare const duallistboxPoll: any;
declare const duallistboxPollDummy: any;

declare const duallistboxAgents: any;
declare const duallistboxAgentsDummy: any;

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.css'],
  providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }]
})
export class PollsComponent implements OnInit, DoCheck {
  readonly separatorKeysCodes = [ENTER, COMMA, TAB] as const;
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  });

  pickerFrom;
  myDatePickerFrom;
  pickerTo;
  myDatePickerTo;
  listPollsSelected: any;
  selectedPolls: Poll[] = [];
  allPolls: Array<Poll> = [];
  pollDummy: Array<Poll> = [];
  poll: Poll;
  duallistbox: any;
  duallistbox2: any;
  maxDate: Date;
  allAgents: Array<Agent> = [];
  agentsDummy: Array<Agent> = [];
  selectedAgents: Agent[] = [];
  listAgentSelected: any;
  //allDynamixInteractions: Array<Interaction> = [];
  //dynamixInteraction: Interaction;
  //dynamixResponse: Survey;


  constructor(private dataService: PollsDataService, private excelService: PollsExcelService, private cloudService: PollsCloudService, private purecloudService: PurecloudService) {
  }

  ngOnInit(): void {
    this.loadUsers();
    this.loadPolls();
    console.log(this.allPolls);
//    console.log(this.allDynamixInteractions);
    console.log(this.allAgents);
  }

  loadPolls(){
  this.dataService.getDynamixPolls().subscribe(
    response => {
      response.forEach(
        DynamixPoll => {
          this.poll = new Poll();
          this.poll.id = DynamixPoll.id;
          this.poll.name = DynamixPoll.name;
          this.allPolls.push(this.poll);
        }
      );
    }
  );
  }

  loadUser(user) {
    this.allAgents.push({
      name: user.name,
      id: user.id,
      email: user.email
    });
  }
  loadUsers() {
    this.purecloudService.getUsers()
      .then((response) => {
        response.forEach((user) => this.loadUser(user));
      })
      .catch((response) => alert(response));
  }

  ngDoCheck(): void {
    var duallistbox = $('.duallistbox-poll');
    if (this.duallistbox == undefined && duallistbox[0].length > 0) {
      this.duallistbox = duallistbox;
      duallistboxPollDummy.bootstrapDualListbox('destroy');
      $('.duallistbox-poll-dummy').hide()

      duallistboxPoll.bootstrapDualListbox({
        moveOnSelect: false,
        infoTextEmpty: 'Lista vacia',
        infoText: false,
        filterPlaceHolder: 'Filtrar por encuesta',
        moveSelectedLabel: 'Mover soleccionado',
        moveAllLabel: 'Mover todos',
        removeSelectedLabel: 'Borrar seleccionado',
        removeAllLabel: 'Borrar todos',
        infoTextFiltered: 'Filtrado {0} de {1}',
        filterTextClear: 'Mostrar todo'
      });
    }

    var listPollsSelectedtmp = $("#bootstrap-duallistbox-selected-list_polls");
    if (this.listPollsSelected == undefined && listPollsSelectedtmp.length > 0) {
      this.listPollsSelected = listPollsSelectedtmp;

      const observer = new MutationObserver(
        () => { listPollsSelectedtmp.trigger('mutated') }
      );
      observer.observe(listPollsSelectedtmp[0], { childList: true });
      listPollsSelectedtmp.on('mutated', () => this.onPollsChange());
    }

    var duallistbox2 = $('.duallistbox-agents');
    if (this.duallistbox2 == undefined && duallistbox2[0].length > 0) {
      this.duallistbox2 = duallistbox2;
      duallistboxAgentsDummy.bootstrapDualListbox('destroy');
      $('.duallistbox-agents-dummy').hide()

      duallistboxAgents.bootstrapDualListbox({
        moveOnSelect: false,
        infoTextEmpty: 'Lista vacia',
        infoText: false,
        filterPlaceHolder: 'Filtrar por agente',
        moveSelectedLabel: 'Mover soleccionado',
        moveAllLabel: 'Mover todos',
        removeSelectedLabel: 'Borrar seleccionado',
        removeAllLabel: 'Borrar todos',
        infoTextFiltered: 'Filtrado {0} de {1}',
        filterTextClear: 'Mostrar todo'
      });
    }

    var listAgentsSelectedtmp = $("#bootstrap-duallistbox-selected-list_agents");
    if (this.listAgentSelected == undefined && listAgentsSelectedtmp.length) {
      this.listAgentSelected = listAgentsSelectedtmp;

      const observer = new MutationObserver(
        () => { listAgentsSelectedtmp.trigger('mutated') }
      )
      observer.observe(listAgentsSelectedtmp[0], { childList: true })
      listAgentsSelectedtmp.on('mutated', () => this.onAgentChange())
    }
  }

  onAgentChange(): void {
    this.selectedAgents = [];
    for (var i = 0; i < this.listAgentSelected[0].length; i++) {
      var agent = this.listAgentSelected[0][i];
      console.log("agente: " +agent.value);
      var values = agent.value.split('#');
      this.selectedAgents.push({ name: agent.text, id: values[0], email: values[1] });
    }     
     console.log("seleccion de agentes: " +this.selectedAgents);
  }

  onPollsChange(): void {
    this.selectedPolls = [];
    for (var i = 0; i < this.listPollsSelected[0].length; i++) {
      var poll = this.listPollsSelected[0][i];
      console.log(poll);
      this.selectedPolls.push({ name: poll.text, id: poll.value });
    }
    console.log(this.selectedPolls);

  }

  onRangeDate(type: string): void {
    var now = new Date();
    switch(type) {
      case 'TODAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'YESTERDAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        break;
        case'LAST_7_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'THIS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom .getDate() + 7, 0, 0, 0, 0);
        break;
        case'PREVIOUS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7 + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        break;
        case'LAST_31_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
      default:
    }
  }

  async onGeneratePoll() {
    console.info('pickerFrom: ' + this.pickerFrom + ', myDatePickerFrom: ' + this.myDatePickerFrom + ', pickerTo: ' + this.pickerTo + ', myDatePickerTo: ' + this.myDatePickerTo);
    if (this.myDatePickerFrom == undefined || this.myDatePickerTo == undefined) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>No se definio rango de fechas correctamente</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var listPollNoSelected = $("#bootstrap-duallistbox-nonselected-list_polls");

    var listAgentNoSelected = $("#bootstrap-duallistbox-nonselected-list_agents");
    if(listAgentNoSelected[0].length > 0 && this.selectedAgents.length > 100) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>Se supero la cantidad de agentes permitidos: 100</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    /*if (listAgentNoSelected[0].length > 0 && this.selectedAgents.length > 100)  {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>Se supero la cantidad de agentes permitidos: 100</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }*/

    var from = new Date(this.myDatePickerFrom); //new Date(2021, 4, 23, 3, 0, 0,0); //'2021-04-23T03:00:00.000Z'
    var to = new Date(this.myDatePickerTo); //new Date(2021, 4, 24, 3, 0, 0,0); //'2021-04-24T03:00:00.000Z'

    var dynamixDateFrom = DateTime.convertDateToDayString(from, '-') + ' ' + DateTime.convertDateToHourString(from, ':');
    var dynamixDateTo = DateTime.convertDateToDayString(to, '-') + ' ' + DateTime.convertDateToHourString(to, ':');

    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    console.log(this.selectedAgents);
    console.log(this.selectedPolls);
    var selectedIdPoll = [];
    var selectedMapPolls = {};
    for (var i = 0; i < this.selectedPolls.length; i++) {
      selectedIdPoll.push(this.selectedPolls[i].id);
    }
    for (var i = 0; i < this.allPolls.length; i++) {
      selectedMapPolls[this.allPolls[i].id] = this.allPolls[i].name;
    }

    var userIds = [];
    if(listAgentNoSelected[0].length !== 0) {
      this.selectedAgents.forEach(agent => {
        userIds.push(agent.id);
      });
    }

    var predicate = {};
    predicate[PredicateDimension.conversation] = {}
    predicate[PredicateDimension.conversation][ConversationDetailQueryPredicateDimension.conversationEnd] = [];
    predicate[PredicateDimension.segment] = {}
    predicate[PredicateDimension.segment][SegmentDetailQueryPredicateDimension.userId] = userIds;

    this.dataService.generate(from, to, predicate, true)
      .then((response) => {
        var polls = this.selectedPolls;
        if(polls.length === 0){
          polls = this.allPolls;
        }

        var agents = this.selectedAgents;
        if(agents.length === 0){
          agents  = this.allAgents;
        }
        this.getDynamixInteractions(response, dynamixDateFrom, dynamixDateTo, agents, polls);
      })
      .catch((response) => this.catchService(response));
  }
  public async getDynamixInteractions (response: Object[], from : string, to : string, selectedAgents : Agent[], selectedPolls : Poll[]){
    var allDynamixInteractions: Array<Interaction> = [];
    for (var i = 0; i < selectedAgents.length; i++) {
      var agent = selectedAgents[i];
      var index = agent.email.indexOf('@');
      if(index > 0) {
        var agentId = agent.email.slice(0,index);
        agent.email= agentId;// en el mail pongo el user_id de dynamix
      }

      this.Toast.fire({
        title: 'Procesando encuestas de ' + agent.email + " (" + i + " de " + selectedAgents.length + ")",
        timer: 1000
      });

      for (var j = 0; j < selectedPolls.length; j++) {
        var poll = selectedPolls[j];
        await this.dataService.getDynamixInteractions(poll.id, agent.email, from, to)
        .then( (interactions) => this.next(interactions, allDynamixInteractions) )// cada vez que llamo a next se está ejecutando la consulta a excel.
        .catch( (error) => this.catchService(error) );
      }
    }
    this.processExcel(response, this.myDatePickerFrom, this.myDatePickerTo,  selectedAgents, selectedPolls, allDynamixInteractions);
 }

 private next(interactions, allDynamixInteractions: Array<Interaction>){
  if(interactions === undefined || interactions === null){
    return;
  }
  interactions.forEach(interaction => {
    var dynamixInteraction  = new Interaction();
    dynamixInteraction.agentId = interaction.agent_id;
    dynamixInteraction.surveyId = interaction.survey_id;
    dynamixInteraction.response =  new Array<Survey>();
    interaction.responses.forEach(survey => {
      var dynamixResponse = new Survey();
      dynamixResponse.question = survey.question_label;
      dynamixResponse.value = survey.response
      dynamixInteraction.response.push(dynamixResponse);
    });
    if(dynamixInteraction != undefined){   
      allDynamixInteractions.push(dynamixInteraction);
    }
  });
 }
 
 private processExcel(totalLlamadas: Object[], from: Date, to: Date, agentsSelected: Agent[], pollsSelected: Poll[], dynamixInteractions: Interaction []): void {
  var report = {};
  var translateAgent = {};
  var translatePoll = {};

  pollsSelected.forEach(poll => {
   translatePoll[poll.id] = poll.name;
  });

  agentsSelected.forEach(agent => {//el id es el de genesys
    translateAgent[agent.email] = agent.id;
    report[agent.id] = {};
    report[agent.id].agent = agent.name;
    report[agent.id].total = totalLlamadas[0][agent.id];
  });

  dynamixInteractions.forEach(dynamixInteraction => {//el id es el dynamix
    var agentId = translateAgent[dynamixInteraction.agentId];
    if(report[agentId] !== undefined) {
      var pollName = translatePoll[dynamixInteraction.surveyId];
      if(report[agentId].polls === undefined){
        report[agentId].polls = {};
        report[agentId].polls[dynamixInteraction.surveyId] = {};
        report[agentId].polls[dynamixInteraction.surveyId].name = pollName;
        report[agentId].polls[dynamixInteraction.surveyId].transferidas = 0;
      }
      dynamixInteraction.response.forEach(survey =>{
        if(survey.question ==='Resolución'){
          report[agentId].polls[dynamixInteraction.surveyId].transferidas++;
        }
        if(survey.value != '-1'){
          if(report[agentId].polls[dynamixInteraction.surveyId][survey.question]  === undefined){
            report[agentId].polls[dynamixInteraction.surveyId][survey.question] = {};
            if(survey.question ==='Resolución') {
              report[agentId].polls[dynamixInteraction.surveyId][survey.question].count1 = 0;
              report[agentId].polls[dynamixInteraction.surveyId][survey.question].count2 = 0;
              report[agentId].polls[dynamixInteraction.surveyId][survey.question].count3 = 0;
              switch (survey.value) {
                case '1':
                  report[agentId].polls[dynamixInteraction.surveyId][survey.question].count1 = 1;
                  break;
                case '2':
                  report[agentId].polls[dynamixInteraction.surveyId][survey.question].count2 = 1;
                  break;
                case '3':
                  report[agentId].polls[dynamixInteraction.surveyId][survey.question].count3 = 1;
                  break;
              }
            }
            report[agentId].polls[dynamixInteraction.surveyId][survey.question].value = parseInt(survey.value);
            report[agentId].polls[dynamixInteraction.surveyId][survey.question].count = 1;
            report[agentId].polls[dynamixInteraction.surveyId][survey.question].average = parseFloat(survey.value).toFixed(2);
          }
          else{
            if(survey.question ==='Resolución'){
              switch (survey.value) {
                case '1':
                  report[agentId].polls[dynamixInteraction.surveyId][survey.question].count1++;
                  break;
                case '2':
                  report[agentId].polls[dynamixInteraction.surveyId][survey.question].count2++;
                  break;
                case '3':
                  report[agentId].polls[dynamixInteraction.surveyId][survey.question].count3++;
                  break;
              }
            }
            report[agentId].polls[dynamixInteraction.surveyId][survey.question].value += parseInt(survey.value);
            report[agentId].polls[dynamixInteraction.surveyId][survey.question].count++;
            report[agentId].polls[dynamixInteraction.surveyId][survey.question].average = (parseFloat(report[agentId].polls[dynamixInteraction.surveyId][survey.question].value)/parseFloat(report[agentId].polls[dynamixInteraction.surveyId][survey.question].count)).toFixed(2);
          }
        }      
      });
    } else {
      console.log("No se encontro el reporte para el Agente: " + dynamixInteraction.agentId + ", " + agentId);
    }
  });

  var pollReport = [];
  agentsSelected.forEach(agent => {
    if(report[agent.id] != undefined){
      if(report[agent.id].polls != undefined){
        pollsSelected.forEach(poll =>{
          if(report[agent.id].polls[poll.id] != undefined){
            if(report[agent.id].polls[poll.id]['Resolución'] || report[agent.id].polls[poll.id]['Calidad Atención'] || report[agent.id].polls[poll.id]['Satisfacción General']){
              var register = new PollReport();
              register.total = report[agent.id].total;
              register.agent = report[agent.id].agent;
              register.poll = report[agent.id].polls[poll.id].name;
              if(report[agent.id].polls[poll.id]['Resolución']){
                if(report[agent.id].polls[poll.id]['Resolución'].count != undefined){
                  register.transferred = report[agent.id].polls[poll.id].transferidas;
                  register.resolution = report[agent.id].polls[poll.id]['Resolución'].count;
                  register.resolutionAverage = report[agent.id].polls[poll.id]['Resolución'].average;
                  register.resolution1 = report[agent.id].polls[poll.id]['Resolución'].count1;
                  register.resolution2 = report[agent.id].polls[poll.id]['Resolución'].count2;
                  register.resolution3 = report[agent.id].polls[poll.id]['Resolución'].count3;
                }else{
                  register.transferred = 0;
                  register.resolution = 0;
                  register.resolutionAverage = 0;
                }  
              }
              if(report[agent.id].polls[poll.id]['Calidad Atención']){
                if(report[agent.id].polls[poll.id]['Calidad Atención'].value != undefined){
                  register.quality = report[agent.id].polls[poll.id]['Calidad Atención'].count;
                  register.qualityAverage = report[agent.id].polls[poll.id]['Calidad Atención'].average;
                }else{
                  register.transferred = 0;
                  register.resolution = 0;
                  register.resolutionAverage = 0;
                }  
              }
              if(report[agent.id].polls[poll.id]['Satisfacción General']){
                if(report[agent.id].polls[poll.id]['Satisfacción General'].value != undefined){
                  register.general = report[agent.id].polls[poll.id]['Satisfacción General'].count;
                  register.generalAverage = report[agent.id].polls[poll.id]['Satisfacción General'].average;
                }else{
                  register.transferred = 0;
                  register.resolution = 0;
                  register.resolutionAverage = 0;
                }
              }
            }
          }
//          console.log(register);
          if(register != undefined){
            pollReport.push(register);
          }
        });
      }
    }
  });
  console.log(pollReport);
  this.excelService.exportExcel(pollReport, new Date(this.myDatePickerFrom), new Date(this.myDatePickerTo));
 }  

  catchService(response){
    Swal.fire({
      title: 'Cancelado',
      html: 'Se cancelo la tarea por... <b></b>',
      didOpen: () => {
        Swal.showLoading();
        const content = Swal.getContent();
        if (content) {
          const b = content.querySelector('b');
          if (b) {
            console.log(response);
            var result = undefined;
            if (response.body !== undefined && response.body.message !== undefined) {
              result = response.body.message
            }
            if (response.error !== undefined && response.error.message !== undefined) {
              result = response.error.message + " (" + response.error.errno + ")";
            }
            if (result === undefined) {
              result = response;
            }
            b.textContent = JSON.stringify(result);
          }
        }
      },
      willClose: () => {
        window.location.href = window.location.href
      }
    }).then((result) => {
      console.log(result);
    })
  }
}
