import { Injectable } from '@angular/core';
import * as platformClient from 'purecloud-platform-client-v2';
import { ConversationDetailQueryPredicateDimension, PredicateDimension, SegmentDetailQueryPredicateDimension } from 'src/app/constants/purecloud';

class getAuthorizationDivisionsOptions implements platformClient.AuthorizationApi.getAuthorizationDivisionsOptions {
  constructor(public pageSize: number) {
  }
}
class getRoutingQueuesOptions implements platformClient.RoutingApi.getRoutingQueuesOptions {
  constructor(public name: string) {
  }
}
class getRoutingWrapupcodesOptions implements platformClient.RoutingApi.getRoutingWrapupcodesOptions {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}
class AsyncConversationQuery implements platformClient.Models.AsyncConversationQuery {
  constructor(public interval: string, public startOfDayIntervalMatching: boolean, public conversationFilters: Array<ConversationDetailQueryFilter>, public segmentFilters: Array<SegmentDetailQueryFilter>) {
  }
}
class AsyncConversationQueryWithoutSegment implements platformClient.Models.AsyncConversationQuery {
  constructor(public interval: string, public startOfDayIntervalMatching: boolean, public conversationFilters: Array<ConversationDetailQueryFilter>) {
  }
}
class ConversationDetailQueryFilter implements platformClient.Models.ConversationDetailQueryFilter {
  constructor(public type: string, public predicates: Array<ConversationDetailQueryPredicate>) {
  }
}
class ConversationDetailQueryPredicate implements platformClient.Models.ConversationDetailQueryPredicate {
  constructor(public type: string, public dimension: string, public operator: string, public value: string) {
  }
}
class SegmentDetailQueryFilter implements platformClient.Models.SegmentDetailQueryFilter {
  constructor(public type: string, public predicates: Array<SegmentDetailQueryPredicate>) {
  }
}
class SegmentDetailQueryPredicate implements platformClient.Models.SegmentDetailQueryPredicate {
  constructor(public type: string, public dimension: string, public operator: string, public value: string) {
  }
}
class DetailsJobResultsOptions implements platformClient.ConversationsApi.getAnalyticsConversationsDetailsJobResultsOptions {
  constructor(public pageSize: number, public cursor?: string) {
  }
}
class ConversationQuery implements platformClient.Models.ConversationQuery {
  constructor(public interval: string, public conversationFilters: Array<ConversationDetailQueryFilter>, public segmentFilters: Array<SegmentDetailQueryFilter>, public paging: PagingSpec) {
  }
}
class ConversationQueryWithoutSegment implements platformClient.Models.ConversationQuery {
  constructor(public interval: string, public conversationFilters: Array<ConversationDetailQueryFilter>, public paging: PagingSpec) {
  }
}
class PagingSpec implements platformClient.Models.PagingSpec {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}
class EvaluationDetailQueryFilter implements platformClient.Models.EvaluationDetailQueryFilter {
  constructor(public type: string, public predicates: Array<EvaluationDetailQueryPredicate>) {
  }
}
class EvaluationDetailQueryPredicate implements platformClient.Models.EvaluationDetailQueryPredicate {
  constructor(public type: string, public dimension: string, public operator: string, public value: string) {
  }
}
class getUsersOptions implements platformClient.UsersApi.getUsersOptions {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class PurecloudService {
  private apiOutbound: platformClient.OutboundApi;
  private apiConversations: platformClient.ConversationsApi;
  private apiRouting: platformClient.RoutingApi;
  private apiUsers: platformClient.UsersApi;
  private apiAuthorization: platformClient.AuthorizationApi;
  private apiTelephonyProvidersEdge: platformClient.TelephonyProvidersEdgeApi;
  private client: any;
  private authData: platformClient.AuthData;

  constructor() {
    this.apiOutbound = new platformClient.OutboundApi();
    this.apiConversations = new platformClient.ConversationsApi();
    this.apiRouting = new platformClient.RoutingApi();
    this.apiUsers = new platformClient.UsersApi();
    this.apiAuthorization = new platformClient.AuthorizationApi();
    this.apiTelephonyProvidersEdge = new platformClient.TelephonyProvidersEdgeApi();
  }

  public getConversations() : platformClient.ConversationsApi {
    return this.apiConversations;
  }

  public getTelephonyProvidersEdge() : platformClient.TelephonyProvidersEdgeApi {
    return this.apiTelephonyProvidersEdge;
  }
  public getOutbound() : platformClient.OutboundApi {
    return this.apiOutbound;
  }

  public getRouting(): platformClient.RoutingApi{
    return this.apiRouting;
  }
  public getUsersApi() : platformClient.UsersApi {
    return this.apiUsers;
  }

  login(): void {
    this.client = platformClient.ApiClient.instance;
    this.client.setEnvironment('https://api.mypurecloud.com');
    this.client.timeout = 100000;
    this.client.loginImplicitGrant('bab8154e-5c3d-4f1f-865a-27da381eb940', window.location.origin + "/download")
      .then((response) => this.thenLogin(response))
      .catch((response) => this.catchLogin(response));
  }
  private thenLogin(response: platformClient.AuthData) {
    this.authData = response;
  }
  private catchLogin(response: any) {
    console.info(response);
  }

  public checkLogin(){
    var now = new Date();
    console.log(now.getTime() + ' ... ' + (this.authData.tokenExpiryTime - 10000));
    if (now.getTime() > this.authData.tokenExpiryTime - 10000) {
      return new Promise((resolve, reject) => {
        reject({ status: '401', code: 'bad.credentials', message: 'Credenciales inválidas.' });
      });
    }
    return undefined;
  }

  private getPredicateConversationEnd(type: string, ids: string[]) : Object {
    var predicate = {};
    predicate[PredicateDimension.conversation] = {}
    predicate[PredicateDimension.conversation][ConversationDetailQueryPredicateDimension.conversationEnd] = [];
    predicate[PredicateDimension.segment] = {}
    predicate[PredicateDimension.segment][type] = ids;
    return predicate;
  }

  public postAnalyticsConversationsDetailsQueryByUserIds(from: Date, to: Date, userIds: string[], index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {
    var predicate = this.getPredicateConversationEnd(SegmentDetailQueryPredicateDimension.userId, userIds);
    return this.postAnalyticsConversationsDetailsQuery(from, to, predicate, index);
  }
  public async postAnalyticsConversationsDetailsQueryByQueueNames(from: Date, to: Date, queueNames: string[], index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {
    let queueIds = await this.getQueueIds(queueNames);
    return this.postAnalyticsConversationsDetailsQueryByQueueIds(from, to, queueIds, index);
  }
  public postAnalyticsConversationsDetailsQueryByQueueIds(from: Date, to: Date, queueIds: string[], index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {
    var predicate = this.getPredicateConversationEnd(SegmentDetailQueryPredicateDimension.queueId, queueIds);
    return this.postAnalyticsConversationsDetailsQuery(from, to, predicate, index);
  }
  public postAnalyticsConversationsDetailsQuery(from: Date, to: Date, predicate, index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {
    var promise = this.checkLogin();
    if (promise !== undefined) {
      return promise;
    }

    var conversationDetailQueryFilter = this.getConversationDetailQueryFilter(predicate[PredicateDimension.conversation]);
    var query: platformClient.Models.ConversationQuery;
    if(predicate[PredicateDimension.segment] !== undefined){
      var segmentDetailQueryFilter = this.getSegmentDetailQueryFilter(predicate[PredicateDimension.segment]);
      query = new ConversationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), [conversationDetailQueryFilter], [segmentDetailQueryFilter], new PagingSpec(100, index));
    }else{
      query = new ConversationQueryWithoutSegment(this.convertDateToString(from) + '/' + this.convertDateToString(to), [conversationDetailQueryFilter], new PagingSpec(100, index));
    }
    console.log("ConversationQuery, interval: ", query.interval, ", pageNumber", query.paging.pageNumber, query);
    return this.checkLogin() || this.apiConversations.postAnalyticsConversationsDetailsQuery(query);
  }

  public async postAnalyticsConversationsDetailsJobsByQueueNames(from: Date, to: Date, queueNames: string[]): Promise<platformClient.Models.AsyncQueryResponse> {
    let queueIds = await this.getQueueIds(queueNames);
    var predicate = this.getPredicateConversationEnd(SegmentDetailQueryPredicateDimension.queueId, queueIds);

    return this.postAnalyticsConversationsDetailsJobs(from, to, predicate);
  }
  public async postAnalyticsConversationsDetailsJobsByUserIds(from: Date, to: Date, userIds: string[]): Promise<platformClient.Models.AsyncQueryResponse> {
    var predicate = this.getPredicateConversationEnd(SegmentDetailQueryPredicateDimension.userId, userIds);

    return this.postAnalyticsConversationsDetailsJobs(from, to, predicate);
  }

  public postAnalyticsConversationsDetailsJobs(from: Date, to: Date, predicate): Promise<platformClient.Models.AsyncQueryResponse> {
    var promise = this.checkLogin();
    if (promise !== undefined) {
      return promise;
    }

    var conversationDetailQueryFilter = this.getConversationDetailQueryFilter(predicate[PredicateDimension.conversation]);
    var query: platformClient.Models.ConversationQuery;
    if(predicate[PredicateDimension.segment] !== undefined){
      var segmentDetailQueryFilter = this.getSegmentDetailQueryFilter(predicate[PredicateDimension.segment]);
      query = new AsyncConversationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), true, [conversationDetailQueryFilter], [segmentDetailQueryFilter]);
    }
    else{
      query = new AsyncConversationQueryWithoutSegment(this.convertDateToString(from) + '/' + this.convertDateToString(to), true, [conversationDetailQueryFilter]);
    }
    console.log("AsyncConversationQuery: ", query);
    return this.checkLogin() || this.apiConversations.postAnalyticsConversationsDetailsJobs(query);
  }

  public getDivisions(): Promise<any> {
    return this.apiAuthorization.getAuthorizationDivisions(new getAuthorizationDivisionsOptions(100))
      .then((result) => {
        var divisions = {};
        result.entities.forEach((division) => divisions[division.id] = division.name);
        return divisions;
      }).catch((error) => {
        return undefined;
      });
  }

  getAnalyticsConversationsDetailsJob(jobId: string): Promise<platformClient.Models.AsyncQueryStatus> {
    return this.checkLogin() || this.apiConversations.getAnalyticsConversationsDetailsJob(jobId);
  }

  getAnalyticsConversationsDetailsJobResults(jobId: string, cursor?: string): Promise<platformClient.Models.AnalyticsConversationAsyncQueryResponse> {
    return this.checkLogin() || this.apiConversations.getAnalyticsConversationsDetailsJobResults(jobId, new DetailsJobResultsOptions(1000, cursor));
  }

  getAnalyticsConversationsDetailsJobsAvailability(): Promise<platformClient.Models.DataAvailabilityResponse>{
    return this.checkLogin() || this.apiConversations.getAnalyticsConversationsDetailsJobsAvailability();
  }

  getWraUps(): Promise<any> {
    return this.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
      this.getWraUpsInternal(new getRoutingWrapupcodesOptions(100, 1), {}, resolve, reject);
    });
  }
  private getWraUpsInternal(opts: getRoutingWrapupcodesOptions, wraups, resolve, reject) {
    this.apiRouting.getRoutingWrapupcodes(opts)
      .then((response) => this.thenWraUps(response, wraups, resolve, reject))
      .catch((response) => this.catchWraUps(response, reject));
  }
  private thenWraUps(response: platformClient.Models.WrapupCodeEntityListing, wraups, resolve, reject) {
    response.entities.forEach((wraup) => wraups[wraup.id]=wraup.name);
    if(response.pageNumber < response.pageCount) {
      this.getWraUpsInternal(new getRoutingWrapupcodesOptions(100, (response.pageNumber + 1)), wraups, resolve, reject);
    } else {
      resolve(wraups);
    }
  }
  private catchWraUps(response: any, reject) {
    reject(response);
  }

  getUsers(): Promise<any|Array<any>> {
    return this.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
      this.getUsersInternal(new getUsersOptions(100, 1), new Array<any>(), resolve, reject);
    });
  }
  private getUsersInternal(opts: getUsersOptions, users: Array<any>, resolve, reject) {
    this.apiUsers.getUsers(opts)
      .then((response) => this.thenUsers(response, users, resolve, reject))
      .catch((response) => this.catchUsers(response, reject));
  }
  private thenUsers(response: platformClient.Models.UserEntityListing, users: Array<any>, resolve, reject) {
    response.entities.forEach((user) => users.push({
      id: user.id,
      name: user.name,
      email: user.email
    }));
    if(response.pageNumber < response.pageCount) {
      this.getUsersInternal(new getUsersOptions(100, (response.pageNumber + 1)), users, resolve, reject);
    } else {
      resolve(users);
    }
  }
  private catchUsers(response: any, reject) {
    reject(response);
  }

  public convertDateToString(date: Date): string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();
    var msecond = '' + date.getMilliseconds();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (hour.length < 2)
      hour = '0' + hour;
    if (minute.length < 2)
      minute = '0' + minute;
    if (second.length < 2)
      second = '0' + second;
    if (msecond.length < 2)
      msecond = '0' + msecond;
    if (msecond.length < 3)
      msecond = '0' + msecond;

    return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.' + msecond + 'Z';
  }

  private getSegmentDetailQueryFilter(predicate) : SegmentDetailQueryFilter {
    var segmentDetailQueryPredicates: SegmentDetailQueryPredicate[] = [];
    SegmentDetailQueryPredicateDimension.DIMENSIONS.forEach(dimension => {
      var values = predicate[dimension];
      if(values !== undefined) {
        if(values.length > 0) {
          for (var i = 0; i < values.length; i++) {
            segmentDetailQueryPredicates.push(new SegmentDetailQueryPredicate("dimension", dimension, "matches", values[i]));
          }
        } else {
          segmentDetailQueryPredicates.push(new SegmentDetailQueryPredicate("dimension", dimension, "exists", undefined));
        }
      }
    });
    return segmentDetailQueryPredicates.length > 0 ? new SegmentDetailQueryFilter("or", segmentDetailQueryPredicates) : undefined;
  }

  private getConversationDetailQueryFilter(predicate) : ConversationDetailQueryFilter {
    var conversationDetailQueryPredicates: ConversationDetailQueryPredicate[] = [];
    ConversationDetailQueryPredicateDimension.DIMENSIONS.forEach(dimension => {
      var values = predicate[dimension];
      if(values !== undefined) {
        if(values.length > 0) {
          for (var i = 0; i < values.length; i++) {
            conversationDetailQueryPredicates.push(new ConversationDetailQueryPredicate("dimension", dimension, "matches", values[i]));
          }
        } else {
          conversationDetailQueryPredicates.push(new ConversationDetailQueryPredicate("dimension", dimension, "exists", undefined));
        }
      }
    });
    return conversationDetailQueryPredicates.length > 0 ? new ConversationDetailQueryFilter("or", conversationDetailQueryPredicates) : undefined;
  }

  private getQueueId(queue: string): Promise<string> {
    return this.apiRouting.getRoutingQueues(new getRoutingQueuesOptions(queue))
      .then((result) => {
        if (result.entities.length > 0)
          return result.entities[0].id;
        return undefined;
      }).catch((error) => {
        return undefined;
      });
  }

  public async getQueueIds(queueNames: string[]): Promise<Array<string>> {
    var queueIds = new Array<string>();
    for (var i = 0; i < queueNames.length; i++) {
      let name = await this.getQueueId(queueNames[i]);
      if (name === undefined) {
        return new Promise((resolve, reject) => reject({ status: '999', code: 'bad.queue', message: 'No se encontro la QUEUE: ' + queueNames[i] + '.' }));
      }
      queueIds.push(name);
    }
    return new Promise((resolve, reject) => resolve(queueIds));
  }
}
