import { Component, Input, OnInit } from '@angular/core';
import { Agent } from 'src/app/models/interactions-detail/agent';
import { PurecloudService } from 'src/app/services/purecloud/purecloud.service';
import * as $ from "jquery"
import { AgentsLogsService } from 'src/app/services/agents-logs/agents-logs.service';
import Swal from 'sweetalert2'
import { AgentLog } from 'src/app/models/agents-logs/agents-logs';
import { AgentsLogsExcelService } from 'src/app/services/agents-logs/agents-logs-excel.service';
import { ConversationDetailQueryPredicateDimension, PredicateDimension, SegmentDetailQueryPredicateDimension } from 'src/app/constants/purecloud';

declare const duallistboxInteractionsDetail: any;
declare const duallistboxInteractionsDetailDummy: any;

@Component({
  selector: 'app-agents-logs',
  templateUrl: './agents-logs.component.html',
  styleUrls: ['./agents-logs.component.css']
})
export class AgentsLogsComponent implements OnInit {

  myDatePickerFrom;
  myDatePickerTo;
  @Input()
  allAgents: Array<Agent>= [];
  allAgentsDummy: Array<Agent>= [];
  selectedAgents: Agent[] = [];
  listAgentSelected: any;
  duallistbox:any;

  constructor(private purecloudService : PurecloudService, private dataService : AgentsLogsService,
              private excelService : AgentsLogsExcelService) { }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUser(user) {
    this.allAgents.push({
      name: user.name,
      id: user.id
    });
  }

  loadUsers() {
    this.purecloudService.getUsers()
    .then((response) => {
      response.forEach((user) => this.loadUser(user));
    })
    .catch((response) => alert(response));
  }

  ngDoCheck(): void {
    var duallistbox = $('.duallistbox-interactions-detail');
    if(this.duallistbox == undefined && duallistbox[0].length > 0) {
      this.duallistbox = duallistbox;
      console.log(duallistbox);
      duallistboxInteractionsDetailDummy.bootstrapDualListbox('destroy');
      $('.duallistbox-interactions-detail-dummy').hide()

      duallistboxInteractionsDetail.bootstrapDualListbox({
        moveOnSelect: false,
        infoTextEmpty: 'Lista vacia',
        infoText: false,
        filterPlaceHolder: 'Filtrar por agente',
        moveSelectedLabel: 'Mover soleccionado',
        moveAllLabel: 'Mover todos',
        removeSelectedLabel: 'Borrar seleccionado',
        removeAllLabel: 'Borrar todos',
        infoTextFiltered: 'Filtrado {0} de {1}',
        filterTextClear: 'Mostrar todo'
      });
    }

    var listAgentSelected = $("#bootstrap-duallistbox-selected-list_agentes");
    if(this.listAgentSelected == undefined && listAgentSelected.length) {
      this.listAgentSelected = listAgentSelected;

      const observer = new MutationObserver(
        () => { listAgentSelected.trigger('mutated') }
      )
      observer.observe(listAgentSelected[0], { childList: true })
      listAgentSelected.on('mutated', () => this.onAgentChange())
    }

  }

  onAgentChange(): void {
    this.selectedAgents = [];
    for (var i = 0; i < this.listAgentSelected[0].length; i++) {
      var agent = this.listAgentSelected[0][i];
      this.selectedAgents.push({name: agent.text, id: agent.value});
    }
  }

  public onRangeDate(type: string): void {
    var now = new Date();
    switch(type) {
      case 'TODAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'YESTERDAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        break;
        case'LAST_7_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'THIS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom .getDate() + 7, 0, 0, 0, 0);
        break;
        case'PREVIOUS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7 + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        break;
        case'LAST_31_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
      default:
    }
  }

  async getAgents(){
    if (this.myDatePickerFrom == undefined || this.myDatePickerTo == undefined ) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>No se definio rango de fechas correctamente</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var from = new Date(this.myDatePickerFrom); //new Date(2021, 4, 23, 3, 0, 0,0); //'2021-04-23T03:00:00.000Z'
    var to =  new Date(this.myDatePickerTo); //new Date(2021, 4, 24, 3, 0, 0,0); //'2021-04-24T03:00:00.000Z'
    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    if ( to < from ) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>No se definio rango de fechas correctamente</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var response: Object[] = [];
    if (response.length === 0) {
      response.push({}); //para el userDetails
      response.push({}); // para el routingStatus
    }

    if( this.selectedAgents.length === 0 ){
      response[0]['agentes'] = this.allAgents; // Todos los agentes
      console.log('e'); //! Borrar esto
    }
    else{
      response[0]['agentes'] = this.selectedAgents; // Todos los agentes seleccionados
      console.log('ne'); //! Borrar esto
    }

    this.dataService.processUsers(from, to, response)
      .then( (responseUserDetails: Object[]) => {// response[0]['Pedro']: Array<AgentLog>

        var predicate = {};
        this.buildPredicate(predicate, this.selectedAgents, ['inbound']);
        this.dataService.generate(from, to, predicate, true)
          .then((responseConversations) => { // responseConversations[0][idAgente][fechaInicio]: Array<AgentLog>
            this.excelService.generateExcel(responseUserDetails, responseConversations, from, to);
          })
          .catch( (error) => this.catchService(error));
      })
      .catch( (error) => this.catchService(error) );
  }

  private buildPredicate(predicate, selectedAgents: Agent[], boundValue: string[]){
    predicate[PredicateDimension.conversation] = {}
    predicate[PredicateDimension.conversation][ConversationDetailQueryPredicateDimension.originatingDirection] = boundValue;
    predicate[PredicateDimension.segment] = {};
    var agentsIds: String[] = [];
    selectedAgents.forEach( (agent) => {
      agentsIds.push(agent.id);
    });
    predicate[PredicateDimension.segment][SegmentDetailQueryPredicateDimension.userId] = agentsIds;
  }
  catchService(error){
    console.log(error);
  }
}
