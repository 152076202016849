export class AgentLog {
  id: string = '-';
  nombreAgente: string = '-';
  fecha: number = 0;
  fechaEnd: number = 0;
  estadoAnterior: string = '-';
  estadoNuevo: string = '-';
  tiempo: number = 0;
  tiempoTime: Date;
  interacciones: number = 0;
}
