export class PredicateDimension {
    public static readonly conversation = 'conversation';
    public static readonly segment = 'segment';
    public static readonly evaluation = 'evaluation';
    public static readonly survey = 'survey';
}
export class ConversationDetailQueryPredicateDimension {
    public static readonly DIMENSIONS = ['conversationEnd', 'conversationId', 'conversationStart', 'divisionId', 'externalTag', 'mediaStatsMinConversationMos', 'originatingDirection'];
    public static readonly conversationEnd = 'conversationEnd';
    public static readonly conversationId = 'conversationId';
    public static readonly conversationStart = 'conversationStart';
    public static readonly divisionId = 'divisionId';
    public static readonly externalTag = 'externalTag';
    public static readonly mediaStatsMinConversationMos = 'mediaStatsMinConversationMos';
    public static readonly originatingDirection = 'originatingDirection';
}

export class SegmentDetailQueryPredicateDimension {
    public static readonly DIMENSIONS = ['addressFrom', 'addressTo', 'agentAssistantId', 'agentOwned', 'ani', 'authenticated', 'callbackNumber', 'callbackScheduledTime', 'conference', 'destinationConversationId', 'direction', 'disconnectType', 'dnis', 'edgeId', 'errorCode', 'exitReason', 'externalContactId', 'externalOrganizationId', 'flaggedReason', 'flowId', 'flowName', 'flowOutType', 'flowOutcome', 'flowOutcomeId', 'flowOutcomeValue', 'flowVersion', 'groupId', 'journeyActionId', 'journeyActionMapId', 'journeyCustomerId', 'journeyCustomerIdType', 'journeyCustomerSessionId', 'mediaCount', 'mediaType', 'messageType', 'monitoredParticipantId', 'outboundCampaignId', 'outboundContactId', 'outboundContactListId', 'participantName', 'protocolCallId', 'provider', 'purpose', 'queueId', 'recording', 'remote', 'remoteNameDisplayable', 'requestedLanguageId', 'requestedRouting', 'requestedRoutingSkillId', 'scoredAgentId', 'scriptId', 'segmentEnd', 'segmentType', 'sessionDnis', 'sipResponseCode', 'subject', 'teamId', 'transferTargetAddress', 'transferTargetName', 'transferType', 'usedRouting', 'userId', 'wrapUpCode', 'wrapUpNote'];
    public static readonly addressFrom = 'addressFrom';
    public static readonly addressTo = 'addressTo';
    public static readonly agentAssistantId = 'agentAssistantId';
    public static readonly agentOwned = 'agentOwned';
    public static readonly ani = 'ani';
    public static readonly authenticated = 'authenticated';
    public static readonly callbackNumber = 'callbackNumber';
    public static readonly callbackScheduledTime = 'callbackScheduledTime';
    public static readonly conference = 'conference';
    public static readonly destinationConversationId = 'destinationConversationId';
    public static readonly direction = 'direction';
    public static readonly disconnectType = 'disconnectType';
    public static readonly dnis = 'dnis';
    public static readonly edgeId = 'edgeId';
    public static readonly errorCode = 'errorCode';
    public static readonly exitReason = 'exitReason';
    public static readonly externalContactId = 'externalContactId';
    public static readonly externalOrganizationId = 'externalOrganizationId';
    public static readonly flaggedReason = 'flaggedReason';
    public static readonly flowId = 'flowId';
    public static readonly flowName = 'flowName';
    public static readonly flowOutType = 'flowOutType';
    public static readonly flowOutcome = 'flowOutcome';
    public static readonly flowOutcomeId = 'flowOutcomeId';
    public static readonly flowOutcomeValue = 'flowOutcomeValue';
    public static readonly flowVersion = 'flowVersion';
    public static readonly groupId = 'groupId';
    public static readonly journeyActionId = 'journeyActionId';
    public static readonly journeyActionMapId = 'journeyActionMapId';
    public static readonly journeyCustomerId = 'journeyCustomerId';
    public static readonly journeyCustomerIdType = 'journeyCustomerIdType';
    public static readonly journeyCustomerSessionId = 'journeyCustomerSessionId';
    public static readonly mediaCount = 'mediaCount';
    public static readonly mediaType = 'mediaType';
    public static readonly messageType = 'messageType';
    public static readonly monitoredParticipantId = 'monitoredParticipantId';
    public static readonly outboundCampaignId = 'outboundCampaignId';
    public static readonly outboundContactId = 'outboundContactId';
    public static readonly outboundContactListId = 'outboundContactListId';
    public static readonly participantName = 'participantName';
    public static readonly protocolCallId = 'protocolCallId';
    public static readonly provider = 'provider';
    public static readonly purpose = 'purpose';
    public static readonly queueId = 'queueId';
    public static readonly recording = 'recording';
    public static readonly remote = 'remote';
    public static readonly remoteNameDisplayable = 'remoteNameDisplayable';
    public static readonly requestedLanguageId = 'requestedLanguageId';
    public static readonly requestedRouting = 'requestedRouting';
    public static readonly requestedRoutingSkillId = 'requestedRoutingSkillId';
    public static readonly scoredAgentId = 'scoredAgentId';
    public static readonly scriptId = 'scriptId';
    public static readonly segmentEnd = 'segmentEnd';
    public static readonly segmentType = 'segmentType';
    public static readonly sessionDnis = 'sessionDnis';
    public static readonly sipResponseCode = 'sipResponseCode';
    public static readonly subject = 'subject';
    public static readonly transferTargetAddress = 'transferTargetAddress';
    public static readonly transferTargetName = 'transferTargetName';
    public static readonly transferType = 'transferType';
    public static readonly usedRouting = 'usedRouting';
    public static readonly userId = 'userId';
    public static readonly wrapUpCode = 'wrapUpCode'
    public static readonly wrapUpNote = 'wrapUpNote';
}

export class EvaluationDetailQueryPredicateDimension {
    public static readonly DIMENSIONS = ['calibrationId', 'contextId', 'deleted', 'evaluationId', 'evaluatorId', 'eventTime', 'formId', 'formName', 'queueId', 'rescored', 'userId'];
    public static readonly calibrationId = 'calibrationId';
    public static readonly contextId = 'contextId';
    public static readonly deleted = 'deleted';
    public static readonly evaluationId = 'evaluationId';
    public static readonly evaluatorId = 'evaluatorId';
    public static readonly eventTime = 'eventTime';
    public static readonly formId = 'formId';
    public static readonly formName = 'formName';
    public static readonly queueId = 'queueId';
    public static readonly rescored = 'rescored';
    public static readonly userId = 'userId';
}

export class SurveyDetailQueryPredicateDimension {
    public static readonly DIMENSIONS = ['eventTime', 'queueId', 'surveyCompletedDate', 'surveyFormContextId', 'surveyFormId', 'surveyId', 'surveyPromoterScore', 'surveyStatus', 'userId'];
    public static readonly eventTime = 'eventTime';
    public static readonly queueId = 'queueId';
    public static readonly surveyCompletedDate = 'surveyCompletedDate';
    public static readonly surveyFormContextId = 'surveyFormContextId';
    public static readonly surveyFormId = 'surveyFormId';
    public static readonly surveyId = 'surveyId';
    public static readonly surveyPromoterScore = 'surveyPromoterScore';
    public static readonly surveyStatus = 'surveyStatus';
    public static readonly userId = 'userId';
}
