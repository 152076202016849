import { Injectable } from '@angular/core';
import * as platformClient  from 'purecloud-platform-client-v2';
import { Conversation } from 'src/app/models/purecloud/conversation';
import Swal from 'sweetalert2'
import { TimeRange }  from '../../services/purecloud/time-range';
import { PurecloudService } from './purecloud.service';

@Injectable({
  providedIn: 'root'
})
export abstract class ConversationDataPurecloudService {
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  });

  constructor(private cloudService : PurecloudService) { }

  protected abstract processInternalCall(conversation: platformClient.Models.AnalyticsConversation, timeRange : TimeRange, calls : Object[]);
  public abstract processCall(conversation : Conversation, calls : Object[]);
  public abstract processCall(conversation : Conversation, calls : Object[]);
  public generate(from: Date, to: Date, predicate, isSync: boolean) : Promise<Object[]> {
    let promise = new Promise<Object[]>((resolve, reject) => {
      var calls : Object[] = [];
      var timeRange = new TimeRange(from, to);
      if(isSync) {
        this.downSync(timeRange, predicate, calls, resolve, reject);
      } else {
        this.downAsync(timeRange, predicate, calls, resolve, reject);
      }
    });
    return promise;
  }

  protected downSync(timeRange : TimeRange, predicate, calls : Object[], resolve, reject) : void {
    if(timeRange.isOk()) {
      this.downSyncParcial(0, timeRange, predicate, calls, {}, resolve, reject);
    } else {
      resolve(calls);
    }
  }
  
  private downSyncParcial(index : number, timeRange : TimeRange, predicate, calls : Object[], errors, resolve, reject) : void {
    index++;
    this.cloudService.postAnalyticsConversationsDetailsQuery(timeRange.fromParcial, timeRange.toParcial, predicate, index)
    .then((queryResponse) => this.processSyncParcial(queryResponse.conversations, index, timeRange, predicate, calls, errors, resolve, reject))
    .catch((response) => this.downSyncParcialCatch(index, timeRange, predicate, calls, response, errors, resolve, reject));
  }
  private downSyncParcialCatch(index : number, timeRange : TimeRange, predicate, calls : Object[], response, errors, resolve, reject) : void {
    if(response.error !== undefined && response.error.errno !== undefined) {
      var count = errors[response.error.errno + "_" + index]
      if(count === undefined) {
        count = 0;
      }
      index--;

      if(count < 3) {
        count++;
        errors[response.error.errno + "_" + index] = count;
        console.log("Errors: ", errors);
        this.downSyncParcial(index, timeRange, predicate, calls, errors, resolve, reject);
      } else {
        reject(response);
      }
    } else {
      reject(response);
    }
  }
  private processSyncParcial(conversations : platformClient.Models.AnalyticsConversation[], index : number, timeRange : TimeRange, predicate, calls : Object[], errors, resolve, reject) {
    console.log("index: ", index, ", length: ", conversations === undefined ? undefined : conversations.length);
    if(conversations == undefined || conversations.length == 0) {
      timeRange.addDate();
      this.downSync(timeRange, predicate, calls, resolve, reject);
    } else {
      timeRange.addCount(conversations.length);
      conversations.forEach((conversation) => this.processInternalCall(conversation, timeRange, calls));

      this.Toast.fire({
        title: 'Interacciones consideradas ' + calls.length + ' de ' + timeRange.count,
        timer: 1000
      });

      this.downSyncParcial(index, timeRange, predicate, calls, errors, resolve, reject);
    }
  }

  protected downAsync(timeRange : TimeRange, predicate, calls : Object[], resolve, reject) {
    var info = {
      html: undefined,
      cancel: false
    }

    Swal.fire({
      title: 'Esperando...',
      html: 'Se espera la respuesta de Purecloud <b></b>',
      didOpen: () => this.didOpenSwal(timeRange, predicate, calls, info, resolve, reject),
      willClose: () => {
        info.cancel = true;
      }
    }).then((result) => {
      console.log(result);
    })    
  }
  private async didOpenSwal(timeRange : TimeRange, predicate, calls : Object[], info, resolve, reject) {
    Swal.showLoading();
    const content = Swal.getContent();
    if (content) {
      const b = content.querySelector('b');
      if (b) {
        b.textContent = '';
        info.html = b;
      }

      this.cloudService.postAnalyticsConversationsDetailsJobs(timeRange.from, timeRange.to, predicate)
      .then((queryResponse) => this.checkStatusJob(queryResponse.jobId, timeRange, predicate, calls, 0, info, resolve, reject))
      .catch((response) => reject(response));
    }
  }
  private async checkStatusJob(jobId: string, timeRange : TimeRange, predicate, calls : Object[], retry : number, info, resolve, reject) {
    await this.sleep(1000);
    retry++;
    this.cloudService.getAnalyticsConversationsDetailsJob(jobId)
    .then((statusResponse) => this.validateStatusJob(statusResponse.state, jobId, timeRange, predicate, calls, retry, info, resolve, reject))
    .catch((response) => reject(response));
  }
  private async validateStatusJob(state: string, jobId: string, timeRange : TimeRange, predicate, calls : Object[], retry : number, info, resolve, reject) {
    if(!info.cancel) {
      console.info('a: ' + retry);
      if(state === 'FULFILLED') {
        this.Toast.fire({
          title: 'Comenzando...',
          timer: 5000
        });

          this.cloudService.getAnalyticsConversationsDetailsJobResults(jobId)
        .then((resultResponse) => this.processJob(resultResponse.cursor, jobId, resultResponse.conversations, 0, timeRange, predicate, calls, resolve, reject))
        .catch((response) => reject(response));
      } else {
        if (info.html) {
          info.html.textContent = ': ' + retry
        }
        await this.checkStatusJob(jobId, timeRange, predicate, calls, retry, info, resolve, reject)
      }
    } else {
      reject('Cancelado');
    }
  }
  private processJob(cursor: string, jobId: string, conversations : platformClient.Models.AnalyticsConversation[], index : number, timeRange : TimeRange, predicate, calls : Object[], resolve, reject) {
    console.log("index: ", index, ", length: ", conversations === undefined ? undefined : conversations.length);
    index++;
    if(conversations == undefined || conversations.length == 0) {
      resolve(calls);
    } else {
      timeRange.addCount(conversations.length);
      conversations.forEach((conversation) => this.processInternalCall(conversation, timeRange, calls));

      this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (1000*(index - 1) + conversations.length),
        timer: 5000
      });

      if(cursor == undefined) {
        resolve(calls);
      } else {
        this.cloudService.getAnalyticsConversationsDetailsJobResults(jobId, cursor)
        .then((resultResponse) => this.processJob(resultResponse.cursor, jobId, resultResponse.conversations, index, timeRange, predicate, calls, resolve, reject))
        .catch((response) => reject(response));
      }
    }
  }
  private sleep(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
