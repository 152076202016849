import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { EnargasDataService } from '../../services/enargas/enargas-data.service';
import { EnargasExcelService } from '../../services/enargas/enargas-excel.service';
import { Enargas }  from '../../models/enargas/Enargas';

@Component({
  selector: 'app-enargas',
  templateUrl: './enargas.component.html',
  styleUrls: ['./enargas.component.css']
})
export class EnargasComponent implements OnInit {
  pickerFrom;
  myDatePickerFrom: Date;
  pickerTo;
  myDatePickerTo: Date;

  constructor(private enargasData : EnargasDataService, private enargasExcel : EnargasExcelService) { }

  ngOnInit(): void {
  }

  onRangeDate(type: string): void {
    var now = new Date();
    switch(type) {
      case 'TODAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'YESTERDAY':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        break;
        case'LAST_7_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
        case'THIS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom .getDate() + 7, 0, 0, 0, 0);
        break;
        case'PREVIOUS_WEEK':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7 + 1, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        break;
        case'LAST_31_DAYS':
        this.myDatePickerFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
        this.myDatePickerTo = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        break;
      default:
    }
  }

  onGenerateEnargas(): void {
    console.info('pickerFrom: ' + this.pickerFrom + ', myDatePickerFrom: ' + this.myDatePickerFrom + ', pickerTo: ' + this.pickerTo + ', myDatePickerTo: ' + this.myDatePickerTo);
    if(this.myDatePickerFrom == undefined || this.myDatePickerTo == undefined) {
      return;
    }
    var queues = ['Emergencias', 'Comercial'];
    var medias = ['voice'];

    var from = new Date(this.myDatePickerFrom); //new Date(2021, 4, 23, 3, 0, 0,0); //'2021-04-23T03:00:00.000Z'
    var to = new Date(this.myDatePickerTo); //new Date(2021, 4, 24, 3, 0, 0,0); //'2021-04-24T03:00:00.000Z'
    
    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    this.enargasData.generateSync(queues, medias, from, to)
    .then((response) => this.processEnargasExcel(response))
    .catch(function(response) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b></b>',
        didOpen: () => {
          Swal.showLoading();
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              console.log(response);
              if(response.body)
                response = response.body
              b.textContent = JSON.stringify(response);
            }
          }
        },
        willClose: () => {
          window.location.href = window.location.href
        }
      }).then((result) => {
        console.log(result);
      })    
    });
  }
  private processEnargasExcel(response : Enargas[]): void {
    console.log(response);

    this.enargasExcel.exportExcel(response);
  }
  private processEnargasTxt(response : Enargas[]): void {
    console.log(response);

    var data = '';
    response.forEach(function(contact){
      data = data + contact.fecha.replace(/[^0-9]/g, '') + contact.hora.replace(/[^0-9]/g, '') + ',' + contact.numero + ',' + contact.demora + ',' + contact.duracion + '\r\n';
    });
    const uri = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data);

    var currentBotton = document.getElementById('generate-enargas');
    var a = document.getElementById('download-enargas') as HTMLAnchorElement;
    if(a) {
      a.href = uri;
    } else {
      var newBr = document.createElement("br");
      a = document.createElement('a');
      a.href = uri;
      a.id = "download-enargas";
      a.download = "reports-enargas.txt";
      a.className="btn btn-block btn-secondary";
      a.innerHTML = "Descargar";
      currentBotton.parentNode.insertBefore(newBr, currentBotton.nextSibling);
      currentBotton.parentNode.insertBefore(a, currentBotton.nextSibling);
    }
  }
}
