export class AgentLogin {
  id: string = '-';
  nombreAgente: string = '-';
  fecha: number = 0;
  logIn: number = 0;
  logOut: number = 0;
  tiempo: number = 0;
  tiempoTime: Date;
  terminado: boolean = false;
}
