import { Injectable } from '@angular/core';
import { Workbook, Worksheet , ValueType} from 'exceljs';
import * as fs from 'file-saver';
import { AgentLog }  from 'src/app/models/agents-logs/agents-logs';
import { DateTime } from 'src/app/utils/date-time';

@Injectable({
  providedIn: 'root'
})
export class AgentsLogsExcelService {

  constructor() { }

  generateExcel(responseUserDetails: Object[], responseConversations: Object[], from: Date, to: Date){
    var name = 'reporte_log_estados_agentes';

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reporte');
    worksheet.properties.defaultRowHeight = 18;

    //? font tamaño alineacion y ancho de todas las celdas
    for(var i =1; i <= 7; i++){
      worksheet.getColumn(i).font = {name: 'Tahoma', size: 8}; //#ffffff white
      worksheet.getColumn(i).width = 20;
      worksheet.getColumn(i).alignment = { horizontal: 'center', vertical: 'middle' };
    }
    worksheet.getColumn(1).width = 33; //LOGINID
    worksheet.getColumn(3).width = 24; //FECHA
    worksheet.getColumn(6).numFmt = "[HH]:mm:ss"; //TIEMPO
    //? Los header's
    var headers = ['LOGINID', 'AGENTE','FECHA','ESTADO ANTERIOR','ESTADO NUEVO','TIEMPO',
                   'INTERACCIONES'];
    var row = worksheet.addRow(headers);
    row.alignment = { vertical: 'top' };
    row.font = {bold: true};
    //? custom exclusivo de los header's
    ['A1','B1','C1','D1','E1','F1',
     'G1'].map(key => {
      var cell = worksheet.getCell(key);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000099' },
        bgColor: { argb: '000099' }
      };
      cell.font = {color: {argb: 'FFFFFF'}, bold: true };
      cell.alignment = {horizontal : 'center'};
    });

    var keys = Object.keys(responseUserDetails[0]); // response[0]['Pedro']: Array<AgentLog>
    for(var i = 0; i < keys.length; i++){
      var agente: string = keys[i];
      var logsPresence: AgentLog[] = responseUserDetails[0][agente];
      var logsRouting: AgentLog[] = responseUserDetails[1][agente];

      //console.log('Antes de añadir rows, agente ', agente, '\nlogsPresence', logsPresence, ' \nlogs routing ', logsRouting, ' agentConversations ', agentConversations, ' \nconversations ', responseConversations[0]);  //! Borrar esto

      if( agente !== 'agentes' ){  // 'agentes' es la lista con los agentes seleccionados
        for(var j = 0; j < logsPresence.length; j++){
          var agentConversations: Object = responseConversations[0][logsPresence[j].id];

          if( logsPresence[j].estadoNuevo === 'ON_QUEUE' ){
            if( j+1 < logsPresence.length ){
              var logOnQueue = logsPresence[j+1]; // Si el estado nuevo es Onqueue, el siguiente estado es onqueue
              var indexRouting = this.getFirstRoutingInRange(logOnQueue.fecha, logOnQueue.fechaEnd, logsRouting);
              if(indexRouting !== -1){
                logsPresence[j].estadoNuevo = logsRouting[indexRouting].estadoAnterior;
              }
            }}
          if(logsPresence[j].estadoAnterior === 'ON_QUEUE'){
           this.addRowsQueueCase(logsPresence, logsRouting, agentConversations, j, worksheet); // esta podria devolver el primer estado para que enganche en vez de hacer el if de arriba
          }
          else{
            this.addRow(logsPresence[j], worksheet);
          }
        }
      }
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }

  private getFirstConversationState(logOnqueue: AgentLog, responseConversations: Object[]): string{
    var res = '';
    var conversationsStart: string[];
    if(responseConversations[0][logOnqueue.id] === null || responseConversations[0][logOnqueue.id] === undefined){
      conversationsStart = [];
    }
    else{
      conversationsStart = Object.keys(responseConversations[0][logOnqueue.id]);
    }

    for(var i = 0; i < conversationsStart.length; i++){
      var startKey = parseInt(conversationsStart[i]);
      var logs: AgentLog[] = responseConversations[0][logOnqueue.id][startKey.toString()];

      if( logOnqueue.fecha <= startKey && startKey <= logOnqueue.fechaEnd ){ // si la conversation esta en el rango de ese onqueue
        res = logs[0].estadoAnterior;
        break;
      }
    }

    return res;
  }

  private addRowsQueueCase(logsPresence: AgentLog[], logsRouting: AgentLog[], agentConversations: Object, onQueueIndex: number, worksheet ){
    var res: AgentLog[] = [];
    var conversationKeys = ( agentConversations !== undefined ) ? Object.keys(agentConversations) : [];
    conversationKeys.sort( (a,b) => parseInt(a) - parseInt(b) );
    var from = logsPresence[onQueueIndex].fecha;
    var to = logsPresence[onQueueIndex].fechaEnd;

    var indexConversation = this.getFirstConversationInRange(from, to, agentConversations);
    var indexRouting = this.getFirstRoutingInRange(from, to, logsRouting);
    var logsConversation: AgentLog[] = ( indexConversation !== -1 ) ? agentConversations[ conversationKeys[indexConversation] ] : [];
    var logRouting = logsRouting[indexRouting];

    for(var i = indexRouting; i < logsRouting.length ; i++){
      logRouting = logsRouting[i];

      if(indexRouting !== -1 && this.logInRange(from, to, logRouting)){
        for( var j = indexConversation; j !== -1 && j < conversationKeys.length; j++){
          logsConversation = agentConversations[ conversationKeys[j] ];
          if( logsConversation.length !== 0){
            if( logsConversation[0].fecha < logRouting.fecha ){
              for(var k = 0; k < logsConversation.length; k++){
                res.push(logsConversation[k]);
              }
              indexConversation = j+1; // si no avanza indexConv cuando pusheamos se pueden repetir conversations
            }
          }
          else{
            indexConversation = j+1; // si llega a haber un caso donde una conversation fue vacia, la salteamos
          }
        }
        if( logRouting.estadoAnterior !== 'INTERACTING'){
          res.push(logRouting);
        }
      }
      //? if( logRouting excedido al onQueue ) then break; ?
    }

    var nextState:string = '-';
    if( onQueueIndex+1 < logsPresence.length ){
      nextState = logsPresence[onQueueIndex+1].estadoAnterior;
    }
    this.fixStates(res);
    if( 0 < res.length ){
      res[res.length-1].estadoNuevo = nextState;
    }
    else{
      res.push(logsPresence[onQueueIndex]);
    }
    for(var i = 0; i < res.length; i++){
      this.addRow(res[i], worksheet);
    }

    var retorno = 'retorno -';
    if(res.length > 0) retorno = res[0].estadoAnterior;

    return retorno;
  }


  private fixStates(logs: AgentLog[]){
    var nombreAgente = ( 0 < logs.length ) ? logs[0].nombreAgente : '';
    for(var i = 0; i < logs.length-1; i++){
      logs[i].nombreAgente = nombreAgente;
      logs[i+1].nombreAgente = nombreAgente;
      logs[i].estadoNuevo = logs[i+1].estadoAnterior;
    }
  }
  private logInRange(from, to, log: AgentLog): boolean{
    var res: boolean = ( log !== undefined ) ? from < log.fecha && log.fecha < to : false;
    return res;
  }
  private getFirstConversationInRange(from, to, agentConversations: Object): number{
    var res = -1;
    var conversationKeys =  ( agentConversations !== undefined ) ? Object.keys(agentConversations) : [];
    conversationKeys.sort( (a,b) => parseInt(a) - parseInt(b) );

    for(var i = 0; i < conversationKeys.length; i++){
      var conversationLogs: AgentLog[] = agentConversations[ conversationKeys[i] ];
      if( 0 < conversationLogs.length && from < conversationLogs[0].fecha && conversationLogs[0].fecha < to ){
        res = i;
        break;
      }
    }

    return res;
  }
  private getFirstRoutingInRange(from, to, logsRouting: AgentLog[]): number{
    var res = -1;
    for(var i = 0; i < logsRouting.length; i++){
      var log = logsRouting[i];

      if( this.logInRange(from, to, log) ){
        res = i;
        break;
      }
    }

    return res;
  }

  private futuroEnRango(conversationsStart, logOnqueue, i): boolean{
    return i+1< conversationsStart.length && logOnqueue.fecha <= parseInt(conversationsStart[i+1]) && parseInt(conversationsStart[i+1]) <= logOnqueue.fechaEnd;
  }

  private addRow(log: AgentLog, worksheet){
    var fecha: Date = new Date();
    var fechaS: string = '-';
    if( log.fecha !== 0){ //ms
      fecha = new Date(log.fecha);
      fechaS = this.convertDateToDayStringCustom(fecha, '/') + ' ' + DateTime.convertDateToHourString(fecha, ':') ;
    }

    log.tiempoTime = this.adapterHours(log.tiempo);

    var columns = [ log.id, log.nombreAgente, fechaS,
                              log.estadoAnterior, log.estadoNuevo, log.tiempoTime,
                              log.interacciones.toString()];

    var row = worksheet.addRow(columns);
    row.height = 18;
    row.font = {name: 'Tahoma', size: 7, bold: true};
    row.alignment = { vertical: 'middle' };
    row.alignment = { horizontal: 'center' };

  }

  private adapterHours(time : number) : Date {
    var date = new Date();
    date.setTime(0);
    date.setDate(-25538);
    date.setHours(date.getHours(), -75, -108, Math.floor(time/1000)*1000);
    return date;
  }

  private toClockFormat(ms: number): string{
    var hours = Math.floor(ms/(1000*60*60));  //ms * min * sec
    var hoursRemainderMs = ms % (1000*60*60);
    var minutes = Math.floor(hoursRemainderMs/(1000*60)); //ms * sec
    var minutesRemainderMs = hoursRemainderMs % (1000*60);
    var seconds = Math.floor(minutesRemainderMs / 1000); //ms
    var hoursS = (hours<10)? hoursS = '0'+hours.toString() : hours.toString();
    var minutesS = (minutes<10)? minutesS = '0'+minutes.toString() : minutes.toString();
    var secondsS = (seconds<10)? secondsS = '0'+seconds.toString() : seconds.toString();
    // hh:mm:ss
    return hoursS + ':' + minutesS + ':' + secondsS;
  }

  private convertDateToDayStringCustom(date : Date, delimiter : string) : string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return  day + delimiter + month  + delimiter + year ;
}
}
