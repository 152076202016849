import { Injectable } from '@angular/core';
import { Models } from 'purecloud-platform-client-v2';
import { Conversation, Participant } from 'src/app/models/purecloud/conversation';
import { ConversationDataPurecloudService } from '../purecloud/conversation-data-purecloud.service';
import { PurecloudService } from '../purecloud/purecloud.service';
import { TimeRange } from '../purecloud/time-range';
import { MapConversation } from 'src/app/utils/map-conversation';
import { DateTime } from 'src/app/utils/date-time';
import { AbandonedDetail } from 'src/app/models/abandoned-interactions-detail/abandoned-detail';



@Injectable({
  providedIn: 'root'
})
export class AbandonedInteractionsDetailDataService  extends ConversationDataPurecloudService  {

  constructor(private purecloud: PurecloudService) {
    super(purecloud);
  }
  protected processInternalCall(conversation: Models.AnalyticsConversation, timeRange: TimeRange, calls: Object[]) {
    var from = DateTime.convertStringToDate(conversation.conversationStart);
    if(timeRange.fromParcial.getTime() === timeRange.from.getTime() && from.getTime() <= timeRange.from.getTime() ) {
      this.processCall(MapConversation.analyticsConversation(conversation), calls)
      return;
    }

    if(from.getTime() > timeRange.fromParcial.getTime()) {
      this.processCall(MapConversation.analyticsConversation(conversation), calls)
    }
  }
  public processCall(conversation: Conversation, calls: Object[]){
    var abandonedDetail = new AbandonedDetail();
    abandonedDetail.idInteraccion = conversation.conversationId;
    abandonedDetail.fecha = DateTime.convertStringToDate(conversation.conversationStart);
    console.log(abandonedDetail.fecha);

    abandonedDetail.fecha.setHours(abandonedDetail.fecha.getHours() -6);
    var session = conversation.participants[0].sessions[0];
    abandonedDetail.cliente = session.direction === 'inbound' ? session.ani : session.dnis;

    conversation.participants.forEach(participant => this.processParticipant(participant, abandonedDetail));

    if(abandonedDetail.interactuoAgente === false) {
      calls.push(abandonedDetail);
    }
  }

  public processParticipant(participant: Participant, abandonedDetail: AbandonedDetail){
    switch (participant.purpose) {
      case 'acd': {
        abandonedDetail.segmento++;
        abandonedDetail.campania = participant.participantName;
        for(var i = 0; i < participant.sessions.length; i++) {
          var session = participant.sessions[i];
          for(var j = 0; j < session.segments.length; j++) {
            var segment = session.segments[j];
            var start = DateTime.convertStringToDate(segment.segmentStart);
            var end = DateTime.convertStringToDate(segment.segmentEnd);
            abandonedDetail.tiempo += (end.getTime() - start.getTime());
          }
        }
        break;
      }
      case 'agent': {
        abandonedDetail.segmento++;

        abandonedDetail.llegoAgente=true;
        for(var i = 0; i < participant.sessions.length; i++) {
          var session = participant.sessions[i];
          for(var j = 0; j < session.segments.length; j++) {
            var segment = session.segments[j];
            if(segment.segmentType !== 'alert') {
              abandonedDetail.interactuoAgente = true;
            }
          }
        }
        break;
      }
      case 'ivr': {
        if(abandonedDetail.purposeAnterior !=='ivr') {
          abandonedDetail.segmento++;
        }
        break;
      }
      case 'external':
      case 'customer': {
        break;
      }
      default: {
        abandonedDetail.segmento++;
        break;
      }
    }
    abandonedDetail.purposeAnterior = participant.purpose;
  }

  public generate(from: Date, to: Date, predicate, isSync: boolean) : Promise<AbandonedDetail[]> {
    let promise = new Promise<AbandonedDetail[]>((resolve, reject) => {
      var calls : AbandonedDetail[] = [];
      var timeRange = new TimeRange(from, to);
      if(isSync) {
        this.downSync(timeRange, predicate, calls, resolve, reject);
      } else {
        this.downAsync(timeRange, predicate, calls, resolve, reject);
      }
    });
    return promise;
  }
}