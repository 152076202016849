import { Injectable } from '@angular/core';
import { PurecloudService } from '../purecloud/purecloud.service';
import * as platformClient from 'purecloud-platform-client-v2';
import { DateTime } from 'src/app/utils/date-time';
import { Agent } from 'src/app/models/interactions-detail/agent';
import Swal from 'sweetalert2'

class getOutboundCampaignsOptions implements platformClient.OutboundApi.getOutboundCampaignsOptions {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}
class UserDetailsQuery implements platformClient.Models.UserDetailsQuery {
  constructor(public interval: string, public order: string, public paging: PagSpec ) {
  }
}
class PagSpec implements platformClient.Models.PagingSpec {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}
class getAgentsOfCampaignOptions implements platformClient.RoutingApi.getRoutingQueueMembersOptions{
  constructor(public pageSize: number, public pageNumber: number){
  }
}
class getRoutingQueuesOptions implements platformClient.RoutingApi.getRoutingQueuesOptions {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class AgentLoginCloudService {

  constructor(private purecloudService : PurecloudService) { }

  public getUsersDetails( from:Date, to: Date ): Promise<any|Array<platformClient.Models.AnalyticsUserDetail>> {
    var interval = DateTime.convertDateToString(from) + '/' + DateTime.convertDateToString(to);
    return this.purecloudService.checkLogin() || new Promise<Array<platformClient.Models.AnalyticsUserDetail>>((resolve, reject) => {
      this.getUsersDetailsInternal(new UserDetailsQuery(interval, 'asc', new PagSpec(100,1)), new Array<platformClient.Models.AnalyticsUserDetail>(), resolve, reject);
    });
  }
  private getUsersDetailsInternal(opts: UserDetailsQuery, users: Array<platformClient.Models.AnalyticsUserDetail>, resolve, reject) {
    console.log('UserDetailQuery: ' + JSON.stringify(opts)); //! Borrar esto
    this.Toast.fire({
      title: "Estados considerados " + opts.paging.pageSize + ' de la pagina ' + opts.paging.pageNumber,
      timer: 1000
    });
    this.purecloudService.getUsersApi().postAnalyticsUsersDetailsQuery( opts )
      .then((response) => this.thenUsersDetails(response, opts , users, resolve, reject))
      .catch((response) => this.catchUsersDetails(response, reject));
  }
  private thenUsersDetails(response: platformClient.Models.AnalyticsUserDetailsQueryResponse,opts: UserDetailsQuery, usersDetails: Array<platformClient.Models.AnalyticsUserDetail>, resolve, reject) {
    if(response.userDetails !== undefined) {
      response.userDetails.forEach( (userDetail) => {
        usersDetails.push(userDetail);
      });
      opts.paging.pageNumber++;
      this.getUsersDetailsInternal(opts ,usersDetails , resolve, reject);
    } else {
      resolve(usersDetails);
    }
  }
  private catchUsersDetails(response: any, reject) {
    reject(response);
  }

  public getCampaigns(): Promise<any|Array<any>> {
    return this.purecloudService.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
      this.getCampaignsInternal(new getOutboundCampaignsOptions(100, 1), new Array<any>(), resolve, reject);
    });
  }
  private getCampaignsInternal(opts: getOutboundCampaignsOptions, users: Array<any>, resolve, reject) {
    this.purecloudService.getOutbound().getOutboundCampaigns()
      .then((response) => this.thenCampaigns(response, users, resolve, reject))
      .catch((response) => this.catchCampaigns(response, reject));
  }
  private thenCampaigns(response: platformClient.Models.CampaignEntityListing, users: Array<any>, resolve, reject) {
    response.entities.forEach((user) => users.push({
      id: user.id,
      name: user.name
    }));
    if(response.pageNumber < response.pageCount) {
      this.getCampaignsInternal(new getOutboundCampaignsOptions(100, (response.pageNumber + 1)), users, resolve, reject);
    } else {
      resolve(users);
    }
  }
  private catchCampaigns(response: any, reject) {
    reject(response);
  }

  public getAgentsOfCampaign(campaignId: string): Promise<any|Array<Agent>> {
    return this.purecloudService.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
      this.getAgentsOfCampaignInternal(campaignId, new getAgentsOfCampaignOptions(100, 1), new Array<Agent>(), resolve, reject);
    });
  }
  private getAgentsOfCampaignInternal(campaignId: string, opts: platformClient.RoutingApi.getRoutingQueueMembersOptions, agents: Array<Agent>, resolve, reject){
    this.purecloudService.getRouting().getRoutingQueueMembers(campaignId, opts)
    .then( (response) =>{
      this.thenAgentsOfCampaign(response, campaignId, agents, resolve, reject);
    })
    .catch( (error) => {
      this.catchAgentsOfCampaign(error, reject)
    });
  }
  private catchAgentsOfCampaign(error: any, reject){
    reject(error);
  }
  private thenAgentsOfCampaign(response: platformClient.Models.QueueMemberEntityListing, campaignId, agents: Array<Agent>, resolve, reject){
    response.entities.forEach( (agent) =>{
      var agentAux = new Agent();
      agentAux.id = agent.id;
      agentAux.name = agent.name;
      agents.push(agentAux);
    });
    if(response.pageNumber < response.pageCount) {
      this.getAgentsOfCampaignInternal(campaignId, new getAgentsOfCampaignOptions(100, (response.pageNumber + 1)), agents, resolve, reject);
    } else {
      resolve(agents);
    }
  }

  /* */
  public getQueues(): Promise<any|Array<any>> {
    return this.purecloudService.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
      this.getQueuesInternal(new getRoutingQueuesOptions(100, 1), new Array<any>(), resolve, reject);
    });
  }
  private getQueuesInternal(opts: getRoutingQueuesOptions, users: Array<any>, resolve, reject) {
    this.purecloudService.getRouting().getRoutingQueues(opts)
      .then((response) => this.thenQueues(response, users, resolve, reject))
      .catch((response) => this.catchQueues(response, reject));
  }
  private thenQueues(response: platformClient.Models.QueueEntityListing, users: Array<any>, resolve, reject) {
    response.entities.forEach((user) => users.push({
      id: user.id,
      name: user.name
    }));
    if(response.pageNumber < response.pageCount) {
      this.getQueuesInternal(new getRoutingQueuesOptions(100, (response.pageNumber + 1)), users, resolve, reject);
    } else {
      resolve(users);
    }
  }
  private catchQueues(response: any, reject) {
    reject(response);
  }
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  });
}
