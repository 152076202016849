import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { COMMA, ENTER, F, TAB } from '@angular/cdk/keycodes';
import { ConversationDetailQueryPredicateDimension, PredicateDimension, SegmentDetailQueryPredicateDimension } from '../../constants/purecloud';
import * as $ from "jquery"
import Swal from 'sweetalert2'
import { EventCounterDataService } from 'src/app/services/event-counter/event-counter-data.service';
import { EventCounterExcelService } from 'src/app/services/event-counter/event-counter-excel.service';
import { EventCounterCloudService } from 'src/app/services/event-counter/event-counter-cloud.service';
import { DateTime } from 'src/app/utils/date-time';
import { EventCounter } from 'src/app/models/event-counter/event-counter';

declare const duallistboxEventCounter: any;
declare const duallistboxEventCounterDummy: any;

@Component({
  selector: 'app-event-counter',
  templateUrl: './event-counter.component.html',
  styleUrls: ['./event-counter.component.css']
})
export class EventCounterComponent implements OnInit, DoCheck {

  readonly separatorKeysCodes = [ENTER, COMMA, TAB] as const;
  pickerFrom;
  myDatePickerFrom;
  pickerTo;
  myDatePickerTo;
  listDidsSelected: any;
  selectedDids: any[] = [];
  allDids: Array<string> = [];
  duallistbox: any; eventCounterDummy: Array<string> = [];

  maxDate: Date;


  constructor(private dataService: EventCounterDataService, private excelService: EventCounterExcelService, private cloudService: EventCounterCloudService) {
  }

  ngOnInit(): void {

    this.getDids();
    this.cloudService.getMaxDate().then((response) => this.maxDate = DateTime.convertStringToDate(response.dataAvailabilityDate));
  }

  getDids() {
    this.cloudService.getDids()
      .then((response) => {
        response.forEach((user) => this.loadDids(user));
      })
      .catch((response) => alert(response));
  }

  loadDids(did) {
    this.allDids.push(did);
  }


  ngDoCheck(): void {
    var duallistbox = $('.duallistbox-event-counter');
    if (this.duallistbox == undefined && duallistbox[0].length > 0) {
      this.duallistbox = duallistbox;
      console.log(duallistbox);
      duallistboxEventCounterDummy.bootstrapDualListbox('destroy');
      $('.duallistbox-event-counter-dummy').hide()

      duallistboxEventCounter.bootstrapDualListbox({
        moveOnSelect: false,
        infoTextEmpty: 'Lista vacia',
        infoText: false,
        filterPlaceHolder: 'Filtrar por agente',
        moveSelectedLabel: 'Mover soleccionado',
        moveAllLabel: 'Mover todos',
        removeSelectedLabel: 'Borrar seleccionado',
        removeAllLabel: 'Borrar todos',
        infoTextFiltered: 'Filtrado {0} de {1}',
        filterTextClear: 'Mostrar todo'
      });
    }

    var listDidsSelected = $("#bootstrap-duallistbox-selected-list_dids");
    if (this.listDidsSelected == undefined && listDidsSelected.length > 0) {
      this.listDidsSelected = listDidsSelected;
      const observer = new MutationObserver(
        () => { listDidsSelected.trigger('mutated') }
      )
      observer.observe(listDidsSelected[0], { childList: true })
      listDidsSelected.on('mutated', () => this.onDidsChange())
    }

  }
  onDidsChange(): void {
    this.selectedDids = [];
    console.log(this.listDidsSelected);
    for (var i = 0; i < this.listDidsSelected[0].length; i++) {
      var dids = this.listDidsSelected[0][i];
      this.selectedDids.push(dids.text);
    }
  }

  onRangeDate(type: string): void {
    var now = new Date();
    switch (type) {
      case 'TODAY':
        var from = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        if (from.getTime() > this.maxDate.getTime()) {
          from = this.maxDate;
        }
        var to = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        if (to.getTime() > this.maxDate.getTime()) {
          to = this.maxDate;
        }
        this.myDatePickerFrom = from;
        this.myDatePickerTo = to;
        break;
      case 'YESTERDAY':
        var from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        if (from.getTime() > this.maxDate.getTime()) {
          from = this.maxDate;
        }
        var to = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        if (to.getTime() > this.maxDate.getTime()) {
          to = this.maxDate;
        }
        this.myDatePickerFrom = from;
        this.myDatePickerTo = to;
        break;
      case 'LAST_7_DAYS':
        var from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6, 0, 0, 0, 0);
        if (from.getTime() > this.maxDate.getTime()) {
          from = this.maxDate;
        }
        var to = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        if (to.getTime() > this.maxDate.getTime()) {
          to = this.maxDate;
        }
        this.myDatePickerFrom = from;
        this.myDatePickerTo = to;
        break;
      case 'THIS_WEEK':
        var from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1, 0, 0, 0, 0);
        if (from.getTime() > this.maxDate.getTime()) {
          from = this.maxDate;
        }
        var to = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        if (to.getTime() > this.maxDate.getTime()) {
          to = this.maxDate;
        }
        this.myDatePickerFrom = from;
        this.myDatePickerTo = to;
        break;
      case 'PREVIOUS_WEEK':
        var from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7 + 1, 0, 0, 0, 0);
        if (from.getTime() > this.maxDate.getTime()) {
          from = this.maxDate;
        }
        var to = new Date(this.myDatePickerFrom.getFullYear(), this.myDatePickerFrom.getMonth(), this.myDatePickerFrom.getDate() + 7, 0, 0, 0, 0);
        if (to.getTime() > this.maxDate.getTime()) {
          to = this.maxDate;
        }
        this.myDatePickerFrom = from;
        this.myDatePickerTo = to;
        break;
      case 'LAST_31_DAYS':
        var from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0, 0);
        if (from.getTime() > this.maxDate.getTime()) {
          from = this.maxDate;
        }
        var to = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);
        if (to.getTime() > this.maxDate.getTime()) {
          to = this.maxDate;
        }
        this.myDatePickerFrom = from;
        this.myDatePickerTo = to;
        break;
      default:
    }
  }

  async onGenerateEventCounter() {
    console.info('pickerFrom: ' + this.pickerFrom + ', myDatePickerFrom: ' + this.myDatePickerFrom + ', pickerTo: ' + this.pickerTo + ', myDatePickerTo: ' + this.myDatePickerTo);
    if (this.myDatePickerFrom == undefined || this.myDatePickerTo == undefined) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>No se definio rango de fechas correctamente</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var listDidsNoSelected = $("#bootstrap-duallistbox-nonselected-list_dids");
    console.log(listDidsNoSelected);
    console.log(this.selectedDids);
    if (listDidsNoSelected[0].length > 0 && this.selectedDids.length > 100) {
      Swal.fire({
        title: 'Cancelado',
        html: 'Se cancelo la tarea por... <b>Se supero la cantidad de dids permitidos: 100</b>',
      }).then((result) => {
        console.log(result);
      })
      return;
    }

    var from = new Date(this.myDatePickerFrom); //new Date(2021, 4, 23, 3, 0, 0,0); //'2021-04-23T03:00:00.000Z'
    var to = new Date(this.myDatePickerTo); //new Date(2021, 4, 24, 3, 0, 0,0); //'2021-04-24T03:00:00.000Z'

    from.setHours(from.getHours() + 3);
    to.setHours(to.getHours() + 3);

    var selectedDids = [];
    var selectedMapDids = {};
    for (var i = 0; i < this.selectedDids.length; i++) {
      selectedDids.push(this.selectedDids[i]);
    }
    for (var i = 0; i < this.allDids.length; i++) {
      selectedMapDids[this.allDids[i]] = this.allDids[i];
    }

    let dids = await this.cloudService.getDids();

    var predicate = {};
    predicate[PredicateDimension.conversation] = {}
    predicate[PredicateDimension.conversation][ConversationDetailQueryPredicateDimension.conversationEnd] = [];
    predicate[PredicateDimension.segment] = {}
    predicate[PredicateDimension.segment][SegmentDetailQueryPredicateDimension.dnis] = selectedDids;

    this.dataService.generate(from, to, predicate, false)
      .then((response) => this.processExcel(response, new Date(this.myDatePickerFrom), new Date(this.myDatePickerTo), this.selectedDids))
      .catch(function (response) {
        Swal.fire({
          title: 'Cancelado',
          html: 'Se cancelo la tarea por... <b></b>',
          didOpen: () => {
            Swal.showLoading();
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector('b');
              if (b) {
                console.log(response);
                var result = undefined;
                if (response.body !== undefined && response.body.message !== undefined) {
                  result = response.body.message
                }
                if (response.error !== undefined && response.error.message !== undefined) {
                  result = response.error.message + " (" + response.error.errno + ")";
                }
                if (result === undefined) {
                  result = response;
                }
                b.textContent = JSON.stringify(result);
              }
            }
          },
          willClose: () => {
            window.location.href = window.location.href
          }
        }).then((result) => {
          console.log(result);
        })
      });
  }

  private processExcel(response: Object[], from: Date, to: Date, dids): void {
    var arrayEventCounter = new Array<EventCounter>();
    response.forEach(element => {
      const keys = Object.keys(element);
      keys.forEach(key => {
        var evento = new EventCounter();
        var palabra = key.split(';');
        evento.secuencia = palabra[0];
        evento.nombre = palabra[1];
        evento.descripcion = palabra[2];
        evento.valor = palabra[3];
        evento.cantidad = element[key];

        console.log(evento);
        arrayEventCounter.push(evento);
      }
      );
    });

    arrayEventCounter.sort((a, b) => {
      if (a.nombre == b.nombre) {
        if (a.secuencia == b.secuencia) {
          return 0;
        }
        if (a.secuencia < b.secuencia) {
          return -1;
        }
        return 1;
      }
      if (a.nombre < b.nombre) {
        return -1;
      }
      return 1;
    });

    this.excelService.exportExcel(arrayEventCounter, from, to, dids);
  }
}
