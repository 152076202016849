export class Task {
    campania:string;
    idTarea:string;
    agente:string;
    intentos:number=0;
    estado:string;
    ultimaLLamada:Date;
    numeroLinea:string;
    idCliente:string;
    nombreCliente:string;
    causaTerminacion:string;
    resultadoGestion:string;
    tiposResultadosGestion:string;
    ultimoWrapup:string;
    ultimoWrapupHora: Date;
    observaciones: string;
   

}