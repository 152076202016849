import { Injectable } from '@angular/core';
import { Workbook, Worksheet , ValueType} from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import { InteractionsDetail }  from '../../models/interactions-detail/interactions-detail';

@Injectable({
  providedIn: 'root'
})
export class InteractionsDetailExcelService {

  constructor() { }
  exportExcel(info : InteractionsDetail[], from: Date, to: Date, anis: string[], contrato: string[]) {
    var headers = ['AGENTE', 'INICIO', 'CONVERSATIONID', 'SEGMENTO', 'TIPO CONTACTO', 'AGENTE CLIENTE', 'CLIENTE', 'DNIS', 'SENTIDO', 'DURACIÓN', 'PREVIEW', 'DIALING', 'RINGING', 'TALKINGTIME', 'HOLD', 'ACW', 'ENCOLA', 'RESULTADO GESTIÓN', 'Nota', 'Contrato', 'EQUIPO', 'ENTRANTE', 'DERIVADA', 'ABANDONADA', 'TRANSFERIN', 'TRANSFEROUT', 'EMPRESA', 'CAMPAÑA', 'ORIGEN CORTE'];
    var name = 'reporte_detalle_interacciones';

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reporte');

    worksheet.getColumn(1).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(1).width = 27.86;
    worksheet.getColumn(1).alignment = { horizontal: 'center' };
    worksheet.getColumn(2).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(2).width = 18.29;
    worksheet.getColumn(2).alignment = { horizontal: 'center' };
    worksheet.getColumn(3).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(3).width = 32;
    worksheet.getColumn(3).alignment = { horizontal: 'center' };
    worksheet.getColumn(4).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(4).width = 8.86;
    worksheet.getColumn(4).alignment = { horizontal: 'center' };
    worksheet.getColumn(5).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(5).width = 18.29;
    worksheet.getColumn(5).alignment = { horizontal: 'center' };
    worksheet.getColumn(6).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(6).width = 27.86;
    worksheet.getColumn(6).alignment = { horizontal: 'center' };
    worksheet.getColumn(7).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(7).width = 13.71;
    worksheet.getColumn(7).alignment = { horizontal: 'center' };
    worksheet.getColumn(8).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(8).width = 14.43;
    worksheet.getColumn(8).alignment = { horizontal: 'center' };
    worksheet.getColumn(9).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(9).width = 14.43;
    worksheet.getColumn(9).alignment = { horizontal: 'center' };
    worksheet.getColumn(10).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(10).width = 12.57;
    worksheet.getColumn(10).alignment = { horizontal: 'center' };
    worksheet.getColumn(11).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(11).width = 12.57;
    worksheet.getColumn(11).alignment = { horizontal: 'center' };
    worksheet.getColumn(12).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(12).width = 12.57;
    worksheet.getColumn(12).alignment = { horizontal: 'center' };
    worksheet.getColumn(13).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(13).width = 12.57;
    worksheet.getColumn(13).alignment = { horizontal: 'center' };
    worksheet.getColumn(14).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(14).width = 12.57;
    worksheet.getColumn(14).alignment = { horizontal: 'center' };
    worksheet.getColumn(15).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(15).width = 12.57;
    worksheet.getColumn(15).alignment = { horizontal: 'center' };
    worksheet.getColumn(16).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(16).width = 12.57;
    worksheet.getColumn(16).alignment = { horizontal: 'center' };
    worksheet.getColumn(17).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(17).width = 12.57;
    worksheet.getColumn(17).alignment = { horizontal: 'center' };
    worksheet.getColumn(18).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(18).width = 46.86;
    worksheet.getColumn(18).alignment = { horizontal: 'center' };
    worksheet.getColumn(19).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(19).width = 18.29;
    worksheet.getColumn(19).alignment = { horizontal: 'center', wrapText:true };
    worksheet.getColumn(20).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(20).width = 18.29;
    worksheet.getColumn(20).alignment = { horizontal: 'center' };
    worksheet.getColumn(21).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(21).width = 32;
    worksheet.getColumn(21).alignment = { horizontal: 'center' };
    worksheet.getColumn(22).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(22).width = 12.57;
    worksheet.getColumn(22).alignment = { horizontal: 'center' };
    worksheet.getColumn(23).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(23).width = 12.57;
    worksheet.getColumn(23).alignment = { horizontal: 'center' };
    worksheet.getColumn(24).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(24).width = 12.57;
    worksheet.getColumn(24).alignment = { horizontal: 'center' };
    worksheet.getColumn(25).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(25).width = 12.57;
    worksheet.getColumn(25).alignment = { horizontal: 'center' };
    worksheet.getColumn(26).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(26).width = 12.57;
    worksheet.getColumn(26).alignment = { horizontal: 'center' };
    worksheet.getColumn(27).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(27).width = 37.29;
    worksheet.getColumn(27).alignment = { horizontal: 'center' };
    worksheet.getColumn(28).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(28).width = 37.29;
    worksheet.getColumn(28).alignment = { horizontal: 'center' };
    worksheet.getColumn(29).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(29).width = 18.29;
    worksheet.getColumn(29).alignment = { horizontal: 'center' };

    worksheet.getColumn(2).style.numFmt = 'DD/MM/YYYY HH:mm:ss';
    worksheet.getColumn(10).style.numFmt = '[HH]:mm:ss';
    worksheet.getColumn(11).style.numFmt = '[HH]:mm:ss';
    worksheet.getColumn(12).style.numFmt = '[HH]:mm:ss';
    worksheet.getColumn(13).style.numFmt = '[HH]:mm:ss';
    worksheet.getColumn(14).style.numFmt = '[HH]:mm:ss';
    worksheet.getColumn(15).style.numFmt = '[HH]:mm:ss';
    worksheet.getColumn(16).style.numFmt = '[HH]:mm:ss';
    worksheet.getColumn(17).style.numFmt = '[HH]:mm:ss';
    
    var row = worksheet.addRow(['']);
    row.height = 20;
    var row = worksheet.addRow(['DETALLE INTERACCIONES (AGENTE) - ( ' + this.formatDate(from) + ' ' + this.formatHour(from) + ' a ' + this.formatDate(to) + ' ' + this.formatHour(to) + ' )']);
    row.getCell(1).font = {name: 'Calibri Light', size: 10, bold: true};
    row.getCell(1).alignment = { horizontal: 'left', vertical: 'top' };
    row.height = 20;
    var row = worksheet.addRow(['']);
    row.height = 10;

    worksheet.mergeCells('A1:AF1');
    worksheet.mergeCells('A2:AF2');
    worksheet.mergeCells('A3:AF3');

    var row = worksheet.addRow(headers);
    row.height = 18;
    row.font = {name: 'Tahoma', size: 7, bold: true};
    row.alignment = { horizontal: 'center', vertical: 'top' };

    info.forEach(d => this.addRow(d, from, anis, contrato, worksheet));
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }

  public formatDate(date: Date) : string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
  }
  public formatHour(date: Date) : string {
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();

    if (hour.length < 2) 
      hour = '0' + hour;
    if (minute.length < 2) 
      minute = '0' + minute;
    if (second.length < 2) 
      second = '0' + second;

    return [hour, minute, second].join(':');
  }

  private async addRow(info : InteractionsDetail, from: Date, anis: string[], contratos: string[], worksheet: Worksheet) {
    var inserted = false;

    if(info.inicioDate.getTime() < from.getTime())
      return;

    var tmpFrom = new Date(info.inicioDate);
    tmpFrom.setHours(tmpFrom.getHours() - 3);

    if(!inserted && anis !== undefined) {
      anis.forEach(ani => {
        if(info.cliente && info.cliente.includes(ani)) {
          worksheet.addRow([info.agente, tmpFrom, info.conversationId, info.segmento, info.tipoContacto, info.agenteCliente, info.cliente, info.dnis, info.sentido, info.duracionTime, info.previewTime, info.dialingTime, info.ringingTime, info.talkingTimeTime, info.holdTime, info.acwTime, info.enColaTime, info.resultadoGestion, info.nota, info.contrato, info.equipo, info.entrante, info.derivada, info.abandonada, info.transferIn, info.transferOut, info.empresas, info.campania, info.origenCorte]);
          inserted = true;
        }
      });
    }

    if(!inserted && contratos !== undefined) {
      contratos.forEach(contrato => {
        if(info.contrato && info.contrato.includes(contrato)) {
          worksheet.addRow([info.agente, tmpFrom, info.conversationId, info.segmento, info.tipoContacto, info.agenteCliente, info.cliente, info.dnis, info.sentido, info.duracionTime, info.previewTime, info.dialingTime, info.ringingTime, info.talkingTimeTime, info.holdTime, info.acwTime, info.enColaTime, info.resultadoGestion, info.nota, info.contrato, info.equipo, info.entrante, info.derivada, info.abandonada, info.transferIn, info.transferOut, info.empresas, info.campania, info.origenCorte]);
          inserted = true;
        }
      });
    }

    if(!inserted && (anis === undefined || anis.length == 0) && (contratos === undefined || contratos.length == 0)) {
      worksheet.addRow([info.agente, tmpFrom, info.conversationId, info.segmento, info.tipoContacto, info.agenteCliente, info.cliente, info.dnis, info.sentido, info.duracionTime, info.previewTime, info.dialingTime, info.ringingTime, info.talkingTimeTime, info.holdTime, info.acwTime, info.enColaTime, info.resultadoGestion, info.nota, info.contrato, info.equipo, info.entrante, info.derivada, info.abandonada, info.transferIn, info.transferOut, info.empresas, info.campania, info.origenCorte]);
    }
  }
}
