import { Injectable } from '@angular/core';
import { Workbook, Worksheet , ValueType} from 'exceljs';
import * as fs from 'file-saver';
import { PollReport } from 'src/app/models/polls/pollReport';

@Injectable({
  providedIn: 'root'
})
export class PollsExcelService {

  constructor() { }
  exportExcel(report : PollReport[], from: Date, to: Date) {
    var headers = ['Category', 'División', 'Resolucion', 'Resolución Q1', 'Resolución Q2', 'Resolución Q3', 'Calidad Atención', 'Calidad Atención %', 'Satisfacción General', 'Satisfacción General %', 'Llamados Manejados', 'Transferidas Encuestas'];
    var name = 'reporte_encuestas';
    var fila = 0;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reporte');

    worksheet.getColumn(1).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(1).width = 25;
    worksheet.getColumn(1).alignment = { horizontal: 'center' };
    worksheet.getColumn(2).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(2).alignment = { horizontal: 'center' };
    worksheet.getColumn(3).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(3).width = 18;
    worksheet.getColumn(3).alignment = { horizontal: 'center' };
    worksheet.getColumn(4).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(4).width = 18;
    worksheet.getColumn(4).alignment = { horizontal: 'center' };
    worksheet.getColumn(5).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(5).width = 18;
    worksheet.getColumn(5).alignment = { horizontal: 'center' };
    worksheet.getColumn(6).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(6).width = 18;
    worksheet.getColumn(6).alignment = { horizontal: 'center' };
    worksheet.getColumn(7).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(7).width = 18;
    worksheet.getColumn(7).alignment = { horizontal: 'center' };
    worksheet.getColumn(8).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(8).width = 18;
    worksheet.getColumn(8).alignment = { horizontal: 'center' };
    worksheet.getColumn(9).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(9).width = 18;
    worksheet.getColumn(9).alignment = { horizontal: 'center' };
    worksheet.getColumn(10).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(10).width = 18;
    worksheet.getColumn(10).alignment = { horizontal: 'center' };
    worksheet.getColumn(11).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(11).width = 18;
    worksheet.getColumn(11).alignment = { horizontal: 'center' };
    worksheet.getColumn(12).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(12).width = 18;
    worksheet.getColumn(12).alignment = { horizontal: 'center' };
    
    var row = worksheet.addRow(['']);
    row.height = 20;
    var row = worksheet.addRow(['REPORTE DE ENCUESTAS - ( ' + this.formatDate(from) + ' ' + this.formatHour(from) + ' a ' + this.formatDate(to) + ' ' + this.formatHour(to) + ' )']);
    row.getCell(1).font = {name: 'Calibri Light', size: 10, bold: true};
    row.getCell(1).alignment = { horizontal: 'left', vertical: 'top' };
    row.height = 20;
    var row = worksheet.addRow(['']);
    row.height = 10;

    worksheet.mergeCells('A1:J1');
    worksheet.mergeCells('A2:J2');
    worksheet.mergeCells('A3:J3');

    var row = worksheet.addRow(headers);
    row.getCell(1).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(1).alignment = { horizontal: 'right' };
      row.getCell(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };
   
      row.getCell(2).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(2).alignment = { horizontal: 'right' };
      row.getCell(2).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };
  
      row.getCell(3).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(3).alignment = { horizontal: 'right' };
      row.getCell(3).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };

      row.getCell(4).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(4).alignment = { horizontal: 'right' };
      row.getCell(4).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };
 
      row.getCell(5).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(5).alignment = { horizontal: 'right' };
      row.getCell(5).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };

      row.getCell(6).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(6).alignment = { horizontal: 'right' };
      row.getCell(6).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };

      row.getCell(7).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(7).alignment = { horizontal: 'right' };
      row.getCell(7).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };

      row.getCell(8).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(8).alignment = { horizontal: 'right' };
      row.getCell(8).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };

      row.getCell(9).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(9).alignment = { horizontal: 'right' };
      row.getCell(9).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };

      row.getCell(10).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(10).alignment = { horizontal: 'right' };
      row.getCell(10).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };
  
      row.getCell(11).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(11).alignment = { horizontal: 'right' };
      row.getCell(11).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };
  
      row.getCell(12).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(12).alignment = { horizontal: 'right' };
      row.getCell(12).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };
  
 
    row.height = 18;
    row.font = {name: 'Tahoma', size: 7, bold: true};
    row.alignment = { horizontal: 'center', vertical: 'top' };

    report.forEach(d => {
      worksheet.addRow([d.agent, d.poll, d.resolution, d.resolution1, d.resolution2, d.resolution3, d.quality, d.qualityAverage, d.general, d.generalAverage, d.total, d.transferred])
    });
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }

  public formatDate(date: Date) : string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
  }
  public formatHour(date: Date) : string {
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();

    if (hour.length < 2) 
      hour = '0' + hour;
    if (minute.length < 2) 
      minute = '0' + minute;
    if (second.length < 2) 
      second = '0' + second;

    return [hour, minute, second].join(':');
  }

}
