<div class="form-group">
    <div class="center">
        <div ng-class="pointer-events: none;">
            <mat-form-field>
                <input matInput [ngxMatDatetimePicker]="pickerFrom" placeholder="Desde*" [(ngModel)]="myDatePickerFrom">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerFrom [showSeconds]="true">
                </ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field>
                <input matInput [ngxMatDatetimePicker]="pickerTo" placeholder="Hasta*" [(ngModel)]="myDatePickerTo"
                    [min]="myDatePickerFrom">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerTo [showSeconds]="true">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="center">
        <div class="example-button-row">
            <button mat-button (click)="onRangeDate('TODAY')">Hoy</button>
            <button mat-button (click)="onRangeDate('YESTERDAY')">Ayer</button>
            <button mat-button (click)="onRangeDate('LAST_7_DAYS')">Últimos 7 días</button>
            <button mat-button (click)="onRangeDate('THIS_WEEK')">Esta semana</button>
            <button mat-button (click)="onRangeDate('PREVIOUS_WEEK')">Anterior semana</button>
            <button mat-button (click)="onRangeDate('LAST_31_DAYS')">Últimos 31 días</button>
          </div>
    </div>
    <div class="center">
        <div class="duallistbox-dummy2">
            <select name="campaigns-dummy" class="duallistbox-campaign-accumulators-dummy" multiple>
                <option *ngFor="let n of campaignDummy" [value]="n.id">{{n.name}}</option>
            </select>
        </div>
    </div>
    <div class="center">
        <div class="duallistbox-custom">
            <select name="campaigns" class="duallistbox-campaign-accumulators" multiple hidden>
                <option *ngFor="let n of allCampaigns" [value]="n.id">{{n.name}}</option>
            </select>
        </div>
    </div>
    <div class="center">
        <section class="checkbox-section">
            <mat-checkbox class="checkbox-margin" color="primary" [(ngModel)]="inbound">Entrante</mat-checkbox>
            <mat-checkbox class="checkbox-margin" color="primary" [(ngModel)]="outbound">Saliente</mat-checkbox>
        </section>
    </div>
</div>
<button [disabled]="!myDatePickerFrom || !myDatePickerTo" id="generate-interactions-detail" type="button"
    class="btn btn-block btn-secondary" (click)="onGenerateCampaignAccumulators()">Generar</button>

<app-script-hack type="text/javascript">
    <![CDATA[
        duallistboxCampaignAccumulatorsDummy = $('.duallistbox-campaign-accumulators-dummy').bootstrapDualListbox({
                moveOnSelect: false,
                infoTextEmpty: 'Lista vacia',
                infoText: false,
                filterPlaceHolder: 'Filtrar por campaña',
                moveSelectedLabel: 'Mover soleccionado',
                moveAllLabel: 'Mover todos',
                removeSelectedLabel: 'Borrar seleccionado',
                removeAllLabel: 'Borrar todos',
                infoTextFiltered: 'Filtrado {0} de {1}',
                filterTextClear: 'Mostrar todo'
            });
        duallistboxCampaignAccumulators = $('.duallistbox-campaign-accumulators');
    ]]>
</app-script-hack>
