import { Injectable } from "@angular/core";
import { AgentLoginCloudService } from "./agent-login-cloud.service";
import * as platformClient from "purecloud-platform-client-v2";
import { AgentLogin } from "src/app/models/agent-login/agent-login";
import { DateTime } from "src/app/utils/date-time";
import { Agent } from "src/app/models/interactions-detail/agent";

@Injectable({
  providedIn: "root",
})
export class AgentLoginService {
  constructor(private agentLoginCloudService: AgentLoginCloudService) {}

  public processUsers(from: Date, to: Date, response: Object[]) {
    return new Promise((resolve, reject) =>
      this.processInternalUsers(from, to, response, resolve, reject)
    );
  }

  private processInternalUsers(from: Date, to: Date, response: Object[], resolve, reject) {
    this.agentLoginCloudService
      .getUsersDetails(from, to)
      .then((usersDetails: platformClient.Models.AnalyticsUserDetail[]) => {
        //"primaryPresence": [ OFFLINE AVAILABLE ON_QUEUE TRAINING MEETING BUSSY BREAK MEAL IDLE AWAY
        var agentsData = response[0];
        var agentes: Agent[] = response[0]["agentes"];

        for (var i = 0; i < usersDetails.length; i++) {
          var userDetail = usersDetails[i];
          var agent: Agent = this.findAgent(agentes, userDetail.userId);
          if (agent !== null) {
             if (agentsData[agent.name] === undefined) {
              agentsData[agent.name] = [];
            }
            var agentData: AgentLogin[] = agentsData[agent.name];
            if( agentData.length === 0) agentData.push(new AgentLogin());

            for (var j = 0; userDetail.primaryPresence && j < userDetail.primaryPresence.length; j++) {
              var log: AgentLogin = agentData[ agentData.length-1 ];
              var primaryPresence = userDetail.primaryPresence[j];
              console.log('primaryPresence.startTime es: ', primaryPresence.startTime, '\n primaryPresence.endTime es: ', primaryPresence.endTime); //!Borrar esto
              var inicioPP = DateTime.convertStringToDate(primaryPresence.startTime).getTime() - 3 * 60 * 60 * 1000;
              var finalPP: number = ( primaryPresence.endTime !== undefined ) ? DateTime.convertStringToDate(primaryPresence.endTime).getTime() - 3 * 60 * 60 * 1000 : new Date().getTime();
              var tPP = finalPP - inicioPP;
              if( primaryPresence.systemPresence === 'OFFLINE' ){
                if( 0 < log.tiempo ){
                  log.terminado = true;
                  agentData.push( new AgentLogin() );
                }
              }
              else{ // es != a offline
                if( log.tiempo <= 0 ){
                  log.id = agent.id;
                  log.nombreAgente = agent.name;
                  log.fecha = inicioPP;
                  log.logIn = inicioPP;
                }
                log.logOut = finalPP;
                log.tiempo = log.tiempo + tPP;
              }


            }
          }
        }

        resolve(response);
      })
      .catch((error) => reject(error));
  }

  private findAgent(agentes: Agent[], id: string): Agent {
    var res: Agent = null;
    agentes.forEach((agente) => {
      res = agente.id === id ? (res = agente) : (res = res);
    });
    return res;
  }
}
