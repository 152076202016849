import { NumberValueAccessor } from "@angular/forms";

export class PollReport {
    agent: string;
    poll: string;
    resolution: number;
    resolutionAverage: number;
    resolution1: number;
    resolution2: number;
    resolution3: number;
    quality: number;
    qualityAverage: number;
    general: number;
    generalAverage: number;
    total: number;
    transferred: number;
}