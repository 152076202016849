import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { AbandonedDetail } from 'src/app/models/abandoned-interactions-detail/abandoned-detail';

@Injectable({
  providedIn: 'root'
})
export class AbandonedInteractionsDetailExcelService {

  constructor() { }

  exportExcel(abandonedInteractions: AbandonedDetail[], from: Date, to: Date) {
    var headers = ['IDINTERACCION', 'SEGMENTO', 'FECHA', 'CAMPAÑA', 'TIEMPO EN COLA', 'CLIENTE'];
    var name = 'reporte_interacciones_abandonadas';

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reporte');

    worksheet.getColumn(1).font = { name: 'Calibri', size: 11 };
    worksheet.getColumn(1).width = 45;
    worksheet.getColumn(1).alignment = { horizontal: 'center' };
    worksheet.getColumn(2).font = { name: 'Calibri', size: 11 };
    worksheet.getColumn(2).width = 10;
    worksheet.getColumn(2).alignment = { horizontal: 'center' };
    worksheet.getColumn(3).font = { name: 'Calibri', size: 11 };
    worksheet.getColumn(3).width = 24;
    worksheet.getColumn(3).alignment = { horizontal: 'center' };
    worksheet.getColumn(3).style.numFmt = 'DD/MM/YYYY HH:mm:ss';
    worksheet.getColumn(4).font = { name: 'Calibri', size: 11 };
    worksheet.getColumn(4).width = 24;
    worksheet.getColumn(4).alignment = { horizontal: 'center' };
    worksheet.getColumn(5).font = { name: 'Calibri', size: 11 };
    worksheet.getColumn(5).width = 24;
    worksheet.getColumn(5).alignment = { horizontal: 'center' };
    worksheet.getColumn(5).style.numFmt = '[HH]:mm:ss';
    worksheet.getColumn(6).font = { name: 'Calibri', size: 11 };
    worksheet.getColumn(6).width = 24;
    worksheet.getColumn(6).alignment = { horizontal: 'center' };


    

    var row = worksheet.addRow(['']);
    var row = worksheet.addRow(['FILTROS - ( ' + this.formatDate(from) + ' ' + this.formatHour(from) + ' a ' + this.formatDate(to) + ' ' + this.formatHour(to) + ' )']);
    row.getCell(1).font = {name: 'Calibri Light', size: 10, bold: true};
    row.getCell(1).alignment = { horizontal: 'left', vertical: 'top' };

    var row = worksheet.addRow(['']);
    var row = worksheet.addRow(headers);
    row.getCell(1).border = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };
    row.getCell(1).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'000080'}
    };
    row.getCell(2).border = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };
    row.getCell(2).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'000080'}
    };
    row.getCell(3).border = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };
    row.getCell(3).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'000080'}
    };
    row.getCell(4).border = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };
    row.getCell(4).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'000080'}
    };
    row.getCell(5).border = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };
    row.getCell(5).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'000080'}
    };
    row.getCell(6).border = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };
    row.getCell(6).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'000080'}
    };

    abandonedInteractions.forEach(interaction => {
      var row = worksheet.addRow([interaction.idInteraccion, interaction.segmento, interaction.fecha, interaction.campania, this.adapterHours(interaction.tiempo), this.adapterPhone(interaction.cliente)]);
      row.getCell(1).border = {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
      row.getCell(2).border = {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
      row.getCell(3).border = {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
      row.getCell(4).border = {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
      row.getCell(5).border = {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
      row.getCell(6).border = {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
      };
     // var row = worksheet.addRow([interaction.['idInteraccion'], interaction['segmento'], interaction['fecha'], interaction['campania'], interaction['tiempo'], interaction['cliente']]);
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }

  public formatDate(date: Date): string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('/');
  }
  public formatHour(date: Date): string {
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();

    if (hour.length < 2)
      hour = '0' + hour;
    if (minute.length < 2)
      minute = '0' + minute;
    if (second.length < 2)
      second = '0' + second;

    return [hour, minute, second].join(':');
  }

  private adapterHours(time : number) : Date {
    var date = new Date();
    date.setTime(0);
    date.setDate(-25538);
    date.setHours(date.getHours(), -75, -108, Math.floor(time/1000)*1000)
    return date;
  }

  private adapterPhone(number : string) : string {
    if(number === undefined) {
      return number;
    }
    
    if(number.startsWith('tel:+540')) {
      return number.substr(8);
    }

    if(number.startsWith('tel:+54')) {
      return number.substr(7);
    }

    if(number.startsWith('tel:540')) {
      return number.substr(7);
    }

    if(number.startsWith('tel:54')) {
      return number.substr(6);
    }

    if(number.startsWith('tel:0')) {
      return number.substr(5);
    }

    if(number.startsWith('tel:')) {
      return number.substr(4);
    }

    if(number.startsWith('sip:Anonymous')) {
      return 'Anónimo';
    }

    return number;
  }

  private adapterDate(date : string) : Date {
    var year = parseInt(date.substring(0, 4));
    var month = parseInt(date.substring(5, 7)) - 1;
    var day = parseInt(date.substring(8, 10));
    var hours = parseInt(date.substring(11, 13)) - 3;
    var minutes = parseInt(date.substring(14, 16));
    var seconds = parseInt(date.substring(17, 19));
    var milliseconds = 0;
    if(date.indexOf('.') >= 0)
      milliseconds = parseInt(date.substring(20, date.length - 1));
    
		return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }
}
