import { Injectable, ReflectiveInjector } from '@angular/core';
import { AgentsLogsCloudService } from './agents-logs-cloud.service';
import * as platformClient from 'purecloud-platform-client-v2';
import { Agent } from 'src/app/models/interactions-detail/agent';
import { AgentLog }  from 'src/app/models/agents-logs/agents-logs';
import { DateTime } from 'src/app/utils/date-time';
import { PurecloudService } from '../purecloud/purecloud.service';
import { ConversationDataPurecloudService } from '../purecloud/conversation-data-purecloud.service';
import { TimeRange } from '../purecloud/time-range';
import { MapConversation } from 'src/app/utils/map-conversation';
import { Conversation, Participant } from 'src/app/models/purecloud/conversation';

@Injectable({
  providedIn: 'root'
})
export class AgentsLogsService extends ConversationDataPurecloudService {

  constructor(private pureCloudService: PurecloudService, private agentLogsCloudService: AgentsLogsCloudService) {
    super(pureCloudService);
   }

  public processUsers(from: Date, to: Date, response: Object[]){
    return new Promise((resolve, reject) =>
      this.processInternalUsers(from, to, response, resolve, reject)
    );
  }

  private processInternalUsers(from: Date, to: Date, response: Object[], resolve, reject){
    this.agentLogsCloudService.getUsersDetails(from, to)
    .then((usersDetails: platformClient.Models.AnalyticsUserDetail[]) => {
      var agentes: Agent[] = response[0]['agentes'];
      var agentsLogs = response[0]; // agentsLogs['Carlos']: Array<AgentLog>
      var agentRoutingLogs = response[1]; // agentRoutingLogs['Carlos']: Array<AgentLog>

      for(var i = 0; usersDetails !== undefined && i < usersDetails.length; i++){//aca revisamos las "paginas"
        var user = usersDetails[i];
        var agent: Agent = this.findAgent(agentes, user.userId);

        //? bloque userDetails
        if( agent !== null ){
          //"primaryPresence": [ OFFLINE AVAILABLE ON_QUEUE TRAINING MEETING BUSSY BREAK MEAL IDLE AWAY
          var flagDeLogsAnteriorExistentes: boolean = false;
          if(agentsLogs[agent.name] === undefined){
            agentsLogs[agent.name] = [];
          }
          else{
            flagDeLogsAnteriorExistentes = true; // si esta definido, hay un Log[] con un estado que tenemos que enlazar
          }
          var agentData: AgentLog[] = agentsLogs[agent.name];

          var estado = '-';
          var fecha: Date = new Date(0);
          var fechaEnd: Date = new Date();
          var tiempo = 0; //ms
          var interacciones = 0;
          if( user.primaryPresence !== undefined ){
            for(var j = 0; j < user.primaryPresence.length; j++){
              var primaryPresence = user.primaryPresence[j];
              var log: AgentLog = new AgentLog();
              //? Primero seteamos todo lo viejo
              log.id = agent.id;
              log.nombreAgente = agent.name;
              log.fecha = fecha.getTime() - 3 * 60 * 60 * 1000; // pureCloud esta adelantado 3 hs, las sacamos para que en el reporte esten en zona horario de Arg
              if(estado === "ON_QUEUE"){
                log.fechaEnd = fechaEnd.getTime() - 3 * 60 * 60 * 1000;
              }
              log.estadoAnterior = estado;
              log.tiempo = tiempo;
              log.interacciones = interacciones;
              //? Ahora actualizamos los datos
              estado = primaryPresence.systemPresence;
              log.estadoNuevo = estado; // Nos faltaba actualizar los datos para poder setear esto

              if( flagDeLogsAnteriorExistentes ){
                var ultimoLog: AgentLog = agentData[agentData.length-1];
                ultimoLog.estadoNuevo = estado;
                flagDeLogsAnteriorExistentes = false;
              }

              if( this.logCumplecondiciones(from, to, log)){
                agentData.push(log);
              }
              fecha = DateTime.convertStringToDate(primaryPresence.startTime);

              //todo, si el endtime es undefined le damos el dia de hoy
              fechaEnd = (primaryPresence.endTime !== undefined)? DateTime.convertStringToDate(primaryPresence.endTime) : new Date();
              tiempo = fechaEnd.getTime() - fecha.getTime();

              if(tiempo < 0){
                tiempo = 0;
              }

              if(primaryPresence.systemPresence === 'ON_QUEUE'){
                interacciones = this.countInteractions(usersDetails, agent, i, primaryPresence.startTime, primaryPresence.endTime);
              }else{
                interacciones = 0;
              }
            }
            //! Se actualiza el ultimo primary pero no se agrega porque termina el for, hacemos un semi-ciclo mas a mano
            var log: AgentLog = new AgentLog();
            log.id = agent.id;
            log.nombreAgente = agent.name;
            log.fecha = fecha.getTime() - 3 * 60 * 60 * 1000;
            if(estado === "ON_QUEUE"){
              log.fechaEnd = fechaEnd.getTime() - 3 * 60 * 60 * 1000;
            }
            log.estadoAnterior = estado;
            log.tiempo = tiempo;
            log.interacciones = interacciones;
            log.estadoNuevo = '-';
            agentData.push(log);
          }
        }

        //? bloque routingStatus
        if( agent !== null){
          var flagLogAnteriorExiste: boolean = false;
          if(agentRoutingLogs[agent.name] === undefined){
            agentRoutingLogs[agent.name] = [];
          }
          else{
            flagLogAnteriorExiste = true; // si esta definido, hay un Log[] con un estado que tenemos que enlazar
          }
          var agentDataRt: AgentLog[] = agentRoutingLogs[agent.name];

          if( user.routingStatus !== undefined ){
            for(var l = 0; l < user.routingStatus.length; l++ ){
              var rStatus = user.routingStatus[l];
              var logRt: AgentLog = new AgentLog();

              logRt.fecha = DateTime.convertStringToDate(rStatus.startTime).getTime() - 3 * 60 * 60 * 1000;
              //logRt.fechaEnd = (rStatus.endTime !== undefined)? DateTime.convertStringToDate(rStatus.endTime).getTime() - 3 * 60 * 60 * 1000 : new Date().getTime();
              if(rStatus.endTime !== undefined){
                logRt.fechaEnd = DateTime.convertStringToDate(rStatus.endTime).getTime() - 3 * 60 * 60 * 1000;
              }else{
                logRt.fechaEnd = new Date().getTime();
              }
              logRt.tiempo = logRt.fechaEnd - logRt.fecha;
              logRt.id = agent.id;
              logRt.nombreAgente = agent.name;
              logRt.estadoAnterior = rStatus.routingStatus;
              logRt.estadoNuevo = ( l+1 < user.routingStatus.length ) ? logRt.estadoNuevo = user.routingStatus[l+1].routingStatus : logRt.estadoNuevo = '-';

              if( flagLogAnteriorExiste && 0 < agentDataRt.length  ){
                agentDataRt[agentDataRt.length-1].estadoNuevo = rStatus.routingStatus;
                flagLogAnteriorExiste = false;
              }
              agentDataRt.push(logRt);

            }

          }
        }
      }

      console.log('response en 0: ', response[0]);//!Borrar esto
      console.log('response en 1: ', response[1]);//!Borrar esto

      resolve(response);
    })
    .catch((error) => reject(error));
  }

  private logCumplecondiciones(from: Date, to: Date, log: AgentLog): boolean{
    var res = true;
    if( log.estadoAnterior === '-' ){
      res = false;
    }
    else if ( !( from.getTime() <= log.fecha && log.fecha <= to.getTime() )){
      res = false;
    }

    return res;
  }

  private findAgent(agentes: Agent[], id: string): Agent{
    var res: Agent = null;
    agentes.forEach( (agente) => {
      res = ( agente.id === id ) ? res = agente: res = res;
    });
    return res;
  }

  private countInteractions(usersDetails: platformClient.Models.AnalyticsUserDetail[], agent: Agent, index:number, startTime, endTime): number{
    var res = 0;
    //"routingStatus": [ OFF_QUEUE IDLE INTERACTING NOT_RESPONDING
    var from: Date = DateTime.convertStringToDate(startTime);
    var to: Date = (endTime !== undefined) ? to = DateTime.convertStringToDate(endTime) : to = new Date();

    var encontrado = false;
    var contador = 2;
    for(var i = index; usersDetails.length !== undefined &&  i < usersDetails.length; i++){
      var user = usersDetails[i];
      if( user.userId === agent.id ){
        var routingStatus = user.routingStatus;
        for(var j = 0; routingStatus !== undefined && j < routingStatus.length; j++){
          var routSegment = routingStatus[j];
          var routSegmentStart = DateTime.convertStringToDate(routSegment.startTime);
          if( from.getTime() <= routSegmentStart.getTime() && routSegmentStart.getTime() <= to.getTime() ){
            res++;
          }
        }
        encontrado = true;
        contador = 2;
      }
      if( encontrado ) contador--;
      if( contador === 0 ) break;

    }

    return res;
  }

  private itsBreakTime(user: platformClient.Models.AnalyticsUserDetail, to: Date): boolean{
    var res = false;
    var newestDate: Date = new Date(0);
    var primaryPresence = user.primaryPresence;
    var routStatus = user.routingStatus;

    if( primaryPresence !== undefined && primaryPresence.length >= 0){
      var primFirstDate: Date = DateTime.convertStringToDate(primaryPresence[0].startTime);
      newestDate = ( newestDate.getTime() <= primFirstDate.getTime() ) ? newestDate = primFirstDate : newestDate;
    }
    if( routStatus !== undefined && routStatus.length >= 0){
      var routFirstDate: Date = DateTime.convertStringToDate(routStatus[0].endTime);
      newestDate = ( newestDate.getTime() <= routFirstDate.getTime() ) ? newestDate = routFirstDate : newestDate;
    }

    res = to.getTime() < newestDate.getTime();

    return res;
  }

  protected processInternalCall(conversation: platformClient.Models.AnalyticsConversation, timeRange: TimeRange, calls: Object[]) {
    var from = DateTime.convertStringToDate(conversation.conversationStart);
    if (from.getTime() >= timeRange.fromParcial.getTime()) {
      this.processIntervalCall(MapConversation.analyticsConversation(conversation), from, calls);
    }
  }

  public processCall(conversation: Conversation, calls: Object[]) {}

  public processIntervalCall(conversation: Conversation, from: Date, calls: Object[] ) {
    if( calls.length === 0){  //??
      calls.push({});
    }
    var response = calls[0];
    var participants = conversation.participants;
    for(var i = 0; i < participants.length; i++){
      var participant: Participant = participants[i];

      if(participant.purpose === 'agent'){
        if(response[participant.userId] === undefined){
          response[participant.userId] = {};
        }
        for(var j = 0; j < participant.sessions.length; j++){
          var session = participant.sessions[j];
          var agentLogs: AgentLog[] = new Array();
          agentLogs = [];
          var key: Date = undefined;
          var interactuo: boolean = false;
          for(var k = 0; k < session.segments.length; k++){
            var segment = session.segments[k];
            key = ( key === undefined ) ? key = DateTime.convertStringToDate(segment.segmentStart) : key;
            var agentLog: AgentLog = new AgentLog();
            agentLog.estadoAnterior = segment.segmentType;
            agentLog.id = participant.userId;
            interactuo = ( segment.segmentType === 'interact' ) ? true : interactuo;
            agentLog.nombreAgente = participant.userId;// A DEFINIR dejar el id y despues poner el nombnre, o hacer una funcion que recupere los Agent's del component
            var fecha = DateTime.convertStringToDate(segment.segmentStart);
            var fechaEnd = (segment.segmentEnd !== undefined)? DateTime.convertStringToDate(segment.segmentEnd) : new Date();
            agentLog.fecha = fecha.getTime() - 3 * 60 * 60 * 1000;
            agentLog.tiempo = fechaEnd.getTime() - fecha.getTime();
            agentLog.estadoNuevo = (k + 1 < session.segments.length) ? session.segments[k+1].segmentType : '-';

            agentLogs.push(agentLog);
          }
          if(interactuo){
            agentLogs.forEach( (log) => {
              log.interacciones += 1;
            });
          }
          response[participant.userId][key.getTime() - 3 * 60 * 60 * 1000] = agentLogs;
        }

      }
    }

  }


}
