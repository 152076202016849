export class CampaignAccumulators {
    disparadas:number = 0;
    noEjecutadas:number = 0;
    noContacto:number = 0;
    contactadas:number = 0;
    ncOcupadas:number = 0;
    ncNoContesta:number = 0;
    ncOtros:number = 0;
    ncModemFax:number = 0;
    ncContestador:number = 0;
    ncNoHablo:number = 0;

    ingresadas:number = 0;
    derivadas:number = 0;
    abandonadas:number = 0;
    agentesAtendidas:number = 0;
    agentesAtendidasBool:boolean = false;
    agentesAbandonadas:number = 0;
    transferOut:number = 0;
    transferOutBool:boolean = false;
    enCola:number = 0;
    login:number = 0;
    avail:number = 0;
    ring:number = 0;
    connect:number = 0;
    hold:number = 0;
    acw:number = 0;
    notReady:number = 0;
    break:number = 0;
    estadoAvailable:number = 0;
    estadoTraining:number = 0;
    estadoMeeting:number = 0;
    estadoBussy:number = 0;
    estadoMeal:number = 0;
    estadoIdle:number = 0;
    estadoAway:number = 0;
    auxiliarTotal:number = 0;
    auxiliarPorcentaje:number = 0;
    tiempoTotalLogueo:number = 0;
    utilizacion:number = 0;
    aht:number = 0;
    att:number = 0;
    ahtCount:number = 0;
    attCount:number = 0;
    rgExitoso:number = 0;
    rgExitosoBool:boolean = false;
    rgNoExitoso:number = 0;
    porcentajeCE:number = 0;
}
