import { Injectable } from '@angular/core';
import * as platformClient  from 'purecloud-platform-client-v2';
import { Conversation, Segment } from 'src/app/models/purecloud/conversation';
import { Task } from 'src/app/models/records-detail/task';
import { DateTime } from 'src/app/utils/date-time';
import { MapConversation } from 'src/app/utils/map-conversation';
import { ConversationDataPurecloudService } from '../purecloud/conversation-data-purecloud.service';
import { PurecloudService } from '../purecloud/purecloud.service';
import { TimeRange } from '../purecloud/time-range';

@Injectable({
  providedIn: 'root'
})
export class RecordsDetailDataService extends ConversationDataPurecloudService {
  constructor(private purecloudService : PurecloudService) {
    super(purecloudService);
  }

  protected processInternalCall(conversation: platformClient.Models.AnalyticsConversation, timeRange: TimeRange, calls: Object[]) {
    var from = DateTime.convertStringToDate(conversation.conversationStart);
    if(from.getTime() >= timeRange.fromParcial.getTime()) {
      this.processCall(MapConversation.analyticsConversation(conversation), calls);
    }
  }
  
  public processCall(conversation : Conversation, calls : Object[]) {
    var task = new Task();
    var ultLlamada: string ="";
    task.resultadoGestion="";
    task.estado ="";
    task.campania = conversation.participants[0].sessions[0].outboundCampaignId;
    task.idCliente = conversation.participants[0].sessions[0].outboundContactId;
    task.idTarea = conversation.conversationId;
    if(conversation.participants[0].sessions[0].dnis!== undefined){
    task.numeroLinea = conversation.participants[0].sessions[0].dnis.replace("tel:","");
    }
    task.tiposResultadosGestion = "No Exitoso";
    for(var i = 0; i < conversation.participants.length; i++) {
      var participant = conversation.participants[i];
      if(participant.purpose == "agent") {
        //task.agente = participant.userId;
        task.agente=participant.participantName;
      }
      if(participant.purpose == "customer" || participant.purpose == "external") {
        task.intentos += participant.sessions.length;
      }

      for(var j=0;j<participant.sessions.length ;j++){
        var seccion=participant.sessions[j];
        
        for(var k =0; k<seccion.segments.length;k++){
          var segement= seccion.segments[k];
          if(segement.segmentStart!==undefined){
             if(ultLlamada ==""||segement.segmentStart.localeCompare(ultLlamada) ==1){
              ultLlamada=segement.segmentStart;
            }
          }
          if(segement.wrapUpCode!==undefined){
            if(participant.purpose === 'agent') {
              task.resultadoGestion=segement.wrapUpCode;
            }
            if(task.ultimoWrapup === undefined){
              task.ultimoWrapupHora = DateTime.convertStringToDate(segement.segmentEnd);
              task.observaciones=segement.wrapUpNote;
            }
            else{
              var end= DateTime.convertStringToDate(segement.segmentEnd);
              if(end.getTime()>task.ultimoWrapupHora.getTime()){
                task.ultimoWrapupHora=DateTime.convertStringToDate(segement.segmentEnd);
                task.observaciones=segement.wrapUpNote;
              }
            }
            task.ultimoWrapup = segement.wrapUpCode;
            task.causaTerminacion = segement.wrapUpCode;
          }

        }
      }
    } 
    if (task.resultadoGestion !== undefined && task.resultadoGestion!==""){
      task.tiposResultadosGestion = "Exitoso";
    }
    else{
     task.resultadoGestion="N/A";
    }
    task.ultimaLLamada = DateTime.convertStringToDate(ultLlamada); 
    //task.ultimaLLamada=ultLlamada;
    calls.push(task);
  }

  public getSessionsCall(conversation : Conversation) {
    var sessions = {};
    for(var i = 0; i < conversation.participants.length; i++) {
      for(var j = 0; j < conversation.participants[i].sessions.length; j++) {
        if(conversation.participants[i].sessions[j].mediaType === 'callback') {
        }

        var id = undefined;
        if(conversation.participants[i].sessions[j].peerId === undefined) {
          id = conversation.participants[i].sessions[j].sessionId;
        } else {
          id = conversation.participants[i].sessions[j].peerId;
        }
        var segments = sessions[id];
        if(segments === undefined) {
          segments = [];
          sessions[id] = segments;
        }

        for(var k = 0; k < conversation.participants[i].sessions[j].segments.length; k++) {
          var segment = conversation.participants[i].sessions[j].segments[k];
          if(segment.wrapUpCode !== undefined) {
            segments.push(segment);
          }
        }
      }
    }
    return sessions;
  }

}
