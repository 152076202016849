export class InteractionsDetail {
    custom:boolean= true;
    agente:string;
    inicio:string;
    inicioDate:Date;
    conversationId:string;
    segmento:number;
    segmentoTipo:string;
    tipoContacto:string;
    agenteCliente:string;
    cliente:string;
    dnis:string;
    sentido:string;
    duracion:number;
    duracionStr:string;
    duracionTime:Date;
    preview:number;
    previewStr:string;
    previewTime:Date;
    dialing:number;
    dialingStr:string;
    dialingTime:Date;
    ringing:number;
    ringingStr:string;
    ringingTime:Date;
    talkingTime:number;
    talkingTimeStr:string;
    talkingTimeTime:Date;
    hold:number;
    holdStr:string;
    holdTime:Date;
    acw:number;
    acwStr:string;
    acwTime:Date;
    enCola:number;
    enColaStr:string;
    enColaTime:Date;
    resultadoGestion:string;
    nota:string;
    contrato:string;
    equipo:string;
    entrante:string;
    derivada:number;
    abandonada:number;
    transferIn:number;
    transferOut:number;
    empresas:string[];
    empresa:string;
    campania:string;
    origenCorte:string;
}