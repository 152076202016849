import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { Workbook, Worksheet , ValueType} from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CampaignWrapupAccumulatorsExcelService {

  constructor() { }
  exportExcel(info : Array<any>, from: Date, to: Date) {
    let workbook = new Workbook();
    var name = 'reporte_acumuladores_wrapup_campañas';

    let wrapUpCodes = info[0].wrapUpCodes;
    let wrapUpRows = info[0].wrapUpRows;

    this.exportExcelWrapUpCodes(from, to, workbook, wrapUpCodes);
    this.exportExcelWrapUpRows(from, to, workbook, wrapUpRows);

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }
  exportExcelWrapUpCodes(from: Date, to: Date, workbook:Workbook, wrapUpCodes:any) {

    var headers = ['Wrapup', 'Totales'];

    let worksheet = workbook.addWorksheet('Reporte');
    worksheet.getColumn(1).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(1).width = 58.27;
    worksheet.getColumn(1).alignment = { horizontal: 'left' };
    worksheet.getColumn(2).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(2).width = 23;
    worksheet.getColumn(2).alignment = { horizontal: 'right' };

    var row = worksheet.addRow(['']);
    row.height = 20;
    var row = worksheet.addRow(['Acumuladores de Wrapup para campañas - ( ' + this.formatDate(from) + ' ' + this.formatHour(from) + ' a ' + this.formatDate(to) + ' ' + this.formatHour(to) + ' )']);
    row.getCell(1).font = {name: 'Calibri Light', size: 10, bold: true};
    row.getCell(1).alignment = { horizontal: 'left', vertical: 'top' };
    row.height = 40;
    var row = worksheet.addRow(['']);
    row.height = 10;

    worksheet.mergeCells('A1:B1');
    worksheet.mergeCells('A2:B2');
    worksheet.mergeCells('A3:B3');

    var row = worksheet.addRow(headers);
    row.height = 18;
    row.font = {name: 'Tahoma', size: 7, bold: true};
    row.alignment = { vertical: 'top' };
    row.getCell(1).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(2).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };

    var counter = {
      title: 'Total general',
      count: 0,
      sum: 0
    };

    this.addRow(wrapUpCodes, counter, worksheet);
    var row = worksheet.addRow([counter.title, counter.sum]);
    row.height = 18;
    row.font = {name: 'Tahoma', size: 7, bold: true};
    row.getCell(1).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(2).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
  }

  exportExcelWrapUpRows(from: Date, to: Date, workbook:Workbook, wrapUpRows:any) {

    var headers = ['conversationId', 'conversationStart', 'conversationEnd', 'outboundCampaignId', 'outboundContatcListId', 'outboundContactId','wrapup'];

    let worksheet = workbook.addWorksheet('Reporte raw');
    worksheet.getColumn(1).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(1).width = 32;
    worksheet.getColumn(1).alignment = { horizontal: 'left' };
    worksheet.getColumn(2).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(2).width = 24;
    worksheet.getColumn(2).alignment = { horizontal: 'left' };
    worksheet.getColumn(3).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(3).width = 23;
    worksheet.getColumn(3).alignment = { horizontal: 'left' };
    worksheet.getColumn(4).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(4).width = 32;
    worksheet.getColumn(4).alignment = { horizontal: 'left' };
    worksheet.getColumn(5).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(5).width = 31;
    worksheet.getColumn(5).alignment = { horizontal: 'left' };
    worksheet.getColumn(6).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(6).alignment = { horizontal: 'left' };
    worksheet.getColumn(7).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(7).alignment = { horizontal: 'left' };

    var row = worksheet.addRow(['']);
    row.height = 20;
    var row = worksheet.addRow(['Acumuladores de Wrapup para campañas - ( ' + this.formatDate(from) + ' ' + this.formatHour(from) + ' a ' + this.formatDate(to) + ' ' + this.formatHour(to) + ' )']);
    row.getCell(1).font = {name: 'Calibri Light', size: 10, bold: true};
    row.getCell(1).alignment = { horizontal: 'left', vertical: 'top' };
    row.height = 20;
    var row = worksheet.addRow(['']);
    row.height = 10;

    worksheet.mergeCells('A1:D1');
    worksheet.mergeCells('A2:D2');
    worksheet.mergeCells('A3:D3');

    var row = worksheet.addRow(headers);
    row.height = 18;
    row.font = {name: 'Tahoma', size: 7, bold: true};
    row.alignment = { vertical: 'top' };
    row.getCell(1).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(2).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(3).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(4).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(5).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(6).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(7).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };

    this.addRowRaw(wrapUpRows, worksheet);
  }


  public formatDate(date: Date) : string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
  }
  public formatHour(date: Date) : string {
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();

    if (hour.length < 2)
      hour = '0' + hour;
    if (minute.length < 2)
      minute = '0' + minute;
    if (second.length < 2)
      second = '0' + second;

    return [hour, minute, second].join(':');
  }

  private async addRow(info : Object, counter, worksheet: Worksheet) {
    const keys = Object.keys(info);
    keys.forEach(key => {
      worksheet.addRow([key, info[key]]);
      counter.sum += info[key];
      counter.count ++;
    });
  }

  private async addRowRaw(info : any, worksheet: Worksheet) {
    info.forEach(values => {

      var dateStart = new Date(values.conversationStart);
      var dateEnd = new Date(values.conversationEnd);
      

      const formatDate = (dateStart)=>{

        var year = dateStart.getFullYear();
        var month = '' + (dateStart.getMonth() + 1);
        var day = '' + dateStart.getDate();
        var hour = '' + dateStart.getHours();
        var minute = '' + dateStart.getMinutes();
        var second = '' + dateStart.getSeconds();
        var msecond = '' + dateStart.getMilliseconds();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        if (hour.length < 2)
          hour = '0' + hour;
        if (minute.length < 2)
          minute = '0' + minute;
        if (second.length < 2)
          second = '0' + second;
        if (msecond.length < 2)
          msecond = msecond + '0';
        if (msecond.length < 3)
          msecond = msecond + '0';

      return day + '-' + month + '-' + year + ' ' + hour + ':' + minute + ':' + second + '.' + msecond;
      }
      var end = '';
      if(values.conversationEnd !== undefined){
        end = formatDate(dateEnd);
      }
      worksheet.addRow([values.conversationId, formatDate(dateStart), end, values.outboundCampaignId, values.outboundContactListId, values.outboundContactId, values.wrapUpCode]);
    });
  }
}
