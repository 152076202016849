import { Injectable } from '@angular/core';
import { Workbook, Worksheet , ValueType} from 'exceljs';
import * as fs from 'file-saver';
import { CampaignAccumulators } from 'src/app/models/campaign-accumulators/campaign-accumulators';
import { DateTime } from 'src/app/utils/date-time';

@Injectable({
  providedIn: 'root'
})
export class CampaignAccumulatorsExcelService {

  constructor(){};

  generateExcel(calls: Object[], from: Date, to: Date){
    var name = 'reporte_acumuladores_campañas';

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reporte');
    worksheet.properties.defaultRowHeight = 18;

    //? font tamaño alineacion y ancho de todas las celdas
    for(var i =1; i <= 44; i++){
      worksheet.getColumn(i).font = {name: 'Tahoma', size: 8}; //#ffffff white
      worksheet.getColumn(i).width = 20;
      worksheet.getColumn(i).alignment = { horizontal: 'center', vertical: 'middle' };
    }
    worksheet.getColumn(16).width = 24; //AGENTES ATENDIDAS
    worksheet.getColumn(17).width = 24; //AGENTES ABANDONADAS
    worksheet.getColumn(38).width = 24; //TIEMPO REAL DE LOGUEO
    //? Los header's
    var headers = ['FECHA', 'INTERVALO','DISPARADAS','NO EJECUTADAS','NO CONTACTO','CONTACTADAS',
                   'NC OCUPADAS','NC NO CONTESTA','NC OTROS','NC MODEMFAX','NC CONTESTADOR','NC NO HABLO',
                   'INGRESADA','DERIVADAS','ABANDONADAS','AGENTES ATENDIDAS','AGENTES ABANDONADAS','TRANSFER OUT',
                   'EN COLA','LOGIN','AVAIL','RING','CONNECT','HOLD',
                   'ACW','NOT READY','AVAILABLE','ON_QUEUE','TRAINING',
                   'MEETING','BUSSY','BREAK','MEAL','IDLE','AWAY',
                   'AUXILIAR TOTAL','AUXILIAR %','TIEMPO REAL DE LOGUEO','UTILIZACION','AHT','ATT',
                   'RG EXITOSO','RG NO EXITOSO','%CE'];
    var row = worksheet.addRow(headers);
    row.alignment = { vertical: 'top' };
    row.font = {bold: true};
    //? custom exclusivo de los header's
    ['A1','B1','M1','N1','O1','P1',
     'Q1','R1','S1','T1','U1','V1',
     'W1','X1','Y1','Z1','AA1','AB1',
     'AC1','AD1','AE1','AF1','AG1','AH1',
     'AI1','AJ1','AK1','AL1','AM1','AN1',
     'AO1','AP1','AQ1','AR1'].map(key => {
      var cell = worksheet.getCell(key);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000099' },
        bgColor: { argb: '000099' }
      };
      cell.font = {color: {argb: 'FFFFFF'}, bold: true };
      cell.alignment = {horizontal : 'center'}
    });
    ['C1','D1','E1','F1','G1','H1',
     'I1','J1','K1','L1'].map(key => {
      var cell = worksheet.getCell(key);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '5AC18E' },
        bgColor: { argb: '5AC18E' }
      };
      cell.font = {color: {argb: 'FFFFFF'}, bold: true };
      cell.alignment = {horizontal : 'center'}
    });


    var obj = calls[0];
    if( calls[1] === undefined)
      console.log("indefinido");
    else{
      var fechas: Date[] = calls[1]['fechas'];
      fechas.sort( (a,b) => a.getTime() - b.getTime() );
      var ids: string[] = calls[1]['ids'];
      console.log('Volcando contenido al excel, las keys son: ' + fechas);

      //!QUE SOLO AGREGUE LAS KEYS QUE ESTAN EN RANGO DE LO QUE SE PIDIO EN LA PAGINA
      var fromAux: Date = new Date(from.getTime());
      fromAux.setHours(fromAux.getHours() - 3); //Restamos 3 para ponerlo a hora local
      var toAux: Date = new Date(to.getTime());
      toAux.setHours(toAux.getHours() - 3); //Restamos 3 para ponerlo a hora local

      for(var i =0; i < fechas.length; i++){
        var fromKey = fechas[i];
        if( fromAux <= fromKey && fromKey < toAux ){
          var dayAndHour = DateTime.convertDateToDayString(fromKey, "/") + "|" + this.convertDateToHalfHourString(fromKey, ":");
          var queueAccumulator: CampaignAccumulators = obj[dayAndHour];
          this.addRow( queueAccumulator, dayAndHour, worksheet );
        }else{
          console.log('Recahzamos la fecha: ' + fromKey + ', por no estar entre las elegias: ' + fromAux + ' ' + toAux);
        }
      }
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });

  };

  private addRow(campaignAccumulators : CampaignAccumulators, time: string, worksheet: Worksheet ){

    // {yyyy/ mm/ dd| hh: mm: ss} -> 2021/05/10|13:00:00
    var day = time.substring(0,10);
    var hour = time.substring(11);
    campaignAccumulators.aht = ( campaignAccumulators.ahtCount === 0 )?  0 : (campaignAccumulators.aht / campaignAccumulators.ahtCount);
    campaignAccumulators.att = ( campaignAccumulators.attCount === 0 )?  0 : (campaignAccumulators.att / campaignAccumulators.attCount);

    var auxPorcent: string = 'NA';
    var utilizacionS: string = 'NA';
    if( campaignAccumulators.auxiliarTotal !== 0 ){
      campaignAccumulators.auxiliarPorcentaje = campaignAccumulators.connect / campaignAccumulators.auxiliarTotal;
      campaignAccumulators.auxiliarPorcentaje = campaignAccumulators.auxiliarPorcentaje * 100;
      auxPorcent = campaignAccumulators.auxiliarPorcentaje.toString();
      if(campaignAccumulators.auxiliarPorcentaje < 10) auxPorcent = auxPorcent.substring(0,4); // 9.99999
      if(campaignAccumulators.auxiliarPorcentaje < 100) auxPorcent = auxPorcent.substring(0,5); // 99.99999
      else auxPorcent = auxPorcent.substring(0,3); // 100.0000
      auxPorcent = auxPorcent + '%';
    }else{
      auxPorcent = '0%';
    }
    if(campaignAccumulators.tiempoTotalLogueo !== 0){
      campaignAccumulators.utilizacion =  campaignAccumulators.connect / campaignAccumulators.tiempoTotalLogueo;
      utilizacionS = campaignAccumulators.utilizacion.toString();
      if(campaignAccumulators.utilizacion < 10) utilizacionS = utilizacionS.substring(0,4); // 9.99999
      if(campaignAccumulators.utilizacion < 100) utilizacionS = utilizacionS.substring(0,5); // 99.99999
      else utilizacionS = utilizacionS.substring(0,3); // 100.0000
    }else{
      utilizacionS = '0';
    }
    if((campaignAccumulators.rgExitoso + campaignAccumulators.rgNoExitoso) !== 0){
      campaignAccumulators.porcentajeCE = campaignAccumulators.rgExitoso * 100 / (campaignAccumulators.rgExitoso + campaignAccumulators.rgNoExitoso);
      var porcentajeCEString = campaignAccumulators.porcentajeCE.toString();
      if(campaignAccumulators.porcentajeCE < 10) porcentajeCEString = porcentajeCEString.substring(0,4); // 9.99999
      if(campaignAccumulators.porcentajeCE < 100) porcentajeCEString = porcentajeCEString.substring(0,5); // 99.99999
      else porcentajeCEString = porcentajeCEString.substring(0,3); // 100.0000
      porcentajeCEString = porcentajeCEString + '%';
    }
    else
      porcentajeCEString = '100%';

    var columns: string[] = [day,
                             hour,
                             campaignAccumulators.disparadas.toString(),
                             campaignAccumulators.noEjecutadas.toString(),
                             campaignAccumulators.noContacto.toString(),
                             campaignAccumulators.contactadas.toString(),

                             campaignAccumulators.ncOcupadas.toString(),
                             campaignAccumulators.ncNoContesta.toString(),
                             campaignAccumulators.ncOtros.toString(),
                             campaignAccumulators.ncModemFax.toString(),
                             campaignAccumulators.ncContestador.toString(),
                             campaignAccumulators.ncNoHablo.toString(),

                             campaignAccumulators.ingresadas.toString(),
                             campaignAccumulators.derivadas.toString(),
                             campaignAccumulators.abandonadas.toString(),
                             campaignAccumulators.agentesAtendidas.toString(),
                             campaignAccumulators.agentesAbandonadas.toString(),
                             campaignAccumulators.transferOut.toString(),

                             this.toClockFormat(campaignAccumulators.enCola),
                             this.toClockFormat(campaignAccumulators.login),
                             this.toClockFormat(campaignAccumulators.avail),
                             this.toClockFormat(campaignAccumulators.ring),
                             this.toClockFormat(campaignAccumulators.connect),
                             this.toClockFormat(campaignAccumulators.hold),

                             this.toClockFormat(campaignAccumulators.acw),
                             this.toClockFormat(campaignAccumulators.notReady),
                             this.toClockFormat(campaignAccumulators.estadoAvailable),
                             this.toClockFormat(campaignAccumulators.enCola),
                             this.toClockFormat(campaignAccumulators.estadoTraining),
                             this.toClockFormat(campaignAccumulators.estadoMeeting),

                             this.toClockFormat(campaignAccumulators.estadoBussy),
                             this.toClockFormat(campaignAccumulators.break),
                             this.toClockFormat(campaignAccumulators.estadoMeal),
                             this.toClockFormat(campaignAccumulators.estadoIdle),
                             this.toClockFormat(campaignAccumulators.estadoAway),
                             this.toClockFormat(campaignAccumulators.auxiliarTotal),

                             auxPorcent,
                             this.toClockFormat(campaignAccumulators.tiempoTotalLogueo),
                             utilizacionS,
                             this.toClockFormat(campaignAccumulators.aht),
                             this.toClockFormat(campaignAccumulators.att),
                             campaignAccumulators.rgExitoso.toString(),

                             campaignAccumulators.rgNoExitoso.toString(),
                             porcentajeCEString];
    var row = worksheet.addRow(columns);
    row.height = 18;
    row.font = {name: 'Tahoma', size: 7, bold: true};
    row.alignment = { vertical: 'middle' };
    row.alignment = { horizontal: 'center' };
  }

  private toClockFormat(ms: number): string{
    var hours = Math.floor(ms/(1000*60*60));  //ms * min * sec
    var hoursRemainderMs = ms % (1000*60*60);
    var minutes = Math.floor(hoursRemainderMs/(1000*60)); //ms * sec
    var minutesRemainderMs = hoursRemainderMs % (1000*60);
    var seconds = Math.floor(minutesRemainderMs / 1000); //ms
    var hoursS = (hours<10)? hoursS = '0'+hours.toString() : hours.toString();
    var minutesS = (minutes<10)? minutesS = '0'+minutes.toString() : minutes.toString();
    var secondsS = (seconds<10)? secondsS = '0'+seconds.toString() : seconds.toString();
    // hh:mm:ss
    return hoursS + ':' + minutesS + ':' + secondsS;
  }
  private convertDateToHalfHourString(date: Date, delimiter: string): string {
    var hour = "" + date.getHours();
    if (date.getMinutes() >= 30)
      var minute = "" + "30";
    else
      var minute = "" + "00";
    var second = "" + "00";
    if (hour.length < 2)
      hour = "0" + hour;

    return hour + delimiter + minute + delimiter + second;
  }
}
