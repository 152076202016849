import { Injectable } from '@angular/core';
import { PurecloudService } from '../purecloud/purecloud.service';
import * as platformClient from 'purecloud-platform-client-v2';


@Injectable({
  providedIn: 'root'
})
export class PollsCloudService {

  constructor(private purecloudService: PurecloudService) { }

  public getMaxDate(): Promise<platformClient.Models.DataAvailabilityResponse> {
    return this.purecloudService.getConversations().getAnalyticsConversationsDetailsJobsAvailability();  
  }


}
