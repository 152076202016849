import { Injectable } from '@angular/core';
import { EventCounter } from 'src/app/models/event-counter/event-counter';
import { Workbook, Worksheet , ValueType} from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class EventCounterExcelService {

  constructor() { }
  exportExcel(info : EventCounter[], from: Date, to: Date, anis: string[]) {
    var headers = ['NOMBRE', 'SECUENCIA', 'DESCRIPCION', 'VALOR', 'CANTIDAD'];
    var name = 'reporte_contador_eventos';
    var fila = 0;

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reporte');

    worksheet.getColumn(1).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(1).alignment = { horizontal: 'center' };
    worksheet.getColumn(2).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(2).width = 10;
    worksheet.getColumn(2).alignment = { horizontal: 'center' };
    worksheet.getColumn(3).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(3).width = 32;
    worksheet.getColumn(3).alignment = { horizontal: 'center' };
    worksheet.getColumn(4).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(4).width = 32;
    worksheet.getColumn(4).alignment = { horizontal: 'center' };
    worksheet.getColumn(5).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(5).width = 10;
    worksheet.getColumn(5).alignment = { horizontal: 'center' };
    
    var row = worksheet.addRow(['']);
    row.height = 20;
    var row = worksheet.addRow(['CONTADOR DE EVENTOS - ( ' + this.formatDate(from) + ' ' + this.formatHour(from) + ' a ' + this.formatDate(to) + ' ' + this.formatHour(to) + ' )']);
    row.getCell(1).font = {name: 'Calibri Light', size: 10, bold: true};
    row.getCell(1).alignment = { horizontal: 'left', vertical: 'top' };
    row.height = 20;
    var row = worksheet.addRow(['']);
    row.height = 10;

    worksheet.mergeCells('A1:E1');
    worksheet.mergeCells('A2:E2');
    worksheet.mergeCells('A3:E3');

    var row = worksheet.addRow(headers);
    row.getCell(1).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(1).alignment = { horizontal: 'right' };
      row.getCell(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };
   
      row.getCell(2).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(2).alignment = { horizontal: 'right' };
      row.getCell(2).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };
  
      row.getCell(3).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(3).alignment = { horizontal: 'right' };
      row.getCell(3).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };

      row.getCell(4).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(4).alignment = { horizontal: 'right' };
      row.getCell(4).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };
 
      row.getCell(5).font = { name: 'Calibri', size: 11, bold: true };
      row.getCell(5).alignment = { horizontal: 'right' };
      row.getCell(5).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9b9b9b' }
      };
  
    row.height = 18;
    row.font = {name: 'Tahoma', size: 7, bold: true};
    row.alignment = { horizontal: 'center', vertical: 'top' };

    info.forEach(d => worksheet.addRow([d.nombre, d.secuencia, d.descripcion, d.valor, d.cantidad]));
    
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }

  public formatDate(date: Date) : string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
  }
  public formatHour(date: Date) : string {
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();

    if (hour.length < 2) 
      hour = '0' + hour;
    if (minute.length < 2) 
      minute = '0' + minute;
    if (second.length < 2) 
      second = '0' + second;

    return [hour, minute, second].join(':');
  }

}
