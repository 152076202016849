import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import { Models } from 'purecloud-platform-client-v2';
import { Observable } from 'rxjs';
import { Poll } from 'src/app/models/polls/poll';
import { Conversation } from 'src/app/models/purecloud/conversation';
import { DateTime } from 'src/app/utils/date-time';
import { MapConversation } from 'src/app/utils/map-conversation';
import { ConversationDataPurecloudService } from '../purecloud/conversation-data-purecloud.service';
import { PurecloudService } from '../purecloud/purecloud.service';
import { TimeRange } from '../purecloud/time-range';

@Injectable({
  providedIn: 'root'
})
export class PollsDataService extends ConversationDataPurecloudService{
  private Toast2 = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  });
  constructor(private purecloudService: PurecloudService, private http: HttpClient) {
    super(purecloudService);
  }
  
  private urlEndPoint: string = 'https://us2.surveydynamix.com';
  private httpHeaders = new HttpHeaders({'Authorization': 'Basic NjI0MzlhNDAtMTMyMi0xMWViLWE3NjUtZWRkMDE5MDFhOWM3OmVOcGppcE1ReFFuUjlQc1F4Sm1Ja2JNSmRHd1ViSDAw'});  

  protected processInternalCall(conversation: Models.AnalyticsConversation, timeRange: TimeRange, calls: Object[]) {
    var from = DateTime.convertStringToDate(conversation.conversationStart);
    if (timeRange.fromParcial.getTime() === timeRange.from.getTime() && from.getTime() <= timeRange.from.getTime()) {
      this.processCall(MapConversation.analyticsConversation(conversation), calls)
      return;
    }
    if (from.getTime() > timeRange.fromParcial.getTime()) {
      this.processCall(MapConversation.analyticsConversation(conversation), calls)
    }
  }

  public processCall(conversation: Conversation, calls: Object[]): any {
    var participants = conversation.participants;
    if (calls.length == 0) {
      calls.push({});
    }
    var agentId = calls[0];
    participants.forEach(participant =>{
      if(participant.purpose === "agent"){

        var count = agentId[participant.userId];
        if(count === undefined){
          count = 0;
        }
        count++;
        agentId[participant.userId] = count;
      } 
    });
  }
   getDynamixPolls(): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {
        _limit: '1000',
        _offset: '0'
      }
    })
    return this.http.get<Poll[]>(this.urlEndPoint + '/api/surveys',{params: httpParams, headers: this.httpHeaders});
  }

  public getDynamixInteractions(surveyId : string, agentId : string,  from : string, to : string) {
    return new Promise(async (resolve, reject) => {
      var polls = new Array();
      await this.getDynamixInteractionsInternal(surveyId, agentId, from, to, 0, polls, resolve, reject);
    });
}

  private async getDynamixInteractionsInternal(surveyId : string, agentId : string,  from : string, to : string, offset: number, polls: Array<any>, resolve, reject) {
    const httpParams = new HttpParams({
      fromObject: {
        survey_id: surveyId,
        _limit: '1000',
        _offset: '' + (offset * 1000),
        _order_by: 'started_at',
        _include_responses: '1',
        _include_data: '1',
        _has_responses: '1',
        start_date: from,
        end_date: to,
        agent_id: agentId
      }
    })
    await this.sleep2(10);
    await this.http.get<any[]>(this.urlEndPoint + '/api/interactions', {params: httpParams, headers: this.httpHeaders }).toPromise()
    .then( (interactions) => {
      if(interactions === undefined || interactions === null){
        resolve(polls);
        return;
      }
      interactions.forEach(interaction => polls.push(interaction));
      this.getDynamixInteractionsInternal(surveyId, agentId, from, to, offset + 1, polls, resolve, reject);
    } )
    .catch( async (error) => { 
      if(error.status === 429){
        await this.sleep2(1000);
        this.Toast2.fire({
          title: 'Re-procesando encuesta de ' + agentId,
          timer: 1000
        });
        await this.getDynamixInteractionsInternal(surveyId, agentId, from, to, offset, polls, resolve, reject);
        console.log("getDynamixInteractionsInternal error", error);
      }else{
        reject(error);
      }  
    });
  }


  getDynamixInteractions2(): Observable<any> {
    const httpParam = new HttpParams({
      fromObject: {
        survey_id: '122',
        _limit: '100',
        _order_by: 'started_at',
        _include_responses: '1',
        _include_data: '1',
        _offset: '1',
        _has_responses: '1',
        start_date: '2021-12-13',
        end_date: '2021-12-14'
      }
    })
    return this.http.get<any[]>(this.urlEndPoint + '/api/interactions', {params: httpParam, headers: this.httpHeaders });
  }

  private sleep2(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
