export class TimeRange {
    public fromParcial: Date;
    public toParcial: Date;
    public count: number;
    constructor(public from: Date, public to: Date) {
        this.fromParcial = from;
        this.toParcial =  new Date(from.getFullYear(), from.getMonth(), from.getDate(), from.getHours() + 1, from.getMinutes(), from.getSeconds(), from.getMilliseconds());
        if(this.toParcial.getTime() > this.to.getTime()) {
            this.toParcial = this.to;
        }
        this.count = 0;
    }
    public addDate() {
        this.fromParcial = new Date(this.fromParcial.getFullYear(), this.fromParcial.getMonth(), this.fromParcial.getDate(), this.fromParcial.getHours() + 1, this.fromParcial.getMinutes(), this.fromParcial.getSeconds(), this.fromParcial.getMilliseconds());
        this.toParcial = new Date(this.toParcial.getFullYear(), this.toParcial.getMonth(), this.toParcial.getDate(), this.toParcial.getHours() + 1, this.toParcial.getMinutes(), this.toParcial.getSeconds(), this.toParcial.getMilliseconds());
        if(this.toParcial.getTime() > this.to.getTime()) {
            this.toParcial = this.to;
        }
    }
    public isOk() : boolean {
        return this.to.getTime() > this.fromParcial.getTime();
    }
    public addCount(count : number) {
        this.count = this.count + count;
    }
}
  