import { Injectable, WrappedValue } from '@angular/core';
import { Workbook, Worksheet , ValueType} from 'exceljs';
import * as fs from 'file-saver';
import { networkInterfaces } from 'os';
@Injectable({
  providedIn: 'root'
})
export class RecordsDetailExcelService {

  constructor() { }

  exportExcel(info : Object[], from: Date, to: Date,selectedMapCampaigns, wraups ) {
    var headers = ['Campaña', 'IdTarea','Agente ultimo contacto', 'Estado','Ultima llamada', 'Nro.Línea','Id Cliente','Causa terminacion', 'Intentos','Resultado Gestíon', 'Tipos resultados gestíon','Observaciones'];
    var name = 'reporte_detalle_de_registros';

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reporte');

    worksheet.getColumn(1).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(1).width = 15.27;
    worksheet.getColumn(1).alignment = { horizontal: 'left' };
    worksheet.getColumn(2).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(2).width = 15.27;
    worksheet.getColumn(2).alignment = { horizontal: 'right' };
    worksheet.getColumn(3).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(3).width = 15.27;
    worksheet.getColumn(3).alignment = { horizontal: 'right' };
    worksheet.getColumn(4).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(4).width = 15.27;
    worksheet.getColumn(4).alignment = { horizontal: 'right' };
    worksheet.getColumn(5).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(5).width = 15.27;
    worksheet.getColumn(5).alignment = { horizontal: 'right' };
    worksheet.getColumn(6).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(6).width = 15.27;
    worksheet.getColumn(6).alignment = { horizontal: 'right' };
    worksheet.getColumn(7).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(7).width = 15.27;
    worksheet.getColumn(7).alignment = { horizontal: 'right' };
    worksheet.getColumn(8).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(8).width = 15.27;
    worksheet.getColumn(8).alignment = { horizontal: 'right' };
    worksheet.getColumn(9).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(9).width = 15.27;
    worksheet.getColumn(9).alignment = { horizontal: 'right' };
    worksheet.getColumn(10).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(10).width = 15.27;
    worksheet.getColumn(10).alignment = { horizontal: 'right' };
    worksheet.getColumn(11).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(11).width = 15.27;
    worksheet.getColumn(11).alignment = { horizontal: 'right' };
    worksheet.getColumn(12).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(12).width = 15.27;
    worksheet.getColumn(12).alignment = { horizontal: 'right' };
    worksheet.getColumn(13).font = {name: 'Tahoma', size: 8 };
    worksheet.getColumn(13).width = 15.27;
    worksheet.getColumn(13).alignment = { horizontal: 'right' };

    var row = worksheet.addRow(['']);
    row.height = 20;
    var row = worksheet.addRow(['Detalle de registros - ( ' + this.formatDate(from) + ' ' + this.formatHour(from) + ' a ' + this.formatDate(to) + ' ' + this.formatHour(to) + ' )']);
    row.getCell(1).font = {name: 'Calibri Light', size: 10, bold: true};
    row.getCell(1).alignment = { horizontal: 'left', vertical: 'top' };
    row.height = 20;
    var row = worksheet.addRow(['']);
    row.height = 10;

    worksheet.mergeCells('A1:B1');
    worksheet.mergeCells('A2:B2');
    worksheet.mergeCells('A3:B3');

    var row = worksheet.addRow(headers);
    row.height = 18;
    row.font = {name: 'Tahoma', size: 7, bold: true};
    row.alignment = { vertical: 'top' };
    row.getCell(1).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(2).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(3).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(4).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(5).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(6).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(7).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(8).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(9).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(10).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(11).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(12).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };
    row.getCell(13).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'B8CCE4'}
    };

   info.forEach(d => this.addDataRow(d,  worksheet));

   workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, name + '.xlsx');
    });
  }
  public formatDate(date: Date) : string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
  }
  public formatHour(date: Date) : string {
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();

    if (hour.length < 2)
      hour = '0' + hour;
    if (minute.length < 2)
      minute = '0' + minute;
    if (second.length < 2)
      second = '0' + second;

    return [hour, minute, second].join(':');
  }

  private async addRow(info : Object, counter, worksheet: Worksheet) {
    const keys = Object.keys(info);
    keys.forEach(key => {
      worksheet.addRow([key, info[key]]);
      counter.sum += info[key];
      counter.count ++;
    });
  }


  private addDataRowWithCount(info : Object,counter, worksheet: Worksheet) {
    const keys = Object.keys(info);
    var newRow = [];
    keys.forEach(key => {
      newRow.push(info[key]);
      counter.sum += info[key];
      counter.count ++;
    });
  }

  private addDataRow(info : Object, worksheet: Worksheet) {
    const keys = Object.keys(info);
    const values  = Object.values(info);

    var newRow = [];

    if (keys.includes("campania")){
      newRow.push(info["campania"]); //campaña
    }
    else{
      newRow.push("");
    }
    if (keys.includes("idTarea")){
      newRow.push(info["idTarea"]); //idTarea
    }
    else{
      newRow.push("");
    }
    if (keys.includes("agente")){
      newRow.push(info["agente"]); //agente
    }
    else{
      newRow.push("");
    }
    if (keys.includes("estado")){
      newRow.push(info["estado"]);  //estado
    }
    else{
      newRow.push("");
    }
    if (keys.includes("ultimaLLamada")){
      const uL: Date =info["ultimaLLamada"];
      newRow.push(this.formatDate(uL)+ ' ' + this.formatHour(uL)); //ultima llamada
    }
    else{
      newRow.push("");
    }
    if (keys.includes("numeroLinea")){
      newRow.push(info["numeroLinea"]);  //numerolinea
    }
    else{
      newRow.push("");
    }
    if (keys.includes("idCliente")){
      newRow.push(info["idCliente"]);  //idcliente
    }
    else{
      newRow.push("");
    }
    if (keys.includes("causaTerminacion")){
      newRow.push(info["causaTerminacion"]);  //causa terminacion
    }
    else{
     newRow.push("");
    }
     if(keys.includes("intentos")){
     newRow.push(info["intentos"]);  //intentos
    }
    else{
     newRow.push("");
    }
    if (keys.includes("resultadoGestion")){
     newRow.push(info["resultadoGestion"]); //Resultado gestion
    }
    else{
     newRow.push("N/A");
    }
    if (keys.includes("tiposResultadosGestion")){
     newRow.push(info["tiposResultadosGestion"]);  //ultimoWrapup
    }
    else{
     newRow.push("");
    }
    if (keys.includes("observaciones")){
     newRow.push(info["observaciones"]);  //observaciones
    }
    else{
     newRow.push("");
    }
  worksheet.addRow(newRow);
  }
}
