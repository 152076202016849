import { KeyedWrite } from '@angular/compiler';
import { Injectable } from '@angular/core';
import * as platformClient  from 'purecloud-platform-client-v2';
import { Models } from 'purecloud-platform-client-v2';
import { Conversation } from 'src/app/models/purecloud/conversation';
import { DateTime } from 'src/app/utils/date-time';
import { MapConversation } from 'src/app/utils/map-conversation';
import { ConversationDataPurecloudService } from '../purecloud/conversation-data-purecloud.service';
import { PurecloudService } from '../purecloud/purecloud.service';
import { TimeRange } from '../purecloud/time-range';

@Injectable({
  providedIn: 'root'
})
export class EventCounterDataService extends ConversationDataPurecloudService  {
  constructor(private purecloudService: PurecloudService) {
    super(purecloudService);
  }

  protected processInternalCall(conversation: Models.AnalyticsConversation, timeRange: TimeRange, calls: Object[]) {
    var from = DateTime.convertStringToDate(conversation.conversationStart);
    if (timeRange.fromParcial.getTime() === timeRange.from.getTime() && from.getTime() <= timeRange.from.getTime()) {
      this.processCall(MapConversation.analyticsConversation(conversation), calls)
      return;
    }
    if (from.getTime() > timeRange.fromParcial.getTime()) {
      this.processCall(MapConversation.analyticsConversation(conversation), calls)
    }
  }

  public processCall(conversation: Conversation, calls: Object[]): any {
    var participants = conversation.participants;
    if (calls.length == 0) {
      calls.push({});
    }
    var events = calls[0];
    participants.forEach(participant =>{
      if(participant.attributes !== undefined){
        const keys = Object.keys(participant.attributes);
        keys.forEach(key => {
          if(key.startsWith('EVENTO-IVR')){
            var value = participant.attributes[key];
            var count = events[value];
            if(count === undefined){
              count = 0;
            }
            count++;
            events[value]= count;
          }
        })
      } 
    });
  }
}
