import { Injectable } from '@angular/core';
import * as platformClient  from 'purecloud-platform-client-v2';
import { Conversation, Segment } from 'src/app/models/purecloud/conversation';
import { DateTime } from 'src/app/utils/date-time';
import { MapConversation } from 'src/app/utils/map-conversation';
import { ConversationDataPurecloudService } from '../purecloud/conversation-data-purecloud.service';
import { PurecloudService } from '../purecloud/purecloud.service';
import { TimeRange } from '../purecloud/time-range';
import { Task } from 'src/app/models/number-of-records-by-type/rbttask';
import { NumberOfRecordsByTypeExcelService } from 'src/app/services/number-of-records-by-type/number-of-records-by-type-excel.service';
import { Session } from 'src/app/models/number-of-records-by-type/sessions';

@Injectable({
  providedIn: 'root'
})
export class NumberOfRecordsByTypeDataService extends ConversationDataPurecloudService {
  constructor(private purecloudService : PurecloudService) {
    super(purecloudService);
  }

  protected processInternalCall(conversation: platformClient.Models.AnalyticsConversation, timeRange: TimeRange, calls: Object[]) {
    var from = DateTime.convertStringToDate(conversation.conversationStart);
    /*
    console.log("conversation start", from.getTime());
    console.log("fromPartial",timeRange.fromParcial.getTime());
    console.log("from",timeRange.from.getTime());
    console.log("to", timeRange.to.getTime());
    */
    if(from.getUTCDate() >= timeRange.fromParcial.getUTCDate() && from.getUTCDate() < timeRange.toParcial.getUTCDate()) {
      console.log("conversation start", from.getTime());   
      this.processCall(MapConversation.analyticsConversation(conversation), calls);
    }
    //if(from.getTime() >= timeRange.fromParcial.getTime() && from.getTime() < timeRange.toParcial.getTime()) {
    //  console.log("conversation start", from.getTime());   
    //  this.processCall(MapConversation.analyticsConversation(conversation), calls);
   // }
    /*else{
      console.log("else from", from);
    }*/
  }
  
  public processCall(conversation : Conversation, calls : Object[]) {
    
    console.log(conversation);
    var innerSessions : Session[] =[];
    var sessionName: string="";
    var queuesTemp : String[] = [];
    var task = new Task();
    var ultLlamada: string ="";
  
    task.conversationid = conversation.conversationId;
    
    task.fecha = this.formatDate(conversation.conversationStart);
    
    //var fromAux: Date = new Date(task.fecha);
    //fromAux.setHours(fromAux.getHours() - 3); //! Importante esta parte, las claves se guardan en horario de arg
    //var day = DateTime.convertDateToDayString(fromAux, "-") ;
    //task.fecha = day;



    conversation.participants.forEach( participant => {
      if( participant.purpose ==='acd' ){
        task.campania=participant.participantName;
        task.idCampania=participant.participantName;
      };
      participant.sessions.forEach( seccion =>{ 
        
        var existsSession = false;
        if (seccion.peerId !==undefined){
          sessionName = seccion.peerId;
        }
        else if (seccion.sessionId !== undefined){
          sessionName = seccion.sessionId;
        }
        else {
          sessionName ="";
        }
        innerSessions.forEach( s => {
          if (s.sessionId === sessionName){
             seccion.segments.forEach( segment => {
            if(s.fecha <= segment.segmentEnd && segment.wrapUpCode!== undefined){
              task.ultimoWrapup = segment.wrapUpCode;
              task.causaTerminacion = segment.wrapUpCode;
              s.fecha = segment.segmentEnd;
              }
            });
            s.wrapup=task.ultimoWrapup;
            s.idQueues.push(participant.participantName);
            existsSession =true;
            
          }
          
        });
               
        if (existsSession === false ){
          var session = new Session();
          session.fecha =  conversation.conversationStart;
          var idQueues: string[] = [];
          seccion.segments.forEach( segment => {
            if(session.fecha <= segment.segmentEnd && segment.wrapUpCode!== undefined){
              task.ultimoWrapup = segment.wrapUpCode;
              task.causaTerminacion = segment.wrapUpCode;
              session.fecha=segment.segmentEnd;
            };
          session.purpose = participant.purpose;
          session.sessionId = sessionName;
          session.wrapup = task.ultimoWrapup;
          idQueues.push(participant.participantName);
          session.idQueues=idQueues;
          innerSessions.push(session);
        });
        };
        
      })  ;
      
      
    });
    console.log(innerSessions);
    innerSessions.forEach(c =>{
      //if (c.purpose === 'acd'){//solo guardo sessions que sean queues
       c.idQueues.forEach(qu => {
        var taskFinal = new Task();
        taskFinal.campania = qu;
        taskFinal.fecha = task.fecha;
        taskFinal.idCampania = qu;
        taskFinal.ultimoWrapup = c.wrapup;
        taskFinal.causaTerminacion= c.wrapup; //ultimo wrapup de la sesion
        taskFinal.estado= task.estado; //hasta acá vacio, luego lo levanta
        calls.push(taskFinal); 
       }); 
      //};
    });
    
  }

  public formatDate(date: String) : string {  
    
    return date.substring(0,10);
  }

  public getSessionsCall(conversation : Conversation) {
    var sessions = {};
    for(var i = 0; i < conversation.participants.length; i++) {
      for(var j = 0; j < conversation.participants[i].sessions.length; j++) {
        if(conversation.participants[i].sessions[j].mediaType === 'callback') {
        }

        var id = undefined;
        if(conversation.participants[i].sessions[j].peerId === undefined) {
          id = conversation.participants[i].sessions[j].sessionId;
        } else {
          id = conversation.participants[i].sessions[j].peerId;
        }
        var segments = sessions[id];
        if(segments === undefined) {
          segments = [];
          sessions[id] = segments;
        }

        for(var k = 0; k < conversation.participants[i].sessions[j].segments.length; k++) {
          var segment = conversation.participants[i].sessions[j].segments[k];
          if(segment.wrapUpCode !== undefined) {
            segments.push(segment);
          }
        }
      }
    }
    return sessions;
  }

}
