export class Enargas {
    atendido:boolean = false;
    corto:boolean = false;
    fecha:string;
    hora:string;
    numero:string;
    demora:number;
    duracion:number;
    queue:string;
    queueCount:number = 0;
    conversation:string;
}