<div class="form-group">
  <div class="center">
      <div ng-class="pointer-events: none;">
          <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="pickerFrom" placeholder="Desde*" [(ngModel)]="myDatePickerFrom">
              <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerFrom [showSeconds]="true">
              </ngx-mat-datetime-picker>
          </mat-form-field>
          <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="pickerTo" placeholder="Hasta*" [(ngModel)]="myDatePickerTo"
                  [min]="myDatePickerFrom">
              <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerTo [showSeconds]="true">
              </ngx-mat-datetime-picker>
          </mat-form-field>
      </div>
  </div>
  <div class="center">
      <div class="example-button-row">
          <button mat-button (click)="onRangeDate('TODAY')">Hoy</button>
          <button mat-button (click)="onRangeDate('YESTERDAY')">Ayer</button>
          <button mat-button (click)="onRangeDate('LAST_7_DAYS')">Últimos 7 días</button>
          <button mat-button (click)="onRangeDate('THIS_WEEK')">Esta semana</button>
          <button mat-button (click)="onRangeDate('PREVIOUS_WEEK')">Anterior semana</button>
          <button mat-button (click)="onRangeDate('LAST_31_DAYS')">Últimos 31 días</button>
        </div>
  </div>
  <div class="center">
    <div class="duallistbox-dummy2" *ngIf="!bloqueDos">
        <select name="agentes-dummy" class="duallistbox-agent-login-agents-dummy" multiple hidden>
            <option *ngFor="let n of allAgentsDummy" [value]="n.id">{{n.name}}</option>
        </select>
    </div>
  </div>
  <div class="center">
      <div class="duallistbox-custom">
          <select name="agentes" class="duallistbox-agent-login-agents" multiple hidden>
              <option *ngFor="let n of allAgents" [value]="n.id">{{n.name}}</option>
          </select>
      </div>
  </div>
</div>
<button [disabled]="!myDatePickerFrom || !myDatePickerTo" id="generate-interactions-detail" type="button"
  class="btn btn-block btn-secondary" (click)="onGenerateAgentsLogin()">Generar
</button>


<app-script-hack type="text/javascript">
  <![CDATA[
      duallistboxAgentLoginAgentsDummy = $('.duallistbox-agent-login-agents-dummy').bootstrapDualListbox({
              moveOnSelect: false,
              infoTextEmpty: 'Lista vacia',
              infoText: false,
              filterPlaceHolder: 'Filtrar por agente',
              moveSelectedLabel: 'Mover soleccionado',
              moveAllLabel: 'Mover todos',
              removeSelectedLabel: 'Borrar seleccionado',
              removeAllLabel: 'Borrar todos',
              infoTextFiltered: 'Filtrado {0} de {1}',
              filterTextClear: 'Mostrar todo'
          });
      duallistboxAgentLoginAgents = $('.duallistbox-agent-login-agents');
  ]]>
</app-script-hack>
