export class Task {
    campania:string;
    idCampania:string;
    estado:string;
    ultimoWrapup:string;
    causaTerminacion:string;
    fecha:string;
    conversationid:string;
 
}

 