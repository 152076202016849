import { Participant } from './../../models/purecloud/conversation';
import { T } from "@angular/cdk/keycodes";
import { Injectable } from "@angular/core";
import * as platformClient from "purecloud-platform-client-v2";
import { stringify } from "querystring";
import { queue } from "rxjs/internal/scheduler/queue";
import { CampaignAccumulators } from "src/app/models/campaign-accumulators/campaign-accumulators";
import {
  Conversation,
  Session,
  Segment,
} from "src/app/models/purecloud/conversation";
import { DateTime } from "src/app/utils/date-time";
import { MapConversation } from "src/app/utils/map-conversation";
import { ConversationDataPurecloudService } from "../purecloud/conversation-data-purecloud.service";
import { PurecloudService } from "../purecloud/purecloud.service";
import { TimeRange } from "../purecloud/time-range";
import { CampaignAccumulatorsCloudService } from "./campaign-accumulators-cloud.service";

@Injectable({
  providedIn: "root",
})
export class CampaignAccumulatorsDataService extends ConversationDataPurecloudService {
  private purposeStop: Array<string> = ["ivr", "agent"];
  constructor(
    private purecloudService: PurecloudService,
    private campaignAccumulatorsCloudService: CampaignAccumulatorsCloudService
  ) {
    super(purecloudService);
  }

  protected processInternalCall(conversation: platformClient.Models.AnalyticsConversation, timeRange: TimeRange, calls: Object[]) {
    var from = DateTime.convertStringToDate(conversation.conversationStart);
    if (from.getTime() >= timeRange.fromParcial.getTime()) {
      this.processIntervalCall(MapConversation.analyticsConversation(conversation), from, calls);
    }
  }

  public processCall(conversation: Conversation, calls: Object[]) {}

  public processIntervalCall(conversation: Conversation, from: Date, calls: Object[] ) {

    /* Idea general, ver 3 casos reducidos y ver si las cuentas dan bien mal de mas o de menos
    |||EN LAS CONVERSATIONS||
    for(todos los participantes)
      if(el agentId matchea)
        procesar todo normalmente
      else
        return par que no lo procese

    ||EN LA OTRA API||
    Si el agentId matchea
      procesar los casos
    else
      return para no procesarlos

    if(   conversation.conversationId === '301ff190-216f-4b29-9bb1-9816a7a67a77' ||
          conversation.conversationId === 'adssa' ||
          conversation.conversationId === 'adssa' ){
    }
    else{
      return;
    }
    */




    if (calls.length == 0) {
      calls.push({});
      calls.push({});
      calls[1]['fechas'] = new Array<Date>();
      calls[1]['ids'] = new Array<string>();
    }

    var obj = calls[0];
    var fechas: Date[] = calls[1]['fechas'];
    var fromAux: Date = new Date(from.getTime());
    fromAux.setHours(fromAux.getHours() - 3); //! Importante esta parte, las claves se guardan en horario de arg
    var dayAndHour = DateTime.convertDateToDayString(fromAux, "/") + "|" + this.convertDateToHalfHourString(fromAux, ":");
    var queueAccumulator: CampaignAccumulators = obj[dayAndHour]; // ie 29/9/21 9hs son las llamadasde 9 a 9.30
    if (queueAccumulator === undefined) {
      console.log('Definimos queueAccumulator \n Añadimos una key ' + dayAndHour);
      queueAccumulator = new CampaignAccumulators();
      obj[dayAndHour] = queueAccumulator;
      fechas.push(fromAux);
    }

    switch (conversation.originatingDirection) {
      case "inbound": {
        this.processInboundCall(queueAccumulator, conversation, calls);
        break;
      }
      case "outbound": {
        this.processOutboundCall(queueAccumulator, conversation);
        break;
      }
      default: {
        console.log(
          "No se reconoce la dirección: " + conversation.originatingDirection
        );
        break;
      }
    }
  }

  public processInboundCall(campaignAccumulators: CampaignAccumulators, conversation: Conversation, calls:Object[]) {
    campaignAccumulators.ingresadas++;

    var withAgent = false;
    var withTansfer = false;
    var withAcd = false;
    campaignAccumulators.agentesAtendidasBool = false;
    campaignAccumulators.transferOutBool = false;
    campaignAccumulators.rgExitosoBool = false;

    for (var i = 0; i < conversation.participants.length; i++) {
      var participant = conversation.participants[i];
      switch (participant.purpose) {
        case "acd": {
          this.processInboundAcd(campaignAccumulators, participant);
          break;
        }
        case "agent": {
          console.log('Participant es un agente');
          withAgent = true;
          this.processInboundAgent(campaignAccumulators, calls, participant);
          break;
        }
        default:
          break;
      }
    }

    if (withAgent) {
      //campaignAccumulators.derivadas++; // (1)
    }

    if (withTansfer) {
      //campaignAccumulators.transferOut++;
    }

    if (campaignAccumulators.agentesAtendidasBool) { //si el cliente corta por esperar, esto sumaria 1 a cuando el agente corta
      //campaignAccumulators.agentesAtendidas++;
    } else {
      //campaignAccumulators.agentesAbandonadas++;
    }

    if (campaignAccumulators.rgExitosoBool) {
      campaignAccumulators.rgExitoso++;
    } else {
      campaignAccumulators.rgNoExitoso++;
    }
  }

  processInboundAcd( campaignAccumulators: CampaignAccumulators, participant: Participant) {
    for (var i = 0; i < participant.sessions.length; i++) {
      var session = participant.sessions[i];
      for (var j = 0; j < session.segments.length; j++) {
        var segment = session.segments[j];
        if (segment.segmentType === "interact" && segment.disconnectType !== "transfer")
          campaignAccumulators.abandonadas++;
      }
    }
  }

  public processInboundAgent(campaignAccumulators: CampaignAccumulators, calls: Object[], participant: Participant) {
    var interactuo = false;
    console.log('Participant: ', participant);
    for (var j = 0; j < participant.sessions.length; j++) {
      var session = participant.sessions[j];
      campaignAccumulators.derivadas++; // si que haya varios agentes cuenta como 1na sola derivada, sacar esto y usar (1)
      for (var k = 0; k < session.segments.length; k++) {
        var segment = session.segments[k];
        this.evaluateSegment(calls, segment, participant.participantName, campaignAccumulators);
      }
    }
  }

  private evaluateSegment(calls: Object[], segment: Segment,participantName: string , campaignRG: CampaignAccumulators){
    var from = DateTime.convertStringToDate(segment.segmentStart);
    var obj = calls[0];
    var fechas: Date[] = calls[1]['fechas'];

    //* Conseguimos el accumulator que corresponde al inicio del segment
    var fromAux: Date = new Date(from.getTime());
    fromAux.setHours(fromAux.getHours() - 3); //! Importante esta parte, las claves se guardan en horario de arg
    //? Mejora para el futuro, este choclo darle una (fecha, accum) y que te de el accum correcto
    var dayAndHour = DateTime.convertDateToDayString(fromAux, "/") + "|" + this.convertDateToHalfHourString(fromAux, ":");
    var campaignAccumulators: CampaignAccumulators = obj[dayAndHour]; // ie 29/9/21 9hs son las llamadasde 9 a 9.30
    if (campaignAccumulators === undefined) {
      console.log('Definimos queueAccumulator en evaluateSegment \n Añadimos una key ' + dayAndHour);
      campaignAccumulators = new CampaignAccumulators();
      obj[dayAndHour] = campaignAccumulators;
      fechas.push(fromAux);
    }

    var time = DateTime.getTimeBySegment(segment);// cuanto dura el segment en ms
    var nextLapse: Date = new Date(fromAux.getTime());
    nextLapse = this.nextLapse(nextLapse);

    if(segment.segmentType === 'interact'){
      campaignAccumulators.agentesAtendidas++; //revisar, solo es una UNICA vez para cuando inicia el segment
    }

    console.log('Fecha del segment: ' + fromAux); //!Borrar
    while(time > 0){
      console.log('Fecha de fromAux: ' + fromAux); //!Borrar
      console.log('NextLapse del fromAux: ' + nextLapse); //!Borrar
      console.log('Key del fromAux: ' + dayAndHour); //!Borrar
      console.log('Tiempo del segment: ' + time); //!Borrar
      var timeAux: number = 0;
      var lapseGap: number = nextLapse.getTime() - fromAux.getTime();
      if( lapseGap < time ){
        timeAux = lapseGap; //ms
      }else{
        timeAux = time; //ms
      }
      console.log('TimeAux elegido: ' + timeAux); //!Borrar
      this.segmentCases(timeAux, campaignAccumulators, segment.segmentType, campaignRG);
      time = time - timeAux; //ms
      if(time > 0){ // notar que time cambió, y que mover esto al inicio del while no es lo mismo
        fromAux = this.nextLapse(fromAux);
        nextLapse = this.nextLapse(fromAux);
        dayAndHour = DateTime.convertDateToDayString(fromAux, "/") + "|" + this.convertDateToHalfHourString(fromAux, ":");
        campaignAccumulators = obj[dayAndHour];
        if (campaignAccumulators === undefined) {
          console.log('Definimos queueAccumulator \n Añadimos una key ' + dayAndHour);
          campaignAccumulators = new CampaignAccumulators();
          obj[dayAndHour] = campaignAccumulators;
          fechas.push(fromAux);
        }
      }
    }
    if (segment.disconnectType === "transfer" && participantName !== "SurveyDynamix") {
      campaignAccumulators.transferOut++;//ocurren unicamente una vez, y corresponden al accum en el que se corta
    }
    if( segment.disconnectType && segment.segmentType === 'interact' && this.agentCutCall(segment.disconnectType) ){
      campaignAccumulators.agentesAbandonadas++;//ocurren unicamente una vez, y corresponden al accum en el que se corta
    }
  }

  private segmentCases(timeAux: number, campaignAccumulators: CampaignAccumulators,segmentType: string, campaignRG: CampaignAccumulators){
    console.log('SegmentCase: ' + segmentType + ', con t: ' + timeAux); //!Borrar
    campaignAccumulators.connect += timeAux;
    switch (segmentType) {
      case "wrapup": {
        campaignAccumulators.acw += timeAux;
        if(campaignRG.rgExitosoBool === false){
          campaignRG.rgExitosoBool = true;
        }
        break;
      }
      case "alert": {
        campaignAccumulators.ring += timeAux;
        campaignAccumulators.aht += timeAux;
        break;
      }
      case "hold": {
        campaignAccumulators.hold += timeAux;
        campaignAccumulators.aht += timeAux;
        break;
      }
      case "interact": {
        campaignAccumulators.aht += timeAux;
        campaignAccumulators.att += timeAux;
        campaignAccumulators.agentesAtendidasBool = true; //no hace nada
        campaignAccumulators.ahtCount++;
        campaignAccumulators.attCount++;

        break;
      }
      default: {
        break;
      }
    }
  }

  private agentCutCall(disconnectType: string): boolean{
    return disconnectType !== 'system' && disconnectType !== 'peer' && disconnectType !== 'transfer';
  }

  public processOutboundCall(
    campaignAccumulators: CampaignAccumulators,
    conversation: Conversation
  ) {
    var wrapUpCode = "undefined";
    var contact = false;
    var sessionsMap: Object = {};

    var mainSessionIds: string[] = [];

    this.groupSessionsByPeer(conversation, sessionsMap, mainSessionIds);

    for (var i = 0; i < mainSessionIds.length; i++) {
      //recorremos todas las sessions principale
      var sessions: Session[] = sessionsMap[mainSessionIds[i]];
      for (var j = 0; j < sessions.length; j++) {
        //todas las sessiones de un main id
        var session: Session = sessions[j];
        this.evaluateSession(campaignAccumulators, session);
      }
    }
  }

  private evaluateSession(campaignAccumulators: CampaignAccumulators, session: Session) {
    var segments: Segment[] = session.segments;
    var error = { code: "", state: false };
    var interact = false;
    var dialing = false;
    var wrapUp = { code: "", date: new Date() };

    for (var k = 0; k < segments.length; k++) {
      // los segments
      var segment: Segment = segments[k];
      dialing = segment.segmentType && segment.segmentType === "dialing" ? (dialing = true) : (dialing = dialing);
      if (segment.segmentType && segment.segmentType === "dialing" && segment.disconnectType && segment.disconnectType === "error") {
        error.state = true;
        error.code = segment.errorCode;
      }
      if (segment.segmentType && segment.segmentType === "wrapup") {
        var wrapUpS = {
          code: segment.wrapUpCode,
          date: DateTime.convertStringToDate(segment.segmentEnd),
        };
        if (wrapUp.code === "") wrapUp = wrapUpS;
        else if (wrapUp.date < wrapUpS.date) wrapUp = wrapUpS;
      }
      interact = segment.segmentType && segment.segmentType === "interact" ? (interact = true): (interact = interact);
    }
    if (wrapUp.code !== "")
      this.wrapUpCases(campaignAccumulators, wrapUp.code);
    else if (error.state && error.code === "error.ininedgecontrol.connection.line.maxCallLimit")
      this.wrapUpCases(campaignAccumulators, "error-without-wrapup");
    else if(interact)
      this.wrapUpCases(campaignAccumulators, 'interact-without-wrapup')
    else
      this.wrapUpCases(campaignAccumulators,'otro');
  }

  private groupSessionsByPeer(
    conversation: Conversation,
    sessionsMap: Object,
    mainSessionIds: string[]
  ) {
    for (var i = 0; i < conversation.participants.length; i++) {
      var participant = conversation.participants[i];
      for (var j = 0; j < participant.sessions.length; j++) {
        // agrupamos las sessions por peerId despues por sessionId
        var session = participant.sessions[j];
        var sessionId: string;
        if (session.peerId) var sessionId = session.peerId;
        else sessionId = session.sessionId;
        mainSessionIds.push(sessionId);

        var sessions: Session[];
        if (sessionsMap[sessionId] === undefined) {
          var ret: Session[] = [];
          sessionsMap[sessionId] = ret;
        }
        sessions = sessionsMap[sessionId];
        sessions.push(session);
      }
    }
  }

  public processUsers(from: Date, to: Date, response: Object[]): Promise<any> {
    return new Promise((resolve, reject) =>
      this.processInternalUsers(from, to, response, resolve, reject)
    );
  }

  private processInternalUsers(from: Date, to: Date, response: Object[], resolve, reject ) {
    console.log('\n Entrando a processInternalUsers \n'); //! Borrar esto
    this.campaignAccumulatorsCloudService.getUsersDetails(from, to)
      .then((usersDetails: platformClient.Models.AnalyticsUserDetail[]) => {
        //recordar que response es el calls que tiene los accumulator's en calls[0]: Object
        //y el userDetails es un array con AnalyticsUserDetail
        console.log(usersDetails); //! Borrar esto



        //? ------------------------------------- ?//
        for(var i = 0; i < usersDetails.length ; i++){
          var time: number = 0;
          var userDetail = usersDetails[i];

          for(var j = 0; userDetail.primaryPresence !== undefined && j < userDetail.primaryPresence.length; j++){
            time = 0;

            console.log('primaryPresence coincidente con el userId es:\n' , userDetail); //! Borrar esto

            var presenceRecord = userDetail.primaryPresence[j];
            if(presenceRecord != undefined){
              if(presenceRecord.endTime === undefined){
                var now = new Date();
                now.setHours( now.getHours() + 3);
                presenceRecord.endTime = DateTime.convertDateToString(now);
              }
              if(presenceRecord.startTime !== undefined && presenceRecord.endTime !== undefined){
                time = (DateTime.convertStringToDate(presenceRecord.endTime)).getTime() - (DateTime.convertStringToDate(presenceRecord.startTime)).getTime();
              }

              if(presenceRecord.systemPresence === 'IDLE'){
                var userCase =  'IDLE_P';
              }
              else{
                var userCase =  presenceRecord.systemPresence;
              }
              var fromAux: Date = DateTime.convertStringToDate(presenceRecord.startTime);
              if(userCase !== 'OFFLINE'){
                this.evaluateUserCases(fromAux, response, time, userCase);
              }
            }
          }
          for(var j = 0; userDetail.routingStatus !== undefined && j < userDetail.routingStatus.length ; j++){
            time = 0;

            console.log('routing coincidente con el userId es:\n' , userDetail); //! Borrar esto

            var routingRecord = userDetail.routingStatus[j];
            if(routingRecord){
              if(routingRecord.endTime === undefined){
                var now = new Date();
                now.setHours( now.getHours() + 3);
                routingRecord.endTime = DateTime.convertDateToString(now);
              }
              if(routingRecord.startTime !== undefined && routingRecord.endTime !== undefined){
                time = (DateTime.convertStringToDate(routingRecord.endTime)).getTime() - (DateTime.convertStringToDate(routingRecord.startTime)).getTime() ;
              }
              if(routingRecord.routingStatus === 'IDLE'){
                var userCase =  'IDLE_R';
              }
              else{
                var userCase =  routingRecord.routingStatus;
              }
              var fromAux: Date = DateTime.convertStringToDate(routingRecord.startTime);
              if(userCase !== 'OFF_QUEUE'){
                this.evaluateUserCases(fromAux, response, time, userCase);
              }
            }
          }
        }
        resolve(response);
      })
      .catch((error) => reject(error));
  }

  private evaluateUserCases(from: Date , response: Object[], time:number, userCase: string){
    var fromAux: Date = new Date(from.getTime());
    var nextLapse: Date = new Date(from.getTime());
    nextLapse = this.nextLapse(nextLapse);
    while(time > 0){
      var timeAux: number = 0;
      var lapseGap: number = nextLapse.getTime() - fromAux.getTime();
      console.log('UserCases, from: ' + fromAux + ', nextLapse: ' + nextLapse); //! Borrar
      if( lapseGap < time )
        timeAux = lapseGap; //ms
      else
        timeAux = time; //ms
      this.userCases(fromAux, response, timeAux, userCase);
      console.log('Procesamos un caso: '+ userCase + ', con t: '+ time );
      time = time - timeAux; //ms
      console.log('El tiempo restante es: ' + time);
      if(time > 0)
        console.log('Pasamos al siguiente lapso');
      fromAux = this.nextLapse(fromAux);
      nextLapse = this.nextLapse(fromAux);
    }
  }

  private userCases(from: Date, response: Object[], time:number, userCase: string){
    var obj = response[0]; // map con los accumulator's
    var fechas: Date[] = response[1]["fechas"];// string[] con las key's q son los lapsos
    if (fechas === undefined) {
      fechas = new Array<Date>();
    }
    var fromAux: Date = new Date(from.getTime());
    fromAux.setHours(fromAux.getHours() - 3); //Transformamos a hora local para el excel
    var dayAndHour = DateTime.convertDateToDayString(fromAux, "/") + "|" + this.convertDateToHalfHourString(fromAux, ":");
    var queueAccumulator: CampaignAccumulators = obj[dayAndHour]; // ie 29/9/21 9hs son las llamadas de [9 a 9.30)
    if (queueAccumulator === undefined) {
      console.log('Definimos queueAccumulator \n Añadimos una key ' + dayAndHour);
      queueAccumulator = new CampaignAccumulators();
      obj[dayAndHour] = queueAccumulator;
      fechas.push(fromAux);
    }
    //"primaryPresence": [ OFFLINE AVAILABLE ON_QUEUE TRAINING MEETING BUSSY BREAK MEAL IDLE AWAY
    //"routingStatus": [ OFF_QUEUE IDLE INTERACTING NOT_RESPONDING, estas son  descripcione detalladas de ON_QUEUE
    //IDLE se repite, ignorar el IDLE de primaryPresence
    switch(userCase){
      case 'OFFLINE':{
        break
      }
      case 'AVAILABLE':{
        console.log('Case: Available')
        queueAccumulator.login += time;
        queueAccumulator.auxiliarTotal += time;
        queueAccumulator.estadoAvailable += time;
        break
      }
      case 'ON_QUEUE':{
        queueAccumulator.enCola += time;
        queueAccumulator.login += time;
        queueAccumulator.auxiliarTotal += time;
        //queueAccumulator.tiempoTotalLogueo += time;
        break
      }
      case 'TRAINING':{
        queueAccumulator.login += time;
        queueAccumulator.notReady += time;
        queueAccumulator.auxiliarTotal += time;
        queueAccumulator.estadoTraining += time;
        break
      }
      case 'MEETING':{
        queueAccumulator.login += time;
        queueAccumulator.notReady += time;
        queueAccumulator.auxiliarTotal += time;
        queueAccumulator.estadoMeeting += time;
        break
      }
      case 'BUSSY':{
        queueAccumulator.login += time;
        queueAccumulator.notReady += time;
        queueAccumulator.break += time;
        queueAccumulator.auxiliarTotal += time;
        queueAccumulator.estadoBussy += time;
        break
      }
      case 'BREAK':{
        queueAccumulator.avail += time;
        queueAccumulator.notReady += time;
        queueAccumulator.break += time;
        queueAccumulator.auxiliarTotal += time;
        break
      }
      case 'MEAL':{
        queueAccumulator.avail += time;
        queueAccumulator.notReady += time;
        queueAccumulator.break += time;
        queueAccumulator.auxiliarTotal += time;
        queueAccumulator.estadoMeal += time;
        break
      }
      case 'AWAY':{
        queueAccumulator.avail += time;
        queueAccumulator.notReady += time;
        queueAccumulator.auxiliarTotal += time;
        queueAccumulator.estadoAway += time;
        break
      }
      case 'OFF_QUEUE':{
        break
      }
      case 'IDLE_P':{ //! esto es IDLE de P_rimaryPresence que significa inactivo realmente
        queueAccumulator.avail += time;
        queueAccumulator.notReady += time;
        queueAccumulator.estadoIdle += time;
        break
      }
      case 'IDLE_R':{ //! esto es IDLE de R_outingStatus que significa que esta en cola pero no esta interactuando
        queueAccumulator.tiempoTotalLogueo += time;
        break
      }
      case 'INTERACTING':{
        queueAccumulator.tiempoTotalLogueo += time;
        break
      }
      case 'NOT_RESPONDING':{
        queueAccumulator.avail += time;
        queueAccumulator.tiempoTotalLogueo += time;
        break
      }
      default:{
        break
      }
    }
  }

  private nextLapse(from: Date): Date{
    var res: Date = new Date(from.getTime());
    if(from.getMinutes() >= 0 && from.getMinutes() < 30){ // [xx:00, xx:30),[xx:30, xx:00)
      res.setMinutes(30,0,0);    // 3:27 -> 3.30,, 3.47 -> 4.17 -> 4.00
    }else{
      res = new Date( res.getTime() + 30*60*1000 );
      res.setMinutes(0,0,0);
    }
    return res;
  }

  private wrapUpCases(
    campaignAccumulators: CampaignAccumulators,
    wrapUpCode: string
  ) {
    if (wrapUpCode !== "ININ-OUTBOUND-INVALID-PHONE-NUMBER" && wrapUpCode !== "ININ-OUTBOUND-STUCK-INTERACTION") {
      campaignAccumulators.disparadas++;
    }

    switch (wrapUpCode) {
      case "OUTBOUND-STUCK-CONTACT": {
      }
      case "ININ-OUTBOUND-STUCK-INTERACTION": {
        campaignAccumulators.noEjecutadas++;
        break;
      }
      case "error-without-wrapup": {
        campaignAccumulators.noEjecutadas++;
        break;
      }
      case "OUTBOUND-INVALID-PHONE-NUMBER": {
        campaignAccumulators.noContacto++;
        break;
      }
      case "ININ-OUTBOUND-INVALID-PHONE-NUMBER": {
        campaignAccumulators.noContacto++;
        break;
      }
      case "ININ-OUTBOUND-SIT-UNCALLABLE": {
        //el numero no es correcto y no volver a llamar
        campaignAccumulators.noContacto++;
        break;
      }
      case "ININ-OUTBOUND-NUMBER_COULD_NOT_BE_DIALED": {
        campaignAccumulators.noContacto++;
        break;
      }
      case "ININ-OUTBOUND-PREVIEW-ERROR-PHONE-NUMBER": {
        campaignAccumulators.noContacto++;
        break;
      }
      case "interact-without-wrapup": {
        campaignAccumulators.contactadas++;
        break;
      }
      case "ININ-OUTBOUND-SIT-CALLABLE": {
      } //el numero es correcto pero el sistema termina la llamada
      case "ININ-OUTBOUND-BUSY": {
        campaignAccumulators.ncOcupadas++;
        break;
      }
      case "ININ-OUTBOUND-NUMBER-ATTEMPT-LIMIT-SKIPPED": {
        campaignAccumulators.ncNoContesta++;
        break;
      }
      case "ININ-OUTBOUND-CONTACT-ATTEMPT-LIMIT-SKIPPED":{
        campaignAccumulators.ncNoContesta++;
        break;
      }
      case "ININ-OUTBOUND-FAX": {
        campaignAccumulators.ncModemFax++;
        break;
      }
      case "ININ-OUTBOUND-MACHINE": {
        campaignAccumulators.ncContestador++;
        break;
      }
      case "ININ-OUTBOUND-NO-ANSWER": {
        campaignAccumulators.ncNoHablo++;
        break;
      }
      case "ININ-OUTBOUND-NOT-TRANSFERRED-TO-FLOW": {
        campaignAccumulators.ncNoHablo++;
        break;
      }
      case "ININ-OUTBOUND-FAILED-TO-REACH-FLOW": {
        campaignAccumulators.ncNoHablo++;
        break;
      }
      default: {
        campaignAccumulators.ncOtros++;
        break;
      }
    }
  }

  private getFirstQueue(conversation: Conversation): string {
    for (var i = 0; i < conversation.participants.length; i++) {
      var participant = conversation.participants[i];
      if ("acd" === participant.purpose) {
        return participant.participantName;
      }
    }
    return "undefined";
  }

  private getSessionsOutboundCall(conversation: Conversation) {
    return this.getSessionsCall(
      conversation,
      this.purposeStop,
      "outbound",
      true
    );
  }
  private getSessionsInboundCall(conversation: Conversation) {
    return this.getSessionsCall(
      conversation,
      this.purposeStop,
      "inbound",
      false
    );
  }

  private getSessionsCall(
    conversation: Conversation,
    purposeStop: Array<string>,
    direction: string,
    withOutboundCampaignId: boolean
  ) {
    var sessions = {};
    if (direction !== conversation.originatingDirection) {
      return sessions;
    }
    for (var i = 0; i < conversation.participants.length; i++) {
      var participant = conversation.participants[i];
      for (var j = 0; j < participant.sessions.length; j++) {
        var session = participant.sessions[j];
        if (
          withOutboundCampaignId &&
          session.outboundCampaignId === undefined
        ) {
          return sessions;
        }

        if (session.mediaType === "callback") {
          //continue;
        }

        var id = undefined;
        if (session.peerId === undefined) {
          id = session.sessionId;
        } else {
          id = session.peerId;
        }
        var segments = sessions[id];
        if (segments === undefined) {
          segments = [];
          sessions[id] = segments;
        }

        for (var k = 0; k < session.segments.length; k++) {
          var segment = session.segments[k];
          if (segment.wrapUpCode !== undefined) {
            segments.push(segment);
          }
        }
      }

      if (purposeStop.includes(participant.purpose)) {
      }
    }
    return sessions;
  }

  private convertDateToHalfHourString(date: Date, delimiter: string): string {
    var hour = "" + date.getHours();
    if (date.getMinutes() >= 30)
      var minute = "" + "30";
    else
      var minute = "" + "00";
    var second = "" + "00";
    if (hour.length < 2)
      hour = "0" + hour;

    return hour + delimiter + minute + delimiter + second;
  }

  public mergeInbOutb(objIn: Object[], objOut: Object[]){
    //? Aca metemos todo lo de outbound en el de inbound porque el campaign de out tiene menos cosas que pasar
    var keysIn: Date[] = objIn[1]['fechas'];
    var keysOut: Date[] = objOut[1]['fechas'];
    for(var i = 0; i < keysOut.length; i++){
      var key = DateTime.convertDateToDayString(keysOut[i], "/") + "|" + this.convertDateToHalfHourString(keysOut[i], ":");
      var accumulatorIn: CampaignAccumulators = objIn[0][key];
      var accumulatorOut: CampaignAccumulators = objOut[0][key];
      if(accumulatorIn === undefined){
        objIn[0][key] = accumulatorOut;
        keysIn.push(keysOut[i]);
      }
      else{
        this.mergeAcummulators(accumulatorIn, accumulatorOut);
      }
    }
  }

  private mergeAcummulators(accumulatorIn: CampaignAccumulators, accumulatorOut: CampaignAccumulators){
    accumulatorIn.disparadas = accumulatorIn.disparadas + accumulatorOut.disparadas;
    accumulatorIn.noEjecutadas = accumulatorIn.noEjecutadas + accumulatorOut.noEjecutadas;
    accumulatorIn.noContacto = accumulatorIn.noContacto + accumulatorOut.noContacto;
    accumulatorIn.contactadas = accumulatorIn.contactadas + accumulatorOut.contactadas;
    accumulatorIn.ncOcupadas = accumulatorIn.ncOcupadas + accumulatorOut.ncOcupadas;
    accumulatorIn.ncNoContesta = accumulatorIn.ncNoContesta + accumulatorOut.ncNoContesta;
    accumulatorIn.ncOtros = accumulatorIn.ncOtros + accumulatorOut.ncOtros;
    accumulatorIn.ncModemFax = accumulatorIn.ncModemFax + accumulatorOut.ncModemFax;
    accumulatorIn.ncContestador = accumulatorIn.ncContestador + accumulatorOut.ncContestador;
    accumulatorIn.ncNoHablo = accumulatorIn.ncNoHablo + accumulatorOut.ncNoHablo;
  }
}
