<div class="content">
    <div class="form-group">
        <div class="center">
            <div>
                <mat-form-field>
                    <input matInput [ngxMatDatetimePicker]="pickerFrom" placeholder="Desde"
                        [(ngModel)]="myDatePickerFrom">
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerFrom [showSeconds]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
                <mat-form-field>
                    <input matInput [ngxMatDatetimePicker]="pickerTo" placeholder="Hasta" [(ngModel)]="myDatePickerTo"
                        [min]="myDatePickerFrom">
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerTo [showSeconds]="true">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>
        <div class="center">
            <div class="example-button-row">
                <button mat-button (click)="onRangeDate('TODAY')">Hoy</button>
                <button mat-button (click)="onRangeDate('YESTERDAY')">Ayer</button>
                <button mat-button (click)="onRangeDate('LAST_7_DAYS')">Últimos 7 días</button>
                <button mat-button (click)="onRangeDate('THIS_WEEK')">Esta semana</button>
                <button mat-button (click)="onRangeDate('PREVIOUS_WEEK')">Anterior semana</button>
                <button mat-button (click)="onRangeDate('LAST_31_DAYS')">Últimos 31 días</button>
              </div>
        </div>
    </div>
<div class="card-body">
        <div class="row">
            <div class="col-5 col-sm-2">
                <div class="nav flex-column nav-tabs h-100" id="vert-tabs-tab" role="tablist"
                    aria-orientation="vertical">
                    <a class="nav-link disabled" id="vert-tabs-home-tab" data-toggle="pill" href="#vert-tabs-home"
                        role="tab" aria-controls="vert-tabs-home" aria-selected="false"></a>
                    <a class="nav-link" id="vert-tabs-profile-tab" data-toggle="pill" href="#vert-tabs-profile"
                        role="tab" aria-controls="vert-tabs-profile" aria-selected="false">Agentes</a>
                    <a class="nav-link" id="vert-tabs-messages-tab" data-toggle="pill" href="#vert-tabs-messages"
                        role="tab" aria-controls="vert-tabs-messages" aria-selected="false">Contratos</a>
                    <a class="nav-link" id="vert-tabs-settings-tab" data-toggle="pill" href="#vert-tabs-settings"
                        role="tab" aria-controls="vert-tabs-settings" aria-selected="false">Telefonos</a>
                </div>
            </div>


            <div class="col-7 col-sm-9">
                <div class="tab-pane fade show" id="vert-tabs-home" role="tabpanel"
                    aria-labelledby="vert-tabs-home-tab" aria-disabled="true" aria-invalid="true">
                </div>
                <div class="tab-content" id="vert-tabs-tabContent">
                    <div class="tab-pane fade" id="vert-tabs-profile" role="tabpanel"
                        aria-labelledby="vert-tabs-profile-tab">
                        <div class="center">
                            <div class="duallistbox-dummy2">
                                <select name="agentes-dummy" class="duallistbox-dummy" multiple>
                                    <option *ngFor="let n of carsDummy" [value]="n.id">{{n.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="vert-tabs-messages" role="tabpanel"
                        aria-labelledby="vert-tabs-messages-tab">
                        <mat-form-field class="chip-list">
                            <mat-label>Contratos a filtrar</mat-label>
                            <mat-chip-list #chipListContract>
                                <mat-chip *ngFor="let contract of selectedContract"
                                    (removed)="removeContract(contract)">
                                    {{contract}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input [matChipInputFor]="chipListContract"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addContract($event)">
                            </mat-chip-list>
                        </mat-form-field>
                    </div>
                    <div class="tab-pane fade" id="vert-tabs-settings" role="tabpanel"
                        aria-labelledby="vert-tabs-settings-tab">
                        <mat-form-field class="chip-list">
                            <mat-label>Teléfonos a filtrar</mat-label>
                            <mat-chip-list #chipListPhone>
                                <mat-chip *ngFor="let phone of selectedPhone" (removed)="removePhone(phone)">
                                    {{phone}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input [matChipInputFor]="chipListPhone"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addPhone($event)">
                            </mat-chip-list>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button [disabled]="!myDatePickerFrom || !myDatePickerTo" id="generate-interactions-detail" type="button"
        class="btn btn-block btn-secondary" (click)="onGenerateInteractionsDetail()">Generar</button>
</div>
<app-script-hack type="text/javascript">
    <![CDATA[
        duallistboxDummy = $('.duallistbox-dummy').bootstrapDualListbox({
                moveOnSelect: false,
                infoTextEmpty: 'Lista vacia',
                infoText: false,
                filterPlaceHolder: 'Filtrar',
                moveSelectedLabel: 'Mover soleccionado',
                moveAllLabel: 'Mover todos',
                removeSelectedLabel: 'Borrar seleccionado',
                removeAllLabel: 'Borrar todos',
                infoTextFiltered: 'Filtrado {0} de {1}',
                filterTextClear: 'Mostrar todo'
            });
    ]]>
</app-script-hack>